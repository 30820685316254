import { useRef, useState, useEffect } from 'react'
import clsx from 'clsx'
import styles from './styles.module.scss'
import triangle from '../../icons/triangle.svg'

export default function Collapse(props) {
  const { title, header, children, collapsible = true, defaultOpen, className } = props

  const [isOpen, setOpen] = useState(defaultOpen || !collapsible ? true : false)

  const container = useRef(null)

  useEffect(() => {
    if (defaultOpen || !collapsible) {
      container.current.style.maxHeight = 'unset'
    }
  }, [collapsible, defaultOpen])

  useEffect(() => {
    container.current.style.maxHeight = isOpen
      ? container.current.scrollHeight + 'px'
      : null
  }, [isOpen])

  const handleOpen = () => setOpen(!isOpen)

  return (
    <div className={clsx(styles.collapsible, className)}>
      <button
        type="button"
        onClick={handleOpen}
        disabled={!collapsible}
        className={clsx(styles.toggle, isOpen && styles.opened)}
      >
        {header || (
          <div className={styles.header}>
            {title}
            {collapsible && (
              <img alt="" src={triangle} className={clsx(isOpen && styles.rotated)} />
            )}
          </div>
        )}
      </button>

      <div ref={container} className={styles.container}>
        {children}
      </div>
    </div>
  )
}
