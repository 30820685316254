import { useFormik } from 'formik'
import * as yup from 'yup'
import { useMemo, useState, useEffect, useCallback } from 'react'
import Table from '../../components/Table'
import ConfirmDialog from '../../components/ConfirmDialog'
import PageTopContainer from '../../components/PageTopContainer'
import Button from '../../components/Button'
import PageLoader from '../../components/PageLoader'
import SwitchButton from '../../components/SwitchButton'
// import InputMaskField from '../../components/InputMaskField'
import AddButton from '../../components/AddButton'
import IconButton from '../../components/IconButton'
import TextInput from '../../components/TextInput'
import SelectSingle from '../../components/SelectSingle'
// import GridLoader from '../../components/Loader'

import plus from '../../icons/plus.svg'
import edit from '../../icons/edit.svg'
import cancel from '../../icons/cancel.svg'
import { useHideSearch } from '../../hooks/useContext'
import axios from '../../utils/axios'
import Modal from '../../components/Modal'
import styles from './styles.module.scss'
import clsx from 'clsx'

const Notifications = () => {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  const [addModalOpen, setAddModalOpen] = useState(false)
  const [editingRule, setEditingRule] = useState('')
  const [editModalOpen, setEditModalOpen] = useState(false)
  const [deletePrompt, setDeletePrompt] = useState({
    open: false,
    name: '',
    id: '',
    loading: false
  })

  const [pagination, setPagination] = useState({
    rowCount: 10,
    pageNum: 1,
    pageCount: 1
  })
  const getData = useCallback(
    async (loading = true) => {
      try {
        loading && setLoading(true)
        const res = await axios.get('notification/list', {
          params: {
            'Pagination.RowCount': pagination.rowCount,
            'Pagination.PageNum': pagination.pageNum
          }
        })
        setData(res.notifications)
        setPagination({ ...pagination, pageCount: res.pagination.pageCount })
      } catch (e) {
        console.log(e)
      } finally {
        setLoading(false)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [pagination.rowCount, pagination.pageNum]
  )

  const handleSwitchState = useCallback(async (id, active) => {
    try {
      await axios.put(`notification/${id}/archive?active=${!active}`)
    } catch {
    } finally {
      getData(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    getData()
  }, [getData])

  const { setHidden } = useHideSearch()
  useEffect(() => {
    setHidden(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const columns = useMemo(
    () => [
      {
        Header: '',
        accessor: 'name',
        disableSortBy: true
      },
      {
        Header: '',
        accessor: 'uid',
        disableSortBy: true,
        Cell: ({ value, row }) => (
          <div className={styles.buttons}>
            <SwitchButton
              id={row.original.uid}
              onLabel="вкл."
              offLabel="выкл."
              checked={row.original.active}
              className={styles.switchButton}
              onChange={() => handleSwitchState(row.original.uid, row.original.active)}
            />
            <IconButton icon={edit} onClick={handleClickEditIcon(row.original.uid)} />
            <IconButton icon={cancel} onClick={handleOpenDeletePrompt(row.original)} />
          </div>
        )
      }
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  const handlePaginationChange = p => {
    setPagination({
      ...pagination,
      ...p
    })
  }

  const handleOpenDeletePrompt = row => () => {
    setDeletePrompt({
      open: true,
      name: row.name,
      id: row.uid
    })
  }

  const handleDeleteRule = async () => {
    try {
      setDeletePrompt(s => ({ ...s, loading: true }))
      await axios.delete(`notification/${deletePrompt.id}`)
    } catch {
    } finally {
      setDeletePrompt(s => ({
        ...s,
        open: false,
        loading: false
      }))
      getData()
    }
  }

  const handleCloseDeletePrompt = () => {
    setDeletePrompt(s => ({
      ...s,
      open: false
    }))
  }

  const handleClickEditIcon = id => () => {
    setEditingRule(id)
    setEditModalOpen(true)
  }

  const handleCloseEditModal = () => {
    setEditModalOpen(false)
  }

  const onModalClose = () => {
    setAddModalOpen(false)
    setEditModalOpen(false)
    setEditingRule('')
    getData()
  }

  const handleClickAddProduct = () => {
    setAddModalOpen(!addModalOpen)
  }

  return (
    <div>
      <PageTopContainer>
        <div className={styles.firstRow}>
          <h1>Оповещения</h1>
          <AddButton
            label="Создать правило"
            isOpen={addModalOpen}
            onClick={handleClickAddProduct}
            onClose={handleClickAddProduct}
          >
            <NotiModal onCloseEvent={onModalClose} />
          </AddButton>
        </div>
      </PageTopContainer>
      <Table
        loading={loading}
        data={data}
        columns={columns}
        pagination={pagination}
        onPaginationChange={handlePaginationChange}
        className={styles.table}
      />
      <Modal centered isOpen={editModalOpen} onClose={handleCloseEditModal}>
        <NotiModal
          onCloseEvent={onModalClose}
          rule={editingRule}
          justClose={handleCloseEditModal}
        />
      </Modal>
      <ConfirmDialog
        isOpen={deletePrompt.open}
        message={`Вы уверены, что хотите удалить правило ${deletePrompt.name}?`}
        onOk={handleDeleteRule}
        onCancel={handleCloseDeletePrompt}
        loading={deletePrompt.loading}
      />
    </div>
  )
}

export default Notifications

const NotiModal = props => {
  const { onCloseEvent, rule, justClose } = props

  const [indicators, setIndicators] = useState([])
  const [comparisons, setComparisons] = useState([])
  const [logicals, setLogicals] = useState([])
  const [loading, setLoading] = useState(false)
  const [initialValues, setInitialValues] = useState({
    name: '',
    conditions: [
      {
        indicator: '',
        comparison: '',
        value: '',
        logicalOperator: null
      }
    ],
    emails: [''],
    phones: ['']
  })

  const handleJustClose = () => {
    justClose()
  }

  const getRule = useCallback(async () => {
    try {
      setLoading(true)
      const res = await axios.get(`notification/${rule}`)
      setInitialValues(res.notification.Rule)
    } catch {
    } finally {
      setLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (rule) {
      getRule()
    }
  }, [rule, getRule])

  const getIndicators = useCallback(async () => {
    const res = await axios.get('notification/indicators')
    setIndicators(res.indicators)
  }, [])

  const getComparisons = useCallback(async () => {
    const res = await axios.get('notification/comparison-operators')
    setComparisons(res.comparisonOperators)
  }, [])

  const getLogicals = useCallback(async () => {
    const res = await axios.get('notification/logical-operators')
    setLogicals(res.logicalOperators)
  }, [])

  const optionsIndicators = useMemo(() => {
    return indicators.map(item => ({ id: item.code, label: item.name }))
  }, [indicators])

  const optionsComparisons = useMemo(() => {
    return comparisons.map(item => ({ id: item.code, label: item.name }))
  }, [comparisons])

  const optionsLogicals = useMemo(() => {
    return logicals.map(item => ({ id: item.code, label: item.name }))
  }, [logicals])

  useEffect(() => {
    if (!indicators.length) {
      getIndicators()
    }
    if (!comparisons.length) {
      getComparisons()
    }
    if (!logicals.length) {
      getLogicals()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleSave = async values => {
    try {
      rule
        ? await axios.put(`notification/${rule}`, values)
        : await axios.post('notification', values)
    } catch (e) {
      console.log(e)
    } finally {
      onCloseEvent()
    }
  }

  const { values, errors, touched, setFieldValue, handleSubmit } = useFormik({
    onSubmit: values => handleSave(values),
    initialValues,
    validationSchema: yup.object().shape({
      name: yup.string().required('Это поле обязательно'),
      conditions: yup.array().of(
        yup.object().shape({
          indicator: yup.string().required('Это поле обязательно'),
          comparison: yup.string().required('Это поле обязательно'),
          value: yup.string().required('Это поле обязательно'),
          logicalOperator: yup.mixed().notRequired()
        })
      ),
      emails: yup
        .array()
        .of(yup.string().required('Это поле обязательно').email('Неверный формат почты')),
      phones: yup.array().of(
        yup
          .string()
          .required('Это поле обязательно')
          .matches(
            /^((8|\+7)[- ]?)?(\(?\d{3}\)?[- ]?)?[\d\- ]{7,10}$/,
            'Неверный номер телефона'
          )
      )
    }),
    enableReinitialize: true
  })

  const handleChange = name => event => {
    setFieldValue(name, event.target.value)
  }

  const handleAddCondition = () => {
    setFieldValue('conditions', [
      ...values.conditions,
      {
        indicator: '',
        comparison: '',
        value: '',
        logicalOperator: null
      }
    ])
  }

  const handleDeleteCondition = index => () => {
    const newConditions = [...values.conditions]
    newConditions[index - 1].logicalOperator = null
    newConditions.splice(index, 1)
    setFieldValue('conditions', newConditions)
  }

  const handleChangeSelector = name => value => {
    setFieldValue(name, value?.id)
  }

  const handleAddEmail = () => {
    setFieldValue('emails', [...values.emails, ''])
  }

  const handleAddPhone = () => {
    setFieldValue('phones', [...values.phones, ''])
  }

  const handleDeleteEmail = index => () => {
    const newEmails = [...values.emails]
    newEmails.splice(index, 1)
    setFieldValue('emails', newEmails)
  }

  const handleDeletePhone = index => () => {
    const newPhones = [...values.phones]
    newPhones.splice(index, 1)
    setFieldValue('phones', newPhones)
  }

  useEffect(() => {
    const index = values.conditions.length - 1
    if (values.conditions?.[index]?.logicalOperator !== null) {
      handleAddCondition()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values])

  return (
    <PageLoader loading={loading}>
      <div className={clsx(rule && styles.editModalContainer)}>
        <h2>Создать правило:</h2>
        <form onSubmit={handleSubmit}>
          <div className={styles.textInputs}>
            <TextInput
              label="Название правила"
              placeholder="Правило №1"
              name="name"
              value={values.name}
              errorMessage={touched.name && errors.name}
              onChange={handleChange('name')}
              tabIndex={1}
            />
          </div>
          <h2>Если:</h2>
          {values.conditions.map((conditios, index) => (
            <div>
              {index >= 1 && <div className={styles.spacer2}></div>}
              <div className={styles.addCol} key={index}>
                <div className={styles.addRow}>
                  <SelectSingle
                    name="indicator"
                    visibleLabel="Выберите показатель"
                    text="Остаток товара"
                    value={values.conditions[index].indicator}
                    errorMessage={
                      touched.conditions?.[index]?.indicator &&
                      errors.conditions?.[index]?.indicator
                    }
                    onChange={handleChangeSelector(`conditions[${index}].indicator`)}
                    options={optionsIndicators}
                    tabIndex={2}
                  />

                  <div className={styles.spacer1}></div>

                  <TextInput
                    label="Введите значение"
                    placeholder="10"
                    name="value"
                    value={values.conditions[index].value}
                    errorMessage={
                      touched.conditions?.[index]?.value &&
                      errors.conditions?.[index]?.value
                    }
                    onChange={handleChange(`conditions[${index}].value`)}
                    tabIndex={4}
                  />
                </div>

                <div className={styles.addRow}>
                  <SelectSingle
                    name="comparison"
                    visibleLabel="Выберите оператор сравнения"
                    text="Меньше"
                    value={values.conditions[index].comparison}
                    errorMessage={
                      touched.conditions?.[index]?.comparison &&
                      errors.conditions?.[index]?.comparison
                    }
                    onChange={handleChangeSelector(`conditions[${index}].comparison`)}
                    options={optionsComparisons}
                    tabIndex={3}
                  />

                  <div className={styles.spacer1}></div>

                  <SelectSingle
                    name="logicalOperator"
                    visibleLabel="Выберите логический оператор"
                    text="И/или"
                    value={values.conditions[index].logicalOperator}
                    errorMessage={
                      touched.conditions?.[index]?.logicalOperator &&
                      errors.conditions?.[index]?.logicalOperator
                    }
                    onChange={handleChangeSelector(
                      `conditions[${index}].logicalOperator`
                    )}
                    options={optionsLogicals}
                    tabIndex={5}
                  />
                  {index >= 1 && (
                    <div>
                      <div className={styles.deleteConditionsButton}>
                        <IconButton
                          onClick={handleDeleteCondition(index)}
                          icon={cancel}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          ))}

          <h2>То уведомить:</h2>

          <div className={styles.addCol}>
            <div className={styles.addRow}>
              {values.emails.map((email, index) => (
                <div className={styles.emailsContainer} key={index}>
                  {index >= 1 && <div className={styles.spacer3}></div>}
                  <TextInput
                    label="Укажите e-mail"
                    name="email"
                    value={values.emails[index]}
                    errorMessage={touched.emails?.[index] && errors.emails?.[index]}
                    onChange={handleChange(`emails[${index}]`)}
                    tabIndex={6}
                  />
                  {index >= 1 && (
                    <div>
                      <div className={styles.deleteConditionsButton}>
                        <IconButton onClick={handleDeleteEmail(index)} icon={cancel} />
                      </div>
                    </div>
                  )}
                </div>
              ))}

              <div className={styles.addConditionsButton}>
                <IconButton onClick={handleAddEmail} icon={plus} />
              </div>
            </div>

            <div className={styles.addRow}>
              {values.phones.map((phone, index) => (
                <div className={styles.emailsContainer} key={index}>
                  {index >= 1 && <div className={styles.spacer3}></div>}
                  <TextInput
                    label="Укажите контактный телефон"
                    name="phone"
                    value={values.phones[index]}
                    errorMessage={touched.phones?.[index] && errors.phones?.[index]}
                    onChange={handleChange(`phones[${index}]`)}
                    tabIndex={6}
                  />
                  {index >= 1 && (
                    <div className={styles.deleteConditionsButton}>
                      <IconButton onClick={handleDeletePhone(index)} icon={cancel} />
                    </div>
                  )}
                </div>
              ))}
              <div className={styles.addConditionsButton}>
                <IconButton onClick={handleAddPhone} icon={plus} />
              </div>
            </div>
          </div>
          <div className={styles.spacer1}></div>

          <div className={clsx(styles.modalButtons, rule && styles.buttonsMB)}>
            <Button type="submit" className={styles.button}>
              {rule ? 'Изменить' : 'Сохранить'}
            </Button>
            {rule && (
              <Button white onClick={handleJustClose}>
                Отменить
              </Button>
            )}
          </div>
        </form>
      </div>
    </PageLoader>
  )
}
