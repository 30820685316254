import { useEffect } from 'react'
import styles from './styles.module.scss'

// Компонент для мобильной webview
export default function LiscDocument({ children, ...rest }) {
  useEffect(() => {
    const link = document.createElement('link')
    link.rel = 'stylesheet'
    link.href = 'https://fonts.googleapis.com/css2?family=Open+Sans&display=swap'
    document.head.appendChild(link)
  }, [])

  return (
    <div className={styles.container} {...rest}>
      {children}
    </div>
  )
}
