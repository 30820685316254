/* eslint-disable eqeqeq */
import React, { useRef, useState } from 'react'
import '../styles.scss'
import Button from '../../Button'
import useOnClickOutside from '../../../hooks/useOnClickOutside'
import DeleteIcon from '../../../icons/cancel.svg'

function ProductItem(props) {
  const {
    product,
    onDelete,
    springUid,
    numberCell,
    imageProduct,
    nameProduct,
    amountProduct,
    priceProduct,
    soldQuantity,
    zIndexKey,
    onAmountChange
  } = props
  const [amountProductNumber, setAmountProductNumber] = useState(amountProduct)
  const [activeChange, setActiveChange] = useState(false)

  const handleChangeAmount = ev => {
    setAmountProductNumber(Number(ev.target.value))
    onAmountChange({ SpringUid: springUid, Quantity: Number(ev.target.value) })
  }

  // Closing Slider a outside visual zone
  const ref = useRef()
  useOnClickOutside(ref, () => setActiveChange(false))

  return (
    <div style={{ position: 'relative', zIndex: 1000 - zIndexKey }}>
      <li className="productItem">
        <div className="productItem__cellNumber">
          <span className="productItem__cellNumber_text">{numberCell}</span>
        </div>
        <img className="productItem__image" src={imageProduct} alt={nameProduct} />
        <p className="productItem__title">{nameProduct}</p>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <p className="productItem__label">Ож. выдачи</p>
          <p className="productItem__inwait">{soldQuantity}</p>
        </div>
        <img
          className="productItem__delete"
          onClick={() => onDelete(product.uid)}
          src={DeleteIcon}
          alt="Удалить товар"
        />
        <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
          <p className="productItem__label">Факт. кол-во</p>
          <div ref={ref}>
            <Button
              className="productItem__number"
              onClick={() => setActiveChange(!activeChange)}
              white
            >
              {amountProduct != amountProductNumber ? amountProductNumber : amountProduct}
            </Button>
            <div
              className="modalRange"
              style={activeChange ? { display: 'block' } : { display: 'none' }}
            >
              <div className="modalRange__container">
                <div className="b-popup__main-range">
                  <div className="b-popup__main-range--content">
                    <span style={{ marginRight: 10 }}>1</span>
                    <input
                      name="range"
                      type="range"
                      min="1"
                      value={
                        amountProduct != amountProductNumber
                          ? amountProductNumber
                          : amountProduct
                      }
                      max={'12'}
                      step="1"
                      onChange={handleChangeAmount}
                    />
                    <span style={{ marginLeft: 10 }}>{'12'}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <p className="productItem__price">{priceProduct} руб.</p>
      </li>
    </div>
  )
}

export default ProductItem
