import { useHistory } from 'react-router'
import icon from '../../icons/arrow-back.svg'
import styles from './styles.module.scss'

export default function GoBackButton(props) {
  const { onClick } = props
  const history = useHistory()

  const handleClick = () => {
    onClick ? onClick() : history.goBack()
  }

  return (
    <button className={styles.button} type="button" title="Назад" onClick={handleClick}>
      <img alt="" src={icon} />
    </button>
  )
}
