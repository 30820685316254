import Fade from '../Fade'
import Header from '../Header'
import Loader from '../Loader'
import SideMenu from '../SideMenu'
import LoginPage from '../../pages/Login'

import styles from './styles.module.scss'

import Routes from '../../pages/Routes'
import { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useSearch, useProfile, useHideSearch } from '../../hooks/useContext'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import LiscOffer from '../LiscOffer'
import LiscContract from '../LiscContract'
import LiscConfidentiality from '../LiscConfidentiality'
import Modal from '../Modal'
import { EditUserModal } from '../Header/EditUserModal'
import { EditPassModal } from '../Header/EditPassModal'
import * as Sentry from '@sentry/react'

export default function Layout() {
  const history = useHistory()
  const { setValue } = useSearch()
  const { setHidden } = useHideSearch()
  const { user, loading, loadUser } = useProfile()
  const { pathname } = useLocation()
  const [editModal, setEditModal] = useState({ open: false })
  const [editPassModal, setEditPassModal] = useState({ open: false })

  useEffect(() => {
    history.listen(location => {
      setValue('')
      setHidden(false)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleCloseModal = () => {
    setEditModal({ ...editModal, open: false })
    loadUser()
  }

  const openPassModalHandler = () => {
    setEditPassModal({ ...editPassModal, open: true })
    setEditModal({ ...editModal, open: false })
  }

  const closePassModalHandler = () => {
    setEditPassModal({ ...editPassModal, open: false })
    setEditModal({ ...editModal, open: true })
  }

  const onClickPassModal = () => {
    setEditModal({ ...editModal, open: true })
  }

  const onUserClickHandler = () => {
    setEditModal({ ...editModal, open: true })
  }

  if (user) {
    Sentry.setUser({ id: user.uid, username: user.visibleName, email: user.email })
  }

  return pathname === '/lisc-offer' ? (
    <LiscOffer />
  ) : pathname === '/lisc-contract' ? (
    <LiscContract />
  ) : pathname === '/lisc-conf' ? (
    <LiscConfidentiality />
  ) : (
    <>
      <Fade isShown={loading}>
        <div className={styles.loading}>
          <Loader />
        </div>
      </Fade>
      {user ? (
        <section className={styles.container}>
          <SideMenu />

          <main className={styles.main}>
            <Header onAvatarClick={onUserClickHandler} />

            <section className={styles.content}>
              <div>
                <Routes />
                <Modal centered isOpen={editModal.open} onClose={handleCloseModal}>
                  <EditUserModal
                    onPassChange={openPassModalHandler}
                    user={user}
                    onClose={handleCloseModal}
                  />
                </Modal>

                <Modal
                  onClose={closePassModalHandler}
                  disableEsc={true}
                  isOpen={editPassModal.open}
                  centered
                >
                  <EditPassModal
                    onClick={onClickPassModal}
                    userUid={user.uid}
                    onClose={closePassModalHandler}
                  />
                </Modal>
              </div>
            </section>
          </main>
        </section>
      ) : (
        <LoginPage />
      )}
      <ToastContainer position={toast.POSITION.BOTTOM_RIGHT} />
    </>
  )
}
