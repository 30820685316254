import styles from './styles.module.scss'
import person from '../../icons/person.svg'

export default function Avatar(props) {
  const { url, name } = props

  return url ? (
    <img src={url} alt={name} className={styles.avatar} />
  ) : (
    <div className={styles.circle}>
      <img src={person} alt={name} />
    </div>
  )
}
