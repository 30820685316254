function checkDateTime(d) {
    const _date = new Date(d);
    const Now = new Date().getTime();
    const DiffTime = _date.getTime() - Now;

    if (
        _date.getFullYear() === 1970 ||
        _date.getFullYear() < new Date().getFullYear()
    ) {
        return false;
    }

    if (DiffTime > 60000) {
        return true;
    } else {
        return false;
    }
}

export const isDate = d => {
    // Любой параметр, который не может быть распознан Date, возвращаемое значение вызова подфункции - NaN
    return isNaN(new Date(d).getTime()) || new Date(d).getTime() === 0
        ? false
        : checkDateTime(d);
};


