import { useState, useEffect, useCallback } from 'react'
import { useHistory, useParams } from 'react-router'
import PageTopContainer from '../../../../components/PageTopContainer'
import SwitchButton from '../../../../components/SwitchButton'
import IconButton from '../../../../components/IconButton'
import ConfirmDialog from '../../../../components/ConfirmDialog'
import Collapse from '../../../../components/Collapse'
import PageLoader from '../../../../components/PageLoader'
import ImageCard from '../../../../components/ImageCard'
import NothingFound from '../../../../components/NothingFound'
import BannerModal from '../BannerModal'
import Modal from '../../../../components/Modal'
import { parseWorkingDays } from '../../../../utils/parseWorkingDays'
import formatDate from '../../../../utils/formatDate'
import axios from '../../../../utils/axios'
import clsx from 'clsx'
import cancel from '../../../../icons/cancel.svg'
import edit from '../../../../icons/edit.svg'
import parseTime from '../../../../utils/12hourTo24'
import styles from './styles.module.scss'
import ConditionalRender from '../../../../components/ConditionalRender'
import GoBackButton from '../../../../components/GoBackButton'

const CircleStatus = props => {
  const { active } = props
  return (
    <div
      className={clsx(
        styles.circleStatus,
        active ? styles.statusActive : styles.statusNotActive
      )}
    />
  )
}

const Description = props => {
  const { banner } = props
  const parsedDays = parseWorkingDays(banner.scheduleDays)

  const showAt = parsedDays.every(d => d.active === '1')
    ? 'Ежедневно'
    : parsedDays.flatMap(i => (i.active === '0' ? [] : [i.title])).join(', ')

  return (
    <ul className={styles.descriptionList}>
      <li>
        <p>Описание акции: </p>
        <p>{banner.description}</p>
      </li>
      <li>
        <p>Дата создания баннера: </p>
        <p>{formatDate(banner.dateCreate)}</p>
      </li>
      <li>
        <p>Время показа баннера: </p>
        <p>
          {banner.schedulePeriod === 1
            ? `Еженедельно \u2013 ${showAt}.`
            : `Кажд. ${banner.schedulePeriod} нед. \u2013 ${showAt}.`}
          {` С ${parseTime(banner.ScheduleTimeStart)} до ${parseTime(
            banner.ScheduleTimeEnd
          )}`}
        </p>
      </li>
      <li>
        <p>Срок работы баннера: </p>
        <p>
          с {formatDate(banner.dateStart, false)} до {formatDate(banner.dateEnd, false)}
        </p>
      </li>
    </ul>
  )
}

const DetailBanner = () => {
  const params = useParams()

  const history = useHistory()

  const [banner, setBanner] = useState({})
  const [deletePrompt, setDeletePrompt] = useState({
    open: false,
    name: '',
    loading: false
  })
  const [modalOpen, setModalOpen] = useState(false)

  const getBanner = useCallback(async () => {
    const res = await axios.get(`promotion/banner/${params.id}`)
    setBanner(res.banner)
  }, [params.id])

  useEffect(() => {
    getBanner()
  }, [getBanner])

  const handleEditBanner = () => {
    setModalOpen(true)
  }

  const handleCloseModal = () => {
    setModalOpen(false)
  }

  const handleChangeSwitcher = async () => {
    await axios.put(`promotion/banner/${params.id}/status?active=${!banner.active}`)
    getBanner()
  }

  const handleDeleteBanner = () => {
    setDeletePrompt({
      open: true,
      name: banner?.title || '',
      loading: false
    })
  }

  const deleteProduct = async () => {
    try {
      setDeletePrompt(s => ({ ...s, loading: true }))
      await axios.delete(`promotion/banner/${params.id}`)
    } catch {
    } finally {
      setDeletePrompt(s => ({
        ...s,
        open: false,
        loading: false
      }))
      history.push('/promotions/banners')
    }
  }

  const handleCloseEditing = () => {
    setModalOpen(false)
    getBanner()
  }

  const handleCloseDeletePrompt = () => {
    setDeletePrompt(s => ({
      ...s,
      open: false
    }))
  }

  return (
    <PageLoader loading={Object.keys(banner).length === 0}>
      {banner.uid ? (
        <>
          <PageTopContainer>
            <div className={styles.titleContainer}>
              <GoBackButton />
              <CircleStatus active={banner.active} />
              <h1>{banner.title}</h1>
            </div>
          </PageTopContainer>

          <div className={styles.container}>
            <div className={styles.image}>
              <ImageCard url={banner.image} name={banner.title} />
            </div>
            <div className={styles.right}>
              <div className={styles.firstRow}>
                <div className={styles.productInfo}>
                  <p>{banner.title}</p>
                </div>
                <div className={styles.icons}>
                  <ConditionalRender roles={['dealer', 'dealerEmployee']}>
                    <IconButton icon={edit} onClick={handleEditBanner} />
                    <IconButton icon={cancel} onClick={handleDeleteBanner} />
                  </ConditionalRender>
                </div>
              </div>
              <div className={styles.secondRow}>
                <Description banner={banner} />
              </div>
              <SwitchButton
                id="active"
                onLabel="вкл."
                offLabel="выкл."
                checked={banner.active}
                onChange={handleChangeSwitcher}
                className={styles.switcher}
              />
            </div>
          </div>

          <Collapse title="Кому показывать">
            <div className={styles.showToWhom}>
              {banner.schools?.length
                ? banner.schools.map((s, i) => (
                    <>
                      <p>{`${s.name}, ${s.address}`}</p>
                      {i !== banner.schools.length - 1 && <br />}
                    </>
                  ))
                : 'Всем'}
            </div>
          </Collapse>
          <ConfirmDialog
            isOpen={deletePrompt.open}
            message={`Вы уверены, что хотите удалить баннер ${deletePrompt.name}?`}
            onOk={deleteProduct}
            onCancel={handleCloseDeletePrompt}
            loading={deletePrompt.loading}
          />
          <Modal isOpen={modalOpen} onClose={handleCloseModal} className={styles.modal}>
            <BannerModal onCloseEvent={handleCloseEditing} banner={banner} />
          </Modal>
        </>
      ) : (
        <NothingFound />
      )}
    </PageLoader>
  )
}

export default DetailBanner
