import { useMemo, useState, useEffect, useCallback } from 'react'
import Table from '../../../../components/Table'
import ConfirmDialog from '../../../../components/ConfirmDialog'
import PageTopContainer from '../../../../components/PageTopContainer'
import ConditionalRender from '../../../../components/ConditionalRender'
import AddButton from '../../../../components/AddButton'
import IconButton from '../../../../components/IconButton'
import edit from '../../../../icons/edit.svg'
import bigArrow from '../../../../icons/arrow-big.svg'
import cancel from '../../../../icons/cancel.svg'
import axios from '../../../../utils/axios'
import styles from './styles.module.scss'
import Modal from '../../../../components/Modal'
import { useHistory, useParams } from 'react-router'
import CitiesModal from '../CititesModal'
import DistrictModal from './DistrictsModal'
import GoBackButton from '../../../../components/GoBackButton'

const Districts = () => {
  const history = useHistory()
  const params = useParams()

  const [data, setData] = useState([])
  const [addModalOpen, setAddModalOpen] = useState(false)
  const [city, setCity] = useState({})
  const [modalEditOpen, setModalEditOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [deletePrompt, setDeletePrompt] = useState({
    open: false,
    name: '',
    id: '',
    loading: false
  })
  const getData = useCallback(async () => {
    try {
      setLoading(true)
      const res = await axios.get(`nomenclature/city/${params.id}`)
      setData(res.city.districts)
      setCity({ name: res.city.name, uid: res.city.uid })
    } catch {
    } finally {
      setLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    getData()
  }, [getData])

  const columns = useMemo(
    () => [
      {
        Header: '',
        accessor: 'name',
        disableSortBy: true
      },
      {
        Header: '',
        accessor: 'uid',
        disableSortBy: true,
        Cell: ({ value, row }) => (
          <ConditionalRender authKey="allow-nomenclature-city-update">
            <div className={styles.buttons}>
              <IconButton
                icon={bigArrow}
                onClick={handleEditClick(row.original)}
                className={styles.arrowIcon}
              />
              <IconButton icon={cancel} onClick={handleOpenDeletePrompt(row.original)} />
            </div>
          </ConditionalRender>
        )
      }
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  const handleOpenDeletePrompt = row => () => {
    setDeletePrompt({
      open: true,
      name: row.name,
      id: row.uid
    })
  }

  const handleClickAddProduct = () => {
    setAddModalOpen(!addModalOpen)
  }

  const handleCloseEditModal = () => {
    setModalEditOpen(false)
    onAddModalClose()
  }

  const handleEditCity = () => {
    setModalEditOpen(true)
  }

  const onAddModalClose = () => {
    setAddModalOpen(false)
    getData()
  }
  const handleDeleteDistrict = async () => {
    try {
      setDeletePrompt(s => ({ ...s, loading: true }))
      await axios.delete(`nomenclature/district/${deletePrompt.id}`)
    } catch {
    } finally {
      setDeletePrompt(s => ({
        ...s,
        open: false,
        loading: false
      }))
      getData()
    }
  }

  const handleEditClick = row => () => {
    history.push(`/nom/city/${params.id}/district/${row.uid}`)
  }

  const handleCloseDeletePrompt = () => {
    setDeletePrompt(s => ({
      ...s,
      open: false
    }))
  }

  return (
    <div>
      <PageTopContainer>
        <div className={styles.firstRow}>
          <GoBackButton />
          <h1>Районы</h1>
        </div>
        <div className={styles.secondRow}>
          <div class={styles.titleContainer}>
            <h2>г.{city.name}</h2>
            <ConditionalRender authKey="allow-nomenclature-city-update">
              <IconButton icon={edit} onClick={handleEditCity} />
            </ConditionalRender>
          </div>
          <ConditionalRender authKey="allow-nomenclature-city-update">
            <AddButton
              label="Добавить район"
              isOpen={addModalOpen}
              onClick={handleClickAddProduct}
              onClose={handleClickAddProduct}
            >
              <DistrictModal onCloseEvent={onAddModalClose} cityId={params.id} />
            </AddButton>
          </ConditionalRender>
        </div>
      </PageTopContainer>
      <Table loading={loading} data={data} columns={columns} className={styles.table} />
      <Modal centered isOpen={modalEditOpen} onClose={handleCloseEditModal}>
        <CitiesModal onCloseEvent={handleCloseEditModal} city={city} />
      </Modal>

      <ConfirmDialog
        isOpen={deletePrompt.open}
        message={`Вы уверены, что хотите удалить район ${deletePrompt.name}?`}
        onOk={handleDeleteDistrict}
        onCancel={handleCloseDeletePrompt}
        loading={deletePrompt.loading}
      />
    </div>
  )
}

export default Districts
