import { useMemo, useState, useEffect, useCallback } from 'react'
import clsx from 'clsx'
import Button from './../Button'
import IconButton from './../IconButton'
import Tooltip from './../Tooltip'
import Counter from './../Counter'
import plus from '../../icons/plus.svg'
import minus from '../../icons/minus.svg'
import cell1 from '../../icons/cell-1.svg'
import cell15 from '../../icons/cell-1.5.svg'
import cell2 from '../../icons/cell-2.svg'
import styles from './styles.module.scss'
import axios from '../../utils/axios'

const MAX_NUMBER_OF_SHELVES = 8
const ADDITIONAL_SHELVES_INDEXES = [6, 7]
const DEFAULT_DEPTH = 12
// const ALL_MOTORS = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]

export const defaultShelf = {
  cells: [
    {
      size: 1,
      depth: DEFAULT_DEPTH,
      motor: 1,
      filled: 0
    },
    {
      size: 1,
      depth: DEFAULT_DEPTH,
      motor: 2,
      filled: 0
    },
    {
      size: 1,
      depth: DEFAULT_DEPTH,
      motor: 3,
      filled: 0
    },
    {
      size: 1,
      depth: DEFAULT_DEPTH,
      motor: 4,
      filled: 0
    },
    {
      size: 1,
      depth: DEFAULT_DEPTH,
      motor: 5,
      filled: 0
    },
    {
      size: 1,
      depth: DEFAULT_DEPTH,
      motor: 6,
      filled: 0
    },
    {
      size: 1,
      depth: DEFAULT_DEPTH,
      motor: 7,
      filled: 0
    },
    {
      size: 1,
      depth: DEFAULT_DEPTH,
      motor: 8,
      filled: 0
    },
    {
      size: 1,
      depth: DEFAULT_DEPTH,
      motor: 9,
      filled: 0
    },
    {
      size: 1,
      depth: DEFAULT_DEPTH,
      motor: 10,
      filled: 0
    },
    {
      size: 1,
      depth: DEFAULT_DEPTH,
      motor: 11,
      filled: 0
    },
    {
      size: 1,
      depth: DEFAULT_DEPTH,
      motor: 12,
      filled: 0
    }
  ]
}

export const deepCloneSchema = schema => {
  return {
    shelves: schema.shelves.map(s => ({
      cells: s.cells.map(c => ({ ...c }))
    }))
  }
}

function Cell(props) {
  const {
    size,
    depth,
    number,
    motor,
    selected,
    selectable = true,
    handleSelectCell,
    isEmpty
  } = props

  return (
    <div
      className={clsx(
        styles.cell,
        size === 1 && styles.single,
        size === 1.5 && styles.oneAndAHalf,
        size === 2 && styles.double,
        selected && styles.selected,
        selectable && styles.selectable,
        isEmpty === false && styles.notEmpty
      )}
      onClick={handleSelectCell}
    >
      <div className={styles.cellNumber}>{number}</div>
      <div className={styles.cellDepth}>{depth}</div>
      <div className={styles.cellSpringNumber}>{`№${motor}`}</div>
    </div>
  )
}

function Shelf(props) {
  const { number, cells, className } = props

  return (
    <div className={clsx(styles.shelf, className)}>
      <div className={styles.number}>{number}</div>
      {cells}
    </div>
  )
}

function AddableShelf(props) {
  const {
    number,
    cells,
    active,
    disableAddingAndDeleting,
    canBeAddedOrDeleted,
    handleAddNewShelf,
    handleDeleteShelf
  } = props

  return (
    <div className={styles.addableShelfContainer}>
      <Shelf
        number={number}
        cells={cells}
        className={clsx(!active && styles.nonActive)}
      />
      {canBeAddedOrDeleted && (
        <Tooltip content={active ? 'Удалить полку' : 'Добавить полку'}>
          {!disableAddingAndDeleting && (
            <button
              type="button"
              onClick={active ? handleDeleteShelf : handleAddNewShelf}
              disabled={!canBeAddedOrDeleted}
              className={styles.addableShelfButton}
            >
              <img src={active ? minus : plus} alt="" />
            </button>
          )}
        </Tooltip>
      )}
    </div>
  )
}

export default function KioskSchema(props) {
  const { schema, onChange, selectable, onSelect, editingMode } = props
  const extSelectedCells = props.selectedCells

  const [selectedCells, setSelectedCells] = useState(extSelectedCells || [])
  const [cellDepth, setCellDepth] = useState(DEFAULT_DEPTH)
  const [springNumber, setSpringNumber] = useState(0)

  useEffect(() => {
    if (extSelectedCells?.length === 0) {
      setSelectedCells([])
    }
  }, [extSelectedCells?.length])

  const handleChangeDepth = depth => {
    setCellDepth(+depth)
  }

  const handleChangeSpringNumber = num => {
    setSpringNumber(num - 1)
  }

  const handleSelectCell = useCallback(
    (sNum, idx, size, number, uid, motor, depth, isEmpty) => () => {
      const newSelectedCells = [...selectedCells]

      const cellIdx = newSelectedCells.findIndex(c => c.sNum === sNum && c.idx === idx)
      if (cellIdx === -1) {
        newSelectedCells.push({ sNum, idx, size, number, uid, motor, depth, isEmpty })
      } else {
        newSelectedCells.splice(cellIdx, 1)
      }

      setSelectedCells(newSelectedCells)
      if (onSelect) {
        onSelect(newSelectedCells)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedCells]
  )

  const mapCb = useCallback(
    sNum => (i, idx) => {
      const cellIdx = idx + 1
      const shelfIdx = sNum - 1
      const number = String(sNum) + (cellIdx < 10 ? `0${cellIdx}` : cellIdx)
      const selected = selectedCells.some(c => c.sNum === shelfIdx && c.idx === idx)

      return (
        <Cell
          key={number}
          size={i.size}
          depth={i.depth}
          number={number}
          motor={i.motor}
          selected={selected}
          isEmpty={i.isEmpty}
          handleSelectCell={handleSelectCell(
            shelfIdx,
            idx,
            i.size,
            number,
            i.uid,
            i.motor,
            i.depth,
            i.isEmpty
          )}
        />
      )
    },
    [handleSelectCell, selectedCells]
  )

  const handleAddNewShelf = useCallback(
    idx => {
      const newSchema = deepCloneSchema(schema)

      newSchema.shelves.push({
        cells: defaultShelf.cells.map(c => ({ ...c }))
      })

      onChange(newSchema)
    },
    [schema, onChange]
  )

  const handleDeleteShelf = useCallback(
    idx => {
      const newSchema = deepCloneSchema(schema)

      newSchema.shelves.pop()
      const newSelectedCells = [...selectedCells].filter(
        c => c.sNum !== newSchema.shelves.length
      )
      setSelectedCells(newSelectedCells)

      onChange(newSchema)
    },
    [schema, onChange, selectedCells]
  )

  const shelves = useMemo(() => {
    const shelves = []

    for (let i = 0; i < MAX_NUMBER_OF_SHELVES; i++) {
      const inc = i + 1
      const shelf = !ADDITIONAL_SHELVES_INDEXES.includes(i) ? (
        <Shelf key={i} number={inc} cells={schema.shelves[i].cells.map(mapCb(inc))} />
      ) : (
        <AddableShelf
          key={i}
          number={inc}
          canBeAddedOrDeleted={
            schema.shelves.length === i || schema.shelves.length - 1 === i
          }
          active={schema.shelves.length - 1 >= i}
          handleAddNewShelf={handleAddNewShelf}
          handleDeleteShelf={handleDeleteShelf}
          cells={
            schema.shelves[i]?.cells.map(mapCb(inc)) || defaultShelf.cells.map(mapCb(inc))
          }
          disableAddingAndDeleting={!selectable}
        />
      )

      shelves.push(shelf)
    }

    return shelves
  }, [schema, handleAddNewShelf, handleDeleteShelf, mapCb, selectable])

  const handleMergeCells = size => () => {
    const [first, second] = selectedCells
    const shelfNum = first.sNum

    const newSchema = deepCloneSchema(schema)
    const cells = newSchema.shelves[shelfNum].cells

    if (size === 2) {
      cells.splice(Math.min(first.idx, second.idx), 2, {
        size,
        depth: DEFAULT_DEPTH,
        motor: first.motor
      })
    } else if (size === 1.5) {
      cells[first.idx].size = 1.5
      cells[second.idx].size = 1.5

      let indexOfLastSingleCell
      for (let i = cells.length - 1; i > -1; i--) {
        if (cells[i].size === 1) {
          indexOfLastSingleCell = i
          break
        }
      }

      cells.splice(indexOfLastSingleCell, 1)
    }

    onChange(newSchema)
    setSelectedCells([])
  }

  const handleDivideCells = () => {
    const newSchema = deepCloneSchema(schema)
    const cells = newSchema.shelves[selectedCells[0].sNum].cells

    if (selectedCells.length === 1) {
      const [cell] = selectedCells
      cells.splice(
        cell.idx,
        1,
        { size: 1, depth: DEFAULT_DEPTH, motor: cell.motor },
        { size: 1, depth: DEFAULT_DEPTH, motor: ++cell.motor }
      )
    } else if (selectedCells.length === 2) {
      const [first, second] = selectedCells
      cells.splice(first.idx, 1, { size: 1, depth: DEFAULT_DEPTH, motor: first.motor })
      cells.splice(second.idx, 1, { size: 1, depth: DEFAULT_DEPTH, motor: second.motor })

      // Нужно вернуть ячейку, что убирали при слиянии, поэтому ищем свободный номер моторчика
      const motors = cells.map(c => c.motor)
      let leftMotor
      for (let i = 1; i < 13; i++) {
        if (!motors.includes(i)) {
          leftMotor = i
        }
      }

      cells.push({ size: 1, depth: DEFAULT_DEPTH, motor: leftMotor })
    }

    onChange(newSchema)
    setSelectedCells([])
  }

  const areCellsMergeableIntoDouble = useMemo(() => {
    if (selectedCells.length === 2 && selectedCells.every(c => c.size === 1)) {
      const [first, second] = selectedCells
      return first.sNum === second.sNum && Math.abs(first.idx - second.idx) === 1
    } else {
      return false
    }
  }, [selectedCells])

  const areCellsMergeableIntoOneAndAHalf = useMemo(() => {
    if (selectedCells.length === 2 && selectedCells.every(c => c.size === 1)) {
      const [first, second] = selectedCells

      if (first.sNum !== second.sNum) {
        return false
      }

      const numOfSingleCells = schema.shelves[first.sNum].cells.reduce(
        (p, c) => (p += c.size === 1 ? 1 : 0),
        0
      )
      return numOfSingleCells > 2
    } else {
      return false
    }
  }, [selectedCells, schema.shelves])

  const areCellsDivisible = useMemo(() => {
    if (selectedCells.length === 1) {
      return selectedCells[0].size === 2
    } else if (selectedCells.length === 2) {
      const [first, second] = selectedCells
      return first.size === 1.5 && second.size === 1.5 && first.sNum === second.sNum
    } else {
      return false
    }
  }, [selectedCells])

  const handleSetSelectedCellsDepth = () => {
    const newSchema = deepCloneSchema(schema)

    for (const { sNum, idx } of selectedCells) {
      newSchema.shelves[sNum].cells[idx].depth = cellDepth
    }

    setSelectedCells([])
    onChange(newSchema)
  }

  const handleSetSpringNumber = () => {
    const newSchema = deepCloneSchema(schema)
    const [cell] = selectedCells
    newSchema.shelves[cell.sNum].cells[cell.idx].motor = springNumber + 1

    setSelectedCells([])
    onChange(newSchema)
  }

  const handleTestSpring = async () => {
    // Тест пружины, API запрос
    const { sNum, motor } = selectedCells[0]
    const kioskUid = document.location.pathname.split('/')[3]
    await axios({
      method: 'get',
      url: `terminal/support/${kioskUid}/${sNum + 1}/${motor}`,
      responseType: 'stream'
    })
  }

  const handleStartCalibration = async () => {
    const kioskUid = document.location.pathname.split('/')[3]

    await axios({
      method: 'get',
      url: `terminal/support/${kioskUid}/calibration`,
      responseType: 'stream'
    })
  }

  let length = selectedCells.length !== 1
  let empty = selectedCells[0]?.isEmpty !== true
  // Валидация на наличие товара
  let disabled = length || empty === true ? true : false

  return (
    <>
      {selectable && (
        <>
          {editingMode && (
            <div style={{ display: 'flex', alignSelf: 'flex-end' }}>
              <div style={{ marginRight: '15px' }} className={styles.testSpring}>
                <Button
                  white
                  className={styles.testSpringButton}
                  onClick={handleTestSpring}
                  disabled={disabled}
                >
                  Запустить пружину
                </Button>
              </div>
              <div className={styles.testSpring}>
                <Button
                  white
                  className={styles.testSpringButton}
                  onClick={handleStartCalibration}
                >
                  Калибровка
                </Button>
              </div>
            </div>
          )}
          <div className={styles.selectableContainer}>
            <div className={styles.firstRow}>
              <h3>{'Вместимость пружины                       Номер моторчика'}</h3>
            </div>
            <div className={styles.secondRow}>
              <div className={styles.depthContainer}>
                <Counter
                  min={5}
                  max={16}
                  value={cellDepth}
                  onChange={handleChangeDepth}
                  bigAndFlat
                  className={styles.counter}
                />
                <Button
                  white
                  className={styles.controlButton}
                  disabled={selectedCells.length === 0}
                  onClick={handleSetSelectedCellsDepth}
                >
                  Установить
                </Button>
                <Counter
                  min={1}
                  max={12}
                  value={springNumber + 1}
                  onChange={handleChangeSpringNumber}
                  bigAndFlat
                  className={styles.counter}
                />
                <Button
                  white
                  className={styles.controlButton}
                  disabled={selectedCells.length !== 1}
                  onClick={handleSetSpringNumber}
                >
                  Установить
                </Button>
              </div>
              <div className={styles.controls}>
                <Tooltip content={<p>Разделить ячейки</p>}>
                  <IconButton
                    icon={cell1}
                    className={styles.iconButton}
                    disabled={!areCellsDivisible}
                    onClick={handleDivideCells}
                  />
                </Tooltip>

                <Tooltip leftSide content={<p>Объединить ячейки в полуторную ячейку</p>}>
                  <IconButton
                    icon={cell15}
                    className={styles.iconButton}
                    disabled={!areCellsMergeableIntoOneAndAHalf}
                    onClick={handleMergeCells(1.5)}
                  />
                </Tooltip>

                <Tooltip leftSide content={<p>Объединить ячейки в двойную ячейку</p>}>
                  <IconButton
                    icon={cell2}
                    className={styles.iconButton}
                    disabled={!areCellsMergeableIntoDouble}
                    onClick={handleMergeCells(2)}
                  />
                </Tooltip>
              </div>
            </div>
          </div>
        </>
      )}
      <div className={styles.container}>{shelves}</div>
    </>
  )
}
