/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { useFormik } from 'formik'
import * as yup from 'yup'
import XLSX from 'xlsx'
import FileSaver from 'file-saver'
import { useCallback, useEffect, useMemo, useState } from 'react'
import Table from '../../components/Table'
import PageTopContainer from '../../components/PageTopContainer'
import Calendar from '../../components/Calendar'
import Button from '../../components/Button'
import { useSearch, useProfile } from '../../hooks/useContext'
import axios from '../../utils/axios'
import Select from '../../components/Select'
import { deserializeListObject } from '../../utils/deserializeList'
import styles from './styles.module.scss'
import moment from 'moment'
import SelectSingle from '../../components/SelectSingle'
import ConditionalRender from '../../components/ConditionalRender'

const Reports = () => {
  const { value } = useSearch()
  const { user } = useProfile()

  const [data, setData] = useState([])
  const [dataExcel, setDataExcel] = useState([])
  const [loading, setLoading] = useState(false)
  const [pagination, setPagination] = useState({
    rowCount: 10,
    pageNum: 1,
    pageCount: 1
  })
  const [cities, setCities] = useState([])
  const [schools, setSchools] = useState([])
  const [kiosks, setKiosks] = useState([])
  const [types, setTypes] = useState([])
  const [dealers, setDealers] = useState([])
  const [statuses, setStatuses] = useState([])
  const [excelMode, setExcelMode] = useState(false)

  useEffect(() => {
    setStatuses([
      { id: 1, label: 'Холдирование' },
      { id: 2, label: 'В обработке' },
      { id: 3, label: 'Отменена' },
      { id: 4, label: 'Завершена' }
    ])
  }, [])

  const exportToCSV = (csvData, fileName) => {
    const fileType =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
    const fileExtension = '.xlsx'
    const ws = XLSX.utils.json_to_sheet(csvData)
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] }
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
    const data = new Blob([excelBuffer], { type: fileType })
    FileSaver.saveAs(data, fileName + fileExtension)
  }

  const dataForExport = data => {
    function getProp(object, keys, defaultVal) {
      keys = Array.isArray(keys) ? keys : keys.split('.')
      object = object[keys[0]]
      if (object && keys.length > 1) {
        return getProp(object, keys.slice(1))
      }
      return object === undefined ? defaultVal : object
    }

    const dataForExport = []
    const temp = []
    const headers = columns.map(item => item.Header)

    data.forEach(row => {
      temp.push(
        columns.map(col => {
          const val = getProp(row, col.accessor)
          return val
        })
      )
    })

    temp.forEach(item => {
      dataForExport.push(Object.assign(...headers.map((k, i) => ({ [k]: item[i] }))))
    })

    return dataForExport
  }

  const handleExportToExcel = () => {
    exportToCSV(dataForExport(dataExcel), 'Отчет')
  }

  let typeSelected = types.find(obj => {
    return obj.selected === true
  })

  const getReport = useCallback(
    async filter => {
      try {
        setLoading(true)
        const res = await axios.get('report/result', {
          params: {
            ...filter,
            'Pagination.RowCount': pagination.rowCount,
            'Pagination.PageNum': pagination.pageNum,
            UserTimeZoneId: Intl.DateTimeFormat().resolvedOptions().timeZone,
            text: value
          }
        })

        if (filter.reportType !== 'ConsolidatedReportCheques') {
          setPagination(p => ({ ...p, pageCount: res.pagination.pageCount }))
        }

        const newData = res.report.map(item => ({
          ...item,
          quantity: `${item.quantity} шт`,
          sum: `${item.sum} ₽`,
          dateReg: moment(item.dateReg).format('DD.MM.YYYY HH:mm')
        }))

        setData(newData)
      } catch {
      } finally {
        setLoading(false)
      }
    },
    [pagination.rowCount, pagination.pageNum, value]
  )

  const getDealers = async () => {
    const res = await axios.get('/kiosk/dealers')
    if (res?.dealers) {
      setDealers(res.dealers.map(d => deserializeListObject(d)))
    }
  }

  useEffect(() => {
    getDealers()
  }, [])

  const getCities = useCallback(async () => {
    const res = await axios.get('kiosk/cities')
    setCities(res.cities?.map(c => deserializeListObject(c)))
  }, [])

  const getSchools = useCallback(async array => {
    const res = await axios.get('catalogue/schools', {
      params: { selectedCities: [array] }
    })
    setSchools(
      res.schools?.map(s => ({
        ...deserializeListObject(s),
        sublabel: s.address
      }))
    )
  }, [])

  const getKiosks = useCallback(async array => {
    const res = await axios.get('catalogue/kiosks', {
      params: { selectedSchools: [array] }
    })
    setKiosks(
      res.kiosks?.map(k => ({
        ...deserializeListObject(k),
        sublabel: k.address
      }))
    )
  }, [])

  const getTypes = useCallback(async () => {
    const res = await axios.get('report/types')
    setTypes(res.types?.map(t => deserializeListObject(t)))
  }, [])

  useEffect(() => {
    if (!cities.length) {
      getCities()
    }
    if (!types.length) {
      getTypes()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const buildParams = values => {
    const params = {
      dateRegEnd: values.dateRegEnd,
      dateRegStart: values.dateRegStart,
      reportType: values.reportType
    }

    if (values.selectedCities.length) {
      params.selectedCities = values.selectedCities
    }

    if (values.selectedStatuses.length) {
      params.selectedStatuses = values.selectedStatuses
    }

    if (values.selectedSchools.length) {
      params.selectedSchools = values.selectedSchools
    }

    if (values.selectedKiosks.length) {
      params.selectedKiosks = values.selectedKiosks
    }

    if (values.selectedDealers.length) {
      params.selectedDealers = values.selectedDealers
    }

    return params
  }

  const handleSave = async values => {
    const params = buildParams(values)
    await getReport(params)
  }

  const handleChangeSelect = name => value => {
    const selected = value.flatMap(i => (i.selected ? [i.id] : []))

    switch (name) {
      case 'selectedCities':
        getSchools(selected)
        setCities(value)
        break

      case 'selectedSchools':
        getKiosks(selected)
        setSchools(value)
        break

      case 'selectedKiosks':
        setKiosks(value)
        break

      case 'selectedDealers':
        setDealers(value)
        break

      case 'selectedStatuses':
        setStatuses(value)
        break

      default:
        break
    }
    setFieldValue(name, selected)
  }

  const handlePaginationChange = p => {
    setPagination(p)
    handleView()
  }

  const handleChangeCalendar = value => {
    setFieldValue(
      'dateRegStart',
      value.startDate ? moment(value.startDate).format('DD.MM.YYYY') : ''
    )
    setFieldValue(
      'dateRegEnd',
      value.endDate ? moment(value.endDate).format('DD.MM.YYYY') : ''
    )
  }

  const columns = useMemo(
    () => [
      {
        Header: 'Дилер',
        accessor: 'dealerName',
        disableSortBy: true
      },
      {
        Header: 'Учреждение',
        accessor: 'schoolName',
        disableSortBy: true
      },
      {
        Header: 'Название киоска',
        accessor: 'kioskAlias',
        disableSortBy: true
      },
      {
        Header: 'Дата оформления заказа',
        accessor: 'dateReg',
        disableSortBy: true
      },
      {
        Header: 'Сумма',
        accessor: 'sum',
        disableSortBy: true
      },
      {
        Header: 'Покупатель',
        accessor: 'userName',
        disableSortBy: true
      },
      {
        Header: 'Статус',
        accessor: 'payState',
        disableSortBy: true
      }
    ],
    [typeSelected]
  )

  const columnsProducts = useMemo(
    () => [
      {
        Header: 'Наименование позиции',
        accessor: 'name',
        disableSortBy: true
      },
      {
        Header: 'Цена',
        accessor: 'price',
        disableSortBy: true
      },
      {
        Header: 'Продажа кол-во',
        accessor: 'soldQuantity',
        disableSortBy: true
      },
      {
        Header: 'Продажа сумма',
        accessor: 'soldSum',
        disableSortBy: true
      },
      {
        Header: 'Возврат кол-во',
        accessor: 'refundQuantity',
        disableSortBy: true
      },
      {
        Header: 'Возврат сумма',
        accessor: 'refundSum',
        disableSortBy: true
      },
      {
        Header: 'Итого кол-во',
        accessor: 'quantity',
        disableSortBy: true
      },
      {
        Header: 'Итого сумма продаж',
        accessor: 'sum',
        disableSortBy: true
      }
    ],
    [typeSelected]
  )

  const columnsCheques = useMemo(
    () => [
      {
        Header: 'п/п',
        accessor: 'number',
        disableSortBy: true
      },
      {
        Header: 'Дата',
        accessor: 'date',
        disableSortBy: true
      },
      {
        Header: 'Кол-во',
        accessor: 'quantity',
        disableSortBy: true
      },
      {
        Header: 'Сумма',
        accessor: 'sum',
        disableSortBy: true
      }
    ],
    [typeSelected]
  )

  const { values, errors, touched, setFieldValue, handleSubmit } = useFormik({
    onSubmit: values => {
      if (excelMode) {
        downloadExcel(values)
      } else {
        handleSave(values)
      }
    },
    initialValues: {
      isDealersRequired:
        user?.role === 'dealerEmployee' || user?.role === 'dealer' ? false : true,
      selectedCities: [],
      selectedSchools: [],
      selectedKiosks: [],
      selectedDealers: [],
      selectedStatuses: [],
      dateRegStart: '',
      dateRegEnd: '',
      reportType: 'Cheques'
    },
    validationSchema: yup.object().shape({
      isDealersRequired: yup.boolean(),
      selectedCities: yup.array().notRequired(),
      selectedSchools: yup.array().notRequired(),
      selectedKiosks: yup.array().notRequired(),
      selectedStatuses: yup.array().notRequired(),
      dateRegEnd: yup
        .string()
        .matches(
          /^([0-2][0-9]|(3)[0-1])(\.)(((0)[0-9])|((1)[0-2]))(\.)\d{4}$/,
          'Неверный формат даты'
        )
        .required('Не выбраны даты'),
      selectedDealers: yup.array().when('isDealersRequired', {
        is: true,
        then: yup.array().min(1, 'Не выбраны дилеры'),
        otherwise: yup.array().notRequired()
      }),
      reportType: yup.string().required('Не выбран тип отчета')
    }),
    enableReinitialize: true
  })

  const handleView = event => {
    event?.preventDefault()
    setExcelMode(false)
    handleSubmit()
  }
  const handleExcel = () => {
    setExcelMode(true)
    handleSubmit()
  }
  const downloadExcel = async () => {
    const params = buildParams(values)
    try {
      await axios.get('report/excel', {
        toast: true,
        params: {
          ...params,
          'Pagination.RowCount': pagination.rowCount,
          'Pagination.PageNum': pagination.pageNum,
          UserTimeZoneId: Intl.DateTimeFormat().resolvedOptions().timeZone,
          text: value
        },
        responseType: 'blob'
      })
    } catch (response) {
      const url = window.URL.createObjectURL(new Blob([response]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `Отчет.xlsx`) //or any other extension
      document.body.appendChild(link)
      link.click()
    }
  }

  return (
    <div>
      <PageTopContainer>
        <div className={styles.firstRow}>
          <div>
            <h1>Отчеты</h1>
            <span>Киоски</span>
          </div>
        </div>
        <div className={styles.secondRow}>
          <form onSubmit={handleView}>
            <div className={styles.gridInputs}>
              <Calendar
                withoutTime={true}
                right={false}
                style={{ width: 210 }}
                name="dateReg"
                required={true}
                text="Выберите дату"
                onSave={handleChangeCalendar}
                className={styles.calendar}
                value={
                  values.dateRegEnd || values.dateRegStart
                    ? {
                        dateStart: values.dateRegStart,
                        dateEnd: values.dateRegEnd
                      }
                    : null
                }
                errorMessage={touched.dateRegEnd && errors.dateRegEnd}
              />
              <ConditionalRender roles={['admin']}>
                <Select
                  text="Выберите дилеров"
                  options={dealers}
                  required={true}
                  value={values.selectedDealers}
                  errorMessage={touched.selectedDealers && errors.selectedDealers}
                  onSave={handleChangeSelect('selectedDealers')}
                  className={styles.selectorContainer}
                />
              </ConditionalRender>
              <SelectSingle
                text="Выберите тип"
                options={types}
                value={values.reportType}
                required={true}
                errorMessage={touched.reportType && errors.reportType}
                onChange={e => {
                  setFieldValue('reportType', e.id)
                  setData([])
                }}
                className={styles.selectorContainer}
              />
              {values.reportType === 'ConsolidatedReportCheques' && (
                <Select
                  text="Выберите статус"
                  options={statuses}
                  value={values.selectedStatuses}
                  errorMessage={touched.selectedStatuses && errors.selectedStatuses}
                  onSave={handleChangeSelect('selectedStatuses')}
                  className={styles.selectorContainer}
                />
              )}
              <Select
                text="Выберите город"
                options={cities}
                value={values.selectedCities}
                errorMessage={touched.selectedCities && errors.selectedCities}
                onSave={handleChangeSelect('selectedCities')}
                className={styles.selectorContainer}
              />
              <Select
                text="Выберите учреждение"
                options={schools}
                value={values.selectedSchools}
                nothingFoundText="В выбранных городах нет доступных учреждений"
                errorMessage={touched.selectedSchools && errors.selectedSchools}
                onSave={handleChangeSelect('selectedSchools')}
                className={styles.selectorContainer}
              />
              <Select
                text="Выберите киоск"
                options={kiosks}
                value={values.selectedKiosks}
                nothingFoundText="В выбранных учреждениях нет доступных киосков"
                errorMessage={touched.selectedKiosks && errors.selectedKiosks}
                onSave={handleChangeSelect('selectedKiosks')}
                className={styles.selectorContainer}
              />
            </div>

            <div className={styles.buttons}>
              <Button type="submit">Сформировать</Button>
              <Button onClick={handleExcel}>Выгрузить в Excel</Button>
            </div>
          </form>
        </div>
      </PageTopContainer>
      {data.length > 0 && (
        <Table
          loading={loading}
          data={data}
          columns={
            values.reportType === 'Cheques'
              ? columns
              : values.reportType === 'ConsolidatedReportCheques'
              ? columnsCheques
              : columnsProducts
          }
          pagination={values.reportType !== 'ConsolidatedReportCheques' && pagination}
          onPaginationChange={handlePaginationChange}
        />
      )}
    </div>
  )
}

export default Reports
