import styles from './styles.module.scss'
import clsx from 'clsx'

export default function Button(props) {
  const { children, type, onClick, className, loading, white, gray, red, disabled, ...rest } = props

  return (
    <button
      type={type || 'button'}
      onClick={onClick}
      className={clsx(
        styles.button,
        loading && styles.loading,
        white && styles.white,
        gray && styles.gray,
        red && styles.red,
        disabled && styles.disabled,
        className
      )}
      disabled={disabled || loading}
      {...rest}
    >
      {children}
    </button>
  )
}
