/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import { useState, useEffect, useMemo, useCallback } from 'react'
import clsx from 'clsx'
import { useHideSearch } from '../../../hooks/useContext'
import Collapse from '../../../components/Collapse'
import GridLoader from '../../../components/Loader'

import CardTable from '../../../components/CardTable'
import PageTopContainer from '../../../components/PageTopContainer'
import ImageCard from '../../../components/ImageCard'
import IconButton from '../../../components/IconButton'
import Indicator from '../../../components/Indicator'
import download from '../../../icons/download.svg'
import visa from '../../../icons/visa.svg'
import styles from './styles.module.scss'
import { useParams } from 'react-router'
import axios from '../../../utils/axios'
import Button from '../../../components/Button'
import ConfirmDialog from '../../../components/ConfirmDialog'
import GoBackButton from '../../../components/GoBackButton'
import { formatProductsCount } from '../../../utils/textWords'
import { useHistory } from 'react-router'
import { dateFromUTCToLocale } from '../../../utils/helper'
import moment from 'moment'
import deserializeList from '../../../utils/deserializeList'

const hideButtonStatuses = [0, 5, 6, 8]

function InfoCard(props) {
  const { title, items } = props

  return (
    <Collapse defaultOpen collapsible={false} title={title}>
      <div className={styles.cardBody}>
        {items.map((i, idx) => (
          <ul key={idx}>
            {i.map(li => (
              <li key={li.label + li.value} className={clsx(li.className)}>
                {li.label}
                <span>{li.value}</span>
              </li>
            ))}
          </ul>
        ))}
      </div>
    </Collapse>
  )
}

export default function Purchase() {
  const params = useParams()
  const history = useHistory()
  const {
    location: { state }
  } = useHistory()

  const { setHidden } = useHideSearch()
  const [data, setData] = useState({})
  const [loading, setLoading] = useState(false)
  const [cancelPayState, setCancelPayState] = useState({
    isOpen: false,
    loading: false
  })
  const [statuses, setStatuses] = useState([
    { id: 0, name: 'Ожидает оплаты' },
    { id: 1, name: 'Оплачен' },
    { id: 2, name: 'Выдан' },
    { id: 3, name: 'Товар не выдан' },
    { id: 4, name: 'Ошибка сервера' },
    { id: 5, name: 'Время оплаты истекло' },
    { id: 6, name: 'Отменен' },
    { id: 7, name: 'Ожидает выдачи' },
    { id: 8, name: 'Возврат' }
  ])

  const getStatuses = useCallback(async () => {
    const res = await axios.get('event/statuses')
    const deserialized = deserializeList(res.statuses)
    setStatuses(deserialized)
  }, [])

  const getData = useCallback(async () => {
    try {
      setLoading(true)
      const res = await axios.get(`/event/${params.id}`)
      setData(res)
    } catch {
    } finally {
      setLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    getStatuses()
    getData()
  }, [])

  useEffect(() => {
    setHidden(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const columns = useMemo(
    () => [
      {
        Header: 'Покупки',
        accessor: 'name'
      },
      {
        Header: 'Количество',
        accessor: 'quantity'
      },
      {
        Header: 'Стоимость',
        accessor: 'price',
        Cell: ({ value }) => `${value} р.`
      },
      {
        Header: 'Сумма без скидки',
        accessor: 'discontPrice',
        Cell: ({ value }) => `${value} р.`
      },
      {
        Header: 'Скидка',
        accessor: 'discount',
        Cell: ({ value }) => `${value}%`
      },
      {
        Header: 'Итоговая сумма',
        accessor: 'totalPrice',
        Cell: ({ value }) => `${value} р.`
      }
    ],
    []
  )

  if (!data?.event?.uid) {
    return (
      <div className={styles.loaderCenter}>
        <GridLoader loading={loading} />
      </div>
    )
  }

  const handleCancelPay = () => {
    setCancelPayState({ isOpen: true, isLoading: false })
  }

  const handleCancelPayClose = () => {
    setCancelPayState({ isOpen: false, isLoading: false })
  }

  const cancelPay = async () => {
    try {
      setLoading(true)
      setCancelPayState({ ...cancelPayState, loading: true })
      await axios.get(`order/pay/${data.event.uid}/cancel`, { toast: true }).then(getData)
    } catch {
    } finally {
      setLoading(false)
      await setCancelPayState({ isOpen: false, isLoading: false })
    }
  }

  return (
    <div>
      <PageTopContainer>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center'
            }}
          >
            <GoBackButton
              onClick={() => {
                if (state !== undefined) {
                  history.push(
                    `/purchase-history&pageNum=${state.pageNum}&rowCount=${state.rowCount}`,
                    {
                      rowIndex: state.rowIndex,
                      dateDeliveryEnd: state.dateDeliveryEnd,
                      dateDeliveryStart: state.dateDeliveryStart,
                      dateRegEnd: state.dateRegEnd,
                      dateRegStart: state.dateRegStart,
                      pageNum: state.pageNum,
                      rowCount: state.rowCount,
                      selectedCities: state.selectedCities,
                      selectedKiosks: state.selectedKiosks,
                      selectedSchools: state.selectedSchools,
                      selectedStatus: state.selectedStatus,
                      selectedUser: state.selectedUser
                    }
                  )
                } else {
                  history.push(`/purchase-history&pageNum=1&rowCount=10`)
                }
              }}
            />
            <h1>Лента событий {data.event.orderState === 8 && '(Возврат)'}</h1>
          </div>
          {!hideButtonStatuses.includes(data.event.orderState) && (
            <Button onClick={handleCancelPay} style={{ width: 150 }}>
              Оформить возрат
            </Button>
          )}
        </div>
      </PageTopContainer>
      <div className={styles.infocards}>
        <InfoCard
          title="Данные о покупке"
          items={[
            [
              {
                label: 'Статус заказа:',
                value: statuses[data.event.orderState].label
              },
              {
                label: 'Общее количество товара:',
                value: data.event.orderInfo.quantity
              },
              // {
              //   label: 'Сумма без скидки:',
              //   value: data.event.orderInfo.totalPrice
              //     ? `${kopeksToRubles(data.event.orderInfo.totalPrice)} р.`
              //     : `0 р.`
              // },
              // {
              //   label: 'Итоговая сумма:',
              //   value: data.event.orderInfo.sum
              //     ? `${kopeksToRubles(data.event.orderInfo.sum)} р.`
              //     : `0 р.`
              // },
              {
                label: 'Бонусов списано:',
                value: data.event.orderInfo.bonusOff
                  ? `${data.event.orderInfo.bonusOff} р.`
                  : `0 р.`
              },
              {
                label: 'Бонусов начислено:',
                value: data.event.orderInfo.bonusAdd
                  ? `${data.event.orderInfo.bonusAdd} р.`
                  : `0 р.`
              },
              {
                label: 'Итого к оплате:',
                value: data.event.orderInfo.sum
                  ? `${data.event.orderInfo.sum} р.`
                  : `0 р.`,
                className: styles.totalPriceContainer
              }
            ]
          ]}
        />

        <InfoCard
          title="Данные о клиенте"
          items={[
            [
              {
                label: 'ФИО:',
                value: data.event.clientInfo.visibleName
                  ? data.event.clientInfo.visibleName
                  : 'Отсутствует'
              },
              {
                label: 'Телефон:',
                value: data.event.clientInfo.phone
              },
              {
                label: 'Электронная почта:',
                value: data.event.clientInfo.email
                  ? data.event.clientInfo.email
                  : 'Отсутствует'
              }
            ]
          ]}
        />

        <InfoCard
          title="Техническая информация"
          items={[
            [
              {
                label: 'Идентификатор заказа:',
                value: data.event.uid || '-'
              },
              {
                label: 'Идентификатор киоска:',
                value: data.event.kioskUid || '-'
              }
            ]
          ]}
        />
      </div>
      <div className={styles.tableContainer}>
        <CardTable
          columns={columns}
          data={data.orders}
          event={{
            date: data.event.orderInfo.datePay,
            sum: data.event.orderInfo.sum
          }}
        />
      </div>
      <ConfirmDialog
        isOpen={cancelPayState.isOpen}
        loading={cancelPayState.loading}
        message={`Вы действительно хотите вернуть ${
          data.event.orderInfo.quantity
        } ${formatProductsCount(data.event.orderInfo.quantity)} на сумму ${
          data.event.orderInfo.sum ? `${data.event.orderInfo.sum}` : `0`
        } руб?`}
        onOk={cancelPay}
        onCancel={handleCancelPayClose}
      />
    </div>
  )
}
