import InfoCard from './index'
import clsx from 'clsx'
import styles from './styles.module.scss'

export default function LastSaleCard(props) {
  const { text } = props

  return (
    <InfoCard>
      <div className={styles.saleContainer}>
        <div className={styles.saleLabel}>Последняя продажа</div>
        <div className={clsx(styles.saleValue, !text && styles.nothing)}>
          {text || '-'}
        </div>
      </div>
    </InfoCard>
  )
}
