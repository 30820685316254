import clsx from 'clsx'
import styles from './styles.module.scss'

export default function ImageCard(props) {
  const { url, name, small, className } = props

  return url ? (
    <img
      src={url}
      alt={name}
      className={clsx(styles.image, small && styles.small, className)}
    />
  ) : (
    <div className={clsx(styles.placeholder, small && styles.small, className)}>✼</div>
  )
}
