import clsx from 'clsx'
import styles from './styles.module.scss'
import passShow from '../../icons/pass-show.svg'
import passHiden from '../../icons/pass-hiden.svg'
import { useState } from 'react'
import PropTypes from 'prop-types'

const regExp = /^[0-9\b]+$/
export default function TextInput(props) {
  const {
    value,
    onChange,
    placeholder,
    className,
    password,
    label,
    fullDisabled,
    fullWidth,
    numbersOnly,
    showMaxSymbols = true,
    errorMessage,
    textarea,
    maxSymbols,
    ...rest
  } = props

  const [showPass, setShowPass] = useState(false)

  const toggleVisiblityPass = () => {
    setShowPass(!showPass)
  }

  const onChangeHandler = event => {
    const value = event.target.value

    if (value === '') {
      return onChange(event)
    }

    if (numbersOnly) {
      if (regExp.test(value) && value !== '') {
        return onChange(event)
      } else {
        return null
      }
    }

    if (maxSymbols) {
      if (value.length <= maxSymbols) {
        return onChange(event)
      }
    } else {
      return onChange(event)
    }
  }

  return (
    <div className={clsx(styles.container, fullWidth && styles.fullWidth, className)}>
      {label && <label className={styles.label}>{label}</label>}
      {textarea ? (
        <textarea
          className={styles.input}
          value={value}
          onChange={onChangeHandler}
          placeholder={placeholder}
          rows={6}
          {...rest}
        />
      ) : (
        <>
          {!password ? (
            <input
              type="text"
              className={clsx(styles.input, fullDisabled && styles.fullDisabled)}
              value={value}
              onChange={onChangeHandler}
              placeholder={placeholder}
              {...rest}
            />
          ) : (
            <>
              <input
                type={showPass ? 'text' : 'password'}
                className={styles.input}
                value={value}
                onChange={onChange}
                placeholder={placeholder}
                {...rest}
              />
              {showPass ? (
                <img
                  className={styles.eye_img}
                  alt="Иконка показа пароля"
                  src={passHiden}
                  onClick={toggleVisiblityPass}
                />
              ) : (
                <img
                  className={styles.eye_img}
                  alt="Иконка скрытия пароля"
                  src={passShow}
                  onClick={toggleVisiblityPass}
                />
              )}
            </>
          )}
        </>
      )}
      {errorMessage && <p className={styles.error}>{errorMessage}</p>}
      {maxSymbols && showMaxSymbols && (
        <span className={styles.info_remain_symbols}>
          Кол-во символов {value?.length}/{maxSymbols}
        </span>
      )}
    </div>
  )
}

TextInput.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  password: PropTypes.bool,
  label: PropTypes.string,
  fullWidth: PropTypes.bool,
  numbersOnly: PropTypes.bool,
  showMaxSymbols: PropTypes.bool,
  errorMessage: PropTypes.string,
  textarea: PropTypes.bool,
  maxSymbols: PropTypes.number
}
