import clsx from 'clsx'
import InfoCard from './index'
import ProgressBar from '../ProgressBar'
import formatRuble from '../../utils/formatRuble'
import styles from './styles.module.scss'

function getInfoText(obj) {
  return `${obj.count} единиц / ${formatRuble(obj.sum)}`
}

export default function LastSaleCard(props) {
  const { capacity, inStock, awaitingStock } = props

  return (
    <InfoCard>
      <div className={styles.unitsCard}>
        <div className={styles.unitsContainer}>
          <div className={styles.unitsCol}>
            <div className={styles.unitsLabel}>
              <span className={clsx(styles.unitsStatus, styles.unitsStatusInStock)} />В
              наличии
            </div>
            <div className={styles.unitsValue}>{getInfoText(inStock)}</div>
          </div>
          <div className={styles.unitsCol}>
            <div className={styles.unitsLabel}>
              <span className={clsx(styles.unitsStatus, styles.unitsStatusPending)} />
              Ожидает выдачи
            </div>
            <div className={styles.unitsValue}>{getInfoText(awaitingStock)}</div>
          </div>
        </div>

        <ProgressBar
          max={capacity}
          value={inStock.count}
          barStyle={styles.unitsStatusInStock}
        />

        <div className={styles.unitsSpacer} />

        <ProgressBar
          max={capacity}
          value={awaitingStock.count}
          barStyle={styles.unitsStatusPending}
        />
      </div>
    </InfoCard>
  )
}
