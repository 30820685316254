import format from 'date-fns/format'

const formatDate = (timestamptz, withTime = true) => {
  if (!timestamptz) return null

  const date = new Date(timestamptz)
  const formatting = withTime ? 'dd.MM.yyyy HH:mm' : 'dd.MM.yyyy'
  return `${format(date, formatting)}`
}

export default formatDate
