const parseTime = string => {
  const [time, modifier] = string.split(' ')
  let [hours, minutes] = time.split(':')

  if (hours === '12') {
    hours = '00'
  }

  if (modifier === 'PM') {
    hours = +hours + 12
  } else {
    if (hours.length === 1) {
      hours = '0' + hours
    }
  }

  return hours + ':' + minutes
}

export default parseTime
