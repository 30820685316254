import Layout from './components/Layout'
import { BrowserRouter } from 'react-router-dom'
import { ProvideAppContext } from './hooks/useContext'
import ScrollMemory from 'react-router-scroll-memory';

function App() {
  return (
    <ProvideAppContext>
      <BrowserRouter>
          <ScrollMemory />
          <Layout />
      </BrowserRouter>
    </ProvideAppContext>
  )
}

export default App
