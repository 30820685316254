import React, { useCallback, useEffect, useMemo } from 'react'
import { useTable, useSortBy, useExpanded } from 'react-table'
import clsx from 'clsx'
import styles from './styles.module.scss'
import triangle from '../../icons/triangle.svg'

import Pagination from '../Pagination'

export default function Table(props) {
  const {
    data,
    rowIdToScroll,
    columns,
    loading,
    pagination,
    onExport,
    paginationOnTheTop,
    onSortChange,
    onPaginationChange,
    onClick,
    className,
    renderRowSubComponent,
    ...rest
  } = props

  const {
    rows,
    headerGroups,
    prepareRow,
    getTableProps,
    visibleColumns,
    getTableBodyProps,

    state: {
      sortBy
      // expanded
    }
  } = useTable(
    {
      data,
      columns,
      manualSortBy: true
    },
    useSortBy,
    useExpanded
  )

  const canExpand = useMemo(() => rows.some(row => row.canExpand), [rows])

  const handleSortChange = useCallback(
    value => {
      if (onSortChange) {
        onSortChange(value)
      }
    },
    [onSortChange]
  )

  useEffect(() => {
    handleSortChange(sortBy?.[0])
  }, [sortBy, handleSortChange])

  const handleExpand =
    ({ canExpand, isExpanded, toggleRowExpanded }) =>
    () => {
      if (canExpand) {
        toggleRowExpanded(!isExpanded)
      }
    }

  const handlePaginationChange = values => {
    if (onPaginationChange) {
      onPaginationChange(values)
    }
  }

  const el = document.getElementById(`row_${rowIdToScroll}`)
  useEffect(() => {
    if (rows.length !== 0) {
      if (rowIdToScroll) {
        if (el) {
          el.scrollIntoView({ behavior: 'smooth' })
        }
      }
    }
    // eslint-disable-next-line
  }, [el, rowIdToScroll])

  return (
    <div className={clsx(styles.table, className)}>
      {paginationOnTheTop && (
        <Pagination
          pagination={pagination}
          onChange={handlePaginationChange}
          onDownload={onExport}
        />
      )}
      <table {...getTableProps()} {...rest}>
        <thead>
          {headerGroups.map((headerGroup, index) => (
            <tr key={index} {...headerGroup.getHeaderGroupProps()}>
              {canExpand && <th />}
              {headerGroup.headers.map(header => (
                <th
                  key={header.render('Header')}
                  {...header.getHeaderProps(header.getSortByToggleProps())}
                >
                  {header.render('Header')}
                  {header.canSort && (
                    <img
                      alt="Сортировать"
                      src={triangle}
                      className={clsx(
                        header.isSorted && styles.active,
                        header.isSortedDesc && styles.rotated
                      )}
                    />
                  )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()} className={clsx(loading && styles.blurred)}>
          {rows.length === 0 ? (
            <tr>
              <td colSpan={headerGroups[0].headers.length} className={styles.empty}>
                Ничего не найдено
              </td>
            </tr>
          ) : (
            <>
              {rows.map((row, index) => {
                prepareRow(row)
                const rowProps = row.getRowProps()
                return (
                  <React.Fragment key={rowProps.key}>
                    <tr
                      key={index}
                      id={rowProps.key}
                      onClick={onClick ? onClick(row) : handleExpand(row)}
                      className={clsx((onClick || row.canExpand) && styles.clickable)}
                      {...row.getRowProps()}
                    >
                      {canExpand && row.depth === 0 && (
                        <td>
                          {row.canExpand && (
                            <img
                              alt={row.isExpanded ? 'Свернуть' : 'Развернуть'}
                              src={triangle}
                              className={clsx(row.isExpanded && styles.rotated)}
                            />
                          )}
                        </td>
                      )}
                      {row.depth === 0 ? (
                        row.cells.map((cell, index) => (
                          <td
                            key={index}
                            style={{ backgroundColor: cell.value }}
                            className={clsx(
                              cell.column.id === 'colorIndicator' && styles.indicator
                            )}
                            data-label={cell.column.Header}
                            {...cell.getCellProps()}
                          >
                            {cell.render('Cell')}
                          </td>
                        ))
                      ) : (
                        <td
                          colSpan={headerGroups[0].headers.length + (canExpand ? 1 : 0)}
                          className={styles.container}
                        >
                          {row.original.value}
                        </td>
                      )}
                    </tr>
                    {row.isExpanded &&
                      renderRowSubComponent &&
                      renderRowSubComponent({ row, rowProps, visibleColumns })}
                  </React.Fragment>
                )
              })}
            </>
          )}
        </tbody>
      </table>

      {pagination && (
        <Pagination
          pagination={pagination}
          onChange={handlePaginationChange}
          onDownload={onExport}
        />
      )}
    </div>
  )
}
