import moment from 'moment'
import FileSaver from 'file-saver'
import XLSX from 'xlsx'

// колонки и поля для выгрузки таблицы
export const exportColumns = [
  {
    title: 'Киоск',
    field: 'kiosk'
  },
  {
    title: 'Город',
    field: 'city'
  },
  {
    title: 'Дата покупки',
    field: 'datePayed'
  },
  {
    title: 'Кол-во товара',
    field: 'quantity'
  },
  {
    title: 'Итоговая сумма',
    field: 'Sum'
  },
  {
    title: 'Дата выдачи',
    field: 'dateDelivery'
  },
  {
    title: 'Номер клиента',
    field: 'clientPhone'
  },
  {
    title: 'Статус выдачи',
    field: 'status'
  }
]

// преобразуем данные в новый объект (откидываем лишнее и делам поля как в exportColumns)
export const makeData = data => {
  return {
    kiosk: data.kiosk.alias,
    city: data.kiosk.school.address,
    datePayed: data?.datePayed ? moment(data.datePayed).format('DD.MM.YYYY HH:mm') : '-',
    quantity: data.quantity,
    Sum: data.Sum,
    dateDelivery: data?.dateDelivery
      ? moment(data.dateDelivery).format('DD.MM.YYYY HH:mm')
      : '-',
    clientPhone: data?.user?.phone ?? '-',
    status: data.orderState
  }
}

// полуает объект и ключ по которому нужно найти значение
// можно прередать вложеный ключ. Например user.phone
// возвращает искомое значение из object по ключу keys
export const getProp = (object, keys, defaultVal) => {
  keys = Array.isArray(keys) ? keys : keys.split('.')
  object = object[keys[0]]
  if (object && keys.length > 1) {
    return getProp(object, keys.slice(1))
  }
  return object === undefined ? defaultVal : object
}

// функция для выгрзуки файла xlsx
/*
  @params:
    scvData: { {[Название колонки: string]: Значение: sting | number }[] }
      Пример: [
                {
                  Город: "Красноярск",
                  Номер клиента: "79999999999",
                  ...
                }
              ]
    fileName: string
*/
export const exportToCSV = (csvData, fileName) => {
  //Подгоняте ширину колонки в файле в зависимости от контента в ней
  const fitToColumn = data => {
    const columnWidths = []
    for (const property in data[0]) {
      columnWidths.push({
        wch: Math.max(
          property ? property.toString().length : 0,
          ...data.map(obj => (obj[property] ? obj[property].toString().length : 0))
        )
      })
    }
    return columnWidths
  }
  const fileType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
  const fileExtension = '.xlsx'
  const ws = XLSX.utils.json_to_sheet(csvData)
  ws['!cols'] = fitToColumn(csvData)

  const wb = { Sheets: { data: ws }, SheetNames: ['data'] }
  const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
  const data = new Blob([excelBuffer], { type: fileType })
  const fullFileName = `${fileName} ${moment().format('DD.MM.YYYY')}`
  FileSaver.saveAs(data, fullFileName + fileExtension)
}
