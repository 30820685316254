import clsx from 'clsx'
import styles from './styles.module.scss'

export default function ProgressBar(props) {
  const { max, value, barStyle } = props

  return (
    <div className={styles.container}>
      <div
        style={{
          width: `${(value / max) * 100}%`
        }}
        className={clsx(styles.bar, barStyle)}
      />
    </div>
  )
}
