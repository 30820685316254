import { useFormik } from 'formik'
import * as yup from 'yup'
import InputMask from 'react-input-mask'
import clsx from 'clsx'
import { useState, useEffect, useRef } from 'react'
import useOnClickOutside from '../../hooks/useOnClickOutside'
import './styles.scss'
import TextInput from '../TextInput'
import moment from 'moment'

const Popup = props => {
  const { nomenclature, open, onClose, onSubmit, cells } = props

  const maxValue = props.maxValue || 12

  const [rangeValue, setRangeValue] = useState(1)

  const ref = useRef()

  useOnClickOutside(ref, onClose)

  useEffect(() => {
    const el = document.querySelector('.range-value')
    const bulletPosition = rangeValue / maxValue
    if (el) {
      el.style.left = bulletPosition * 240 + 'px'
    }
  })

  function expirationDate() {
    if (nomenclature?.expirationDays === null) {
      return null
    }

    let date = new Date()
    date.setDate(date.getDate() + nomenclature?.expirationDays || 0)
    return moment(date.toISOString()).format('DD.MM.YYYY hh:mm')
  }

  const onSumbitHandler = ({ values, quantity }) => {
    if (String(values.price).split(',').length === 2) {
      // если отправить price с заяпятой а не точкой - на бэкенде сохраняется полное число без разделенй Пример: 10,5 => 105
      return onSubmit({ ...values, price: values.price.split(',').join('.'), quantity })
    } else {
      return onSubmit({ ...values, quantity })
    }
  }

  const { values, errors, touched, setFieldValue, handleSubmit } = useFormik({
    onSubmit: values => onSumbitHandler({ values, quantity: rangeValue }),
    initialValues: {
      shelfLife: expirationDate(),
      price: nomenclature ? Number(nomenclature.price) : ''
    },
    validationSchema: yup.object().shape({
      price: yup
        .string()
        .required('Это поле обязательно')
        .test('price test', 'Неверный формат', value => {
          const regExp = /^\d+([.,]\d{1,2})?$/s
          if (regExp.test(value)) {
            return true
          }
          return false
        }),
      shelfLife: yup
        .mixed()
        .when('price', (_, shema) => {
          return nomenclature?.expirationDays !== null
            ? shema.required('Это поле обязательно')
            : shema.notRequired()
        })
        .test('checkDate', 'Неверный формат c', value => {
          if (nomenclature?.expirationDays === null) return true
          const reg =
            // eslint-disable-next-line no-useless-escape
            /(?:(?:0[13578]|1[02])[\/.](?:0[1-9]|[12][0-9]|3[01])[\/.](?:18|19|20)[0-9]{2})|(?:(?:0[469]|11)[\/.](?:0[1-9]|[12][0-9]|30)[\/.](?:18|19|20)[0-9]{2})|(?:(?:02)[\/.](?:0[1-9]|1[0-9]|2[0-8])[\/.](?:18|19|20)[0-9]{2})|(?:(?:02)[\/.]29[\/.](?:(?:(?:18|19|20)(?:04|08|[2468][048]|[13579][26]))|2000))(\s([01]\d|2[0-3]):([0-5]\d):([0-5]\d))/gm
          return reg.test(moment(value, 'DD.MM.YYYY HH:mm').format('MM/DD/YYYY HH:mm:ss'))
        })
        .test('expiration day test', 'Просроченный товар', value => {
          if (moment() < moment(value, 'DD.MM.YYYY HH:mm')) {
            return true
          } else if (nomenclature?.expirationDays === null) {
            return true
          }
          return false
        })
    }),
    enableReinitialize: true
  })

  const handleChangeRange = e => {
    setRangeValue(e.target.value)
  }

  const handleChange = e => {
    const { name, value } = e.target
    setFieldValue(name, value)
  }

  const handleChangePrice = ev => {
    const value = ev.target.value

    if (value === '') {
      setFieldValue('price', value)
    }

    const regExp1 = /^\d+([.,]\d{1,2})?$/s
    const regExp2 = /^\d+([.,])?$/s
    if (regExp1.test(value) || regExp2.test(value)) {
      setFieldValue('price', value)
    }
  }

  return nomenclature ? (
    <form onSubmit={handleSubmit}>
      <div className={clsx('b-popup', open && 'b-popup_open')}>
        <div ref={ref} className="b-popup__container">
          <div className="b-popup__header">
            <p>
              {cells.length > 1
                ? 'Добавление товара в ячейки'
                : 'Добавление товара в ячейку'}
            </p>
            <p>
              {cells.length &&
                cells
                  .map(item => item.number)
                  .sort()
                  .join(', ')}
            </p>
          </div>

          <div className="b-popup__main">
            <div className="b-popup__main-desc">
              <div className="b-popup__main-desc--img">
                <img src={nomenclature.image} alt="" />
              </div>
              <p>{nomenclature.name}</p>
            </div>

            <div className="b-popup__main-inputs">
              {nomenclature.expirationDays !== null && (
                <div className="b-popup__main-inputs--container">
                  <label htmlFor="shelfLife">Срок годности</label>
                  <div className="b-popup__main-inputs--container--date">
                    <InputMask
                      id="shelfLife"
                      name="shelfLife"
                      mask="99.99.9999 99:99"
                      value={values.shelfLife}
                      onChange={handleChange}
                      type="text"
                      tabIndex={1}
                    />
                  </div>
                  {touched.shelfLife && (
                    <span className="b-popup--error">{errors.shelfLife}</span>
                  )}
                </div>
              )}

              <div className="price-container">
                <label htmlFor="price">Цена</label>
                <div className="price">
                  <TextInput
                    name="price"
                    style={{ height: 20 }}
                    value={values.price}
                    onChange={handleChangePrice}
                    tabIndex={2}
                  />
                </div>
                {touched.price && <span className="b-popup--error">{errors.price}</span>}
              </div>
            </div>

            <div className="b-popup__main-range">
              <label htmlFor="range">Количество</label>

              <div className="b-popup__main-range--content">
                <span>1</span>
                <div className="b-popup__main-range--parent">
                  <span className="range-value">{rangeValue}</span>
                  <input
                    name="range"
                    type="range"
                    min="1"
                    value={rangeValue}
                    max={maxValue}
                    step="1"
                    onChange={handleChangeRange}
                  />
                </div>
                <span>{maxValue}</span>
              </div>
            </div>
          </div>

          <div className="b-popup__footer">
            <button type="submit">Добавить</button>
          </div>
        </div>
      </div>
    </form>
  ) : null
}

export default Popup
