export default function deserializeList(list) {
  return list.map(i => ({
    ...deserializeListObject(i),
    selected: false
  }))
}

export function deserializeListObject(obj) {
  if (!obj) return {}

  return {
    id: obj.code || obj.uid || obj.id,
    label: obj.title || obj.name || obj.visibleName
  }
}
