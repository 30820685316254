import SwitchButton from '../../components/SwitchButton'
import clsx from 'clsx'
import styles from './styles.module.scss'

const Role = props => {
  const { parentRole, childRoles, onRoleChange, values, soloSwitcher, soloRole } = props

  const handleSwitchChange = ({ target }) => {
    const { id, checked } = target

    let newPermissions = { ...values.permissions }

    const checkIdUpdate = id.split('update')

    if (checkIdUpdate.length === 2) {
      newPermissions[id] = values.permissions[parentRole.read] ? checked : false
    } else {
      newPermissions[id] = checked
    }

    if (id === parentRole.write) {
      const key = id.split('get')

      if (!checked) {
        return onRoleChange({
          ...values.permissions,
          [id]: false,
          [key[0] + 'update']: false,
          ...childRoles.reduce(
            (p, c) => ({ ...p, [c.write]: false, [c.read]: false }),
            {}
          )
        })
      } else {
        return onRoleChange({
          ...values.permissions,
          [id]: true,
          [key[0] + 'update']: false,
          ...childRoles.reduce((p, c) => ({ ...p, [c.write]: true, [c.read]: false }), {})
        })
      }
    }

    if (id === parentRole.read) {
      const key = id.split('update')
      if (!checked) {
        return onRoleChange({
          ...values.permissions,
          [id]: false,
          ...childRoles.reduce((p, c) => ({ ...p, [c.read]: false }), {})
        })
      } else {
        return onRoleChange({
          ...values.permissions,
          [key[0] + 'get']: true,
          [id]: true,
          ...childRoles.reduce((p, c) => ({ ...p, [c.write]: true, [c.read]: true }), {})
        })
      }
    }

    const updateKey = id.split('update')
    if (updateKey.length === 2) {
      if (checked) {
        newPermissions = {
          ...newPermissions,
          [updateKey[0] + 'get']: checked
        }
      } else {
        const split = id.split('-')
        const keyBase = split[0] + '-' + split[1]
        const parentKey = keyBase + '-update'

        let allUnchecked = true
        for (const p in newPermissions) {
          if (p === id || p === parentKey) continue
          if (p.startsWith(keyBase) && p.endsWith('update') && newPermissions[p]) {
            allUnchecked = false
            break
          }
        }

        if (allUnchecked) {
          newPermissions[parentKey] = false
        }
      }
    }

    const getKey = id.split('get')
    if (getKey.length === 2 && !checked) {
      const correspondingUpdateKey = getKey[0] + 'update'
      newPermissions[correspondingUpdateKey] = checked

      const split = id.split('-')
      const keyBase = split[0] + '-' + split[1]
      const parentKey = keyBase + '-get'

      let allUnchecked = true
      for (const p in newPermissions) {
        if (p === id || p === parentKey) continue
        if (p.startsWith(keyBase) && p.endsWith('get') && newPermissions[p]) {
          allUnchecked = false
          break
        }
      }

      if (allUnchecked) {
        const correspondingParentKey = keyBase + '-update'
        newPermissions[parentKey] = false
        newPermissions[correspondingParentKey] = false
      }
    }

    onRoleChange(newPermissions)
  }

  const handleSoloSwitchChange = ({ target }) => {
    const { id, checked } = target

    let newPermissions = {
      ...values.permissions,
      [id]: checked
    }

    const updateKey = id.split('update')
    if (updateKey.length === 2 && checked) {
      newPermissions = {
        ...newPermissions,
        [updateKey[0] + 'get']: checked
      }
    }

    const getKey = id.split('get')
    if (getKey.length === 2 && !checked) {
      const correspondingUpdateKey = getKey[0] + 'update'
      newPermissions[correspondingUpdateKey] = checked
    }

    onRoleChange(newPermissions)
  }

  return soloSwitcher ? (
    <div className={styles.switchRow}>
      <div className={styles.section}>{soloRole.name}</div>
      <div className={styles.switches}>
        <SwitchButton
          id={soloRole.write}
          onLabel="Просмотр"
          checked={values.permissions[soloRole.write]}
          onChange={handleSoloSwitchChange}
        />

        <SwitchButton
          id={soloRole.read}
          onLabel="Редактирование"
          checked={values.permissions[soloRole.read]}
          onChange={handleSoloSwitchChange}
        />
      </div>
    </div>
  ) : (
    <>
      <div className={styles.switchRow}>
        <div className={styles.section}>{parentRole.name}</div>
        <div className={styles.switches}>
          <SwitchButton
            id={parentRole.write}
            onLabel="Просмотр"
            checked={values.permissions[parentRole.write]}
            onChange={handleSwitchChange}
          />

          <SwitchButton
            id={parentRole.read}
            onLabel="Редактирование"
            checked={values.permissions[parentRole.read]}
            onChange={handleSwitchChange}
          />
        </div>
      </div>

      {values.permissions[parentRole.write] &&
        childRoles.map(k => (
          <div key={k.name} className={clsx(styles.switchRow, styles.tabSwitch)}>
            <div className={styles.section}>{k.name}</div>
            <div className={styles.switches}>
              <SwitchButton
                id={k.write}
                onLabel="Просмотр"
                checked={values.permissions[k.write]}
                onChange={handleSwitchChange}
              />

              <SwitchButton
                id={k.read}
                onLabel="Редактирование"
                checked={values.permissions[k.read]}
                onChange={handleSwitchChange}
              />
            </div>
          </div>
        ))}
    </>
  )
}

export default Role
