import React from 'react'
import styles from './styles.module.scss'
import { useFormik } from 'formik'
import * as yup from 'yup'
import TextInput from '../TextInput'
import Button from '../Button'
import clsx from 'clsx'
import backArrow from '../../icons/arrow-back.svg'
import md5 from 'md5'
import axiosInstance from '../../utils/axios'
import { toast } from 'react-toastify'

export const EditPassModal = ({ onClick, userUid, onClose }) => {
  const onSumbitHandler = async () => {
    const data = {
      userUid,
      oldPass: md5(values.oldPass).toUpperCase(),
      newPass: md5(values.newPass).toUpperCase(),
      newPassConfirm: md5(values.newPassConfirm).toUpperCase()
    }

    await axiosInstance
      .put(`/user/update/pass`, data)
      .then(() => {
        toast.success('Пароль успешно изменен')
        onClose()
          onClick()
      })
      .catch(data => {
        toast.error(data.Info)
      })
  }

  const { values, errors, touched, setFieldValue, handleSubmit } = useFormik({
    onSubmit: values => onSumbitHandler(values),
    initialValues: {
      oldPass: '',
      newPass: '',
      newPassConfirm: ''
    },
    validationSchema: yup.object().shape({
      oldPass: yup.string().required('Поле обязательно'),
      newPass: yup
        .string()
        .required('Поле обязательно')
        .test('same repeat password', 'Пароли не совпадают', function (value) {
          if (value !== this.parent.newPassConfirm) {
            return false
          }
          return true
        }),
      newPassConfirm: yup
        .string()
        .required('Поле обязательно')
        .test('same new password', 'Пароли не совпадают', function (value) {
          if (value !== this.parent.newPass) {
            return false
          }
          return true
        })
    }),
    enableReinitialize: true
  })

  const onChangeHandler = name => event => {
    setFieldValue(name, event.target.value)
  }

  return (
    <form className={clsx(styles.form, styles.pass_form)} onSubmit={handleSubmit}>
      <div class={styles.pass_title}>
        <img
          src={backArrow}
          alt="Кнопка назад"
          onClick={() => {onClose(); onClick()}}
          className={styles.pass_title__icon}
        />
        <h3>Изменение пароля</h3>
      </div>
      <TextInput
        placeholder="Старый пароль"
        label="Старый пароль"
        password
        className={styles.input}
        value={values.oldPass}
        onChange={onChangeHandler('oldPass')}
        errorMessage={touched.oldPass && errors.oldPass}
      />
      <TextInput
        placeholder="Новый пароль"
        label="Новый пароль"
        password
        className={styles.input}
        value={values.newPass}
        errorMessage={touched.newPass && errors.newPass}
        onChange={onChangeHandler('newPass')}
      />
      <TextInput
        placeholder="Повторить пароль"
        label="Повторить пароль"
        password
        className={styles.input}
        value={values.newPassConfirm}
        onChange={onChangeHandler('newPassConfirm')}
        errorMessage={touched.newPassConfirm && errors.newPassConfirm}
      />
      <Button type="submit" className={styles.save_changes_button}>
        Изменить
      </Button>
    </form>
  )
}
