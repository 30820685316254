import { useEffect, useRef } from 'react'
import clsx from 'clsx'
import Fade from '../Fade'
import styles from './styles.module.scss'
import closeThick from '../../icons/close-thick.svg'
import useOnClickOutside from '../../hooks/useOnClickOutside'
import useDisableBodyScroll from '../../hooks/useDisableBodyScroll'

export default function Modal(props) {
  const { isOpen, onClose, disableEsc = false, centered, children, className } = props

  const ref = useRef()

  useOnClickOutside(ref, onClose)
  useDisableBodyScroll(isOpen)

  useEffect(() => {
    const keyPressCallback = event =>
      event.key === 'Escape' && !disableEsc ? onClose() : 0

    document.addEventListener('keydown', keyPressCallback)

    return () => document.removeEventListener('keydown', keyPressCallback)
  }, [onClose, disableEsc])

  return (
    <Fade isShown={isOpen}>
      <div>
        <div className={clsx(styles.overlay, centered && styles.centered)}>
          <div ref={ref} className={clsx(styles.container, className)}>
            <div className={styles.header}>
              <button type="button" onClick={onClose} className={styles.close}>
                <img alt="Закрыть" src={closeThick} />
              </button>
            </div>
            <div className={styles.content}>{children}</div>
          </div>
        </div>
      </div>
    </Fade>
  )
}
