import { Redirect, Route, Switch } from 'react-router-dom'
import Kiosks from './Kiosks'
import Kiosk from './Kiosks/Kiosk'
import Product from './Product'
import PurchaseHistory from './PurchaseHistory'
import Purchase from './PurchaseHistory/Purchase'
import TechEvents from './TechEvents'
// import OnlineShopGoods from './OnlineShop/Goods'
import Roles from './Roles'
import Profiles from './Profiles'
import RechargeHistory from './RechargeHistory'
import NomProducts from './Nomenclature/Products'
import Dealers from './Dealers'
import Categories from './Nomenclature/Categories'
import Groups from './Nomenclature/Groups'
import Cities from './Nomenclature/Cities'
import Districts from './Nomenclature/Cities/Districts'
import Schools from './Nomenclature/Cities/Schools'
import Notifications from './Notification'
import Reports from './Reports'
import Banners from './Promotions/Banner'
import DetailBanner from './Promotions/Banner/DetailBanner'
import DealersNomenclatures from './Dealers/Nomenclatures'
import Changelog from "./Changelog";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import {version} from '../../package.json'

export default function Routes() {

    Sentry.init({
        dsn: "https://9102f280d5c84a4ba79b3f9b544efcc8@o1289654.ingest.sentry.io/6532718",
        integrations: [new BrowserTracing()],
        tracesSampleRate: 1.0,
        release: version
    });

  return (
    <Switch>
      <Route exact path="/">
        <Redirect to="/kiosks" />
      </Route>
      {/* <Redirect /> */}
        <Route exact path="/changelog" component={Changelog} />
        <Route exact path="/kiosks" component={Kiosks} />
      <Route exact path="/kiosks/kiosk/:uid" component={Kiosk} />
      <Route
        exact
        path="/kiosks/kiosk/:kioskUid/product/:productUid"
        component={Product}
      />
      {/* <Route exact path='/goods' component={OnlineShopGoods} /> */}
      <Route exact path="/notifications" component={Notifications} />
      <Route exact path="/promotions/banners" component={Banners} />
      <Route exact path="/promotions/banners/:id" component={DetailBanner} />
      <Route exact path="/purchase-history&pageNum=:pageNum&rowCount=:rowCount" component={PurchaseHistory} />
      <Route exact path="/purchase-history/purchase/:id" component={Purchase} />
      <Route exact path="/tech-events" component={TechEvents} />
      <Route exact path="/users/roles" component={Roles} />
      <Route exact path="/users/profiles" component={Profiles} />
      <Route exact path="/users/recharge-history" component={RechargeHistory} />
      <Route exact path="/nom/goods" component={NomProducts} />
      <Route exact path="/dealers&pageNum=:pageNum&rowCount=:rowCount" component={Dealers} />
      <Route exact path="/nom/categories" component={Categories} />
      <Route exact path="/nom/groups" component={Groups} />
      <Route exact path="/nom/cities" component={Cities} />
      <Route exact path="/nom/city/:id" component={Districts} />
      <Route exact path="/nom/city/:city/district/:district" component={Schools} />
      <Route
        exact
        path="/nom/dealers/:uid/nomenclatures"
        component={DealersNomenclatures}
      />
      <Route exact path="/reports/kiosks" component={Reports} />
      {/* <Route component={NotFoundPage} /> */}
    </Switch>
  )
}
