import { useMemo, useRef, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import clsx from 'clsx'
import Fade from '../Fade'
import arrow from '../../icons/arrow-big.svg'
import phoenix from '../../icons/phoenix.svg'
import mainLogo from '../../icons/main-logo.svg'
import triangle from '../../icons/triangle.svg'
import list from '../../icons/list.svg'
import grid from '../../icons/grid.svg'
import square from '../../icons/square.svg'
import person from '../../icons/person.svg'
import message from '../../icons/message.svg'
import infoCircle from '../../icons/info-circle.svg'
import ConditionalRender from '../ConditionalRender'
import styles from './styles.module.scss'
import {version} from '../../../package.json'

const MENU = [
  {
    id: 'allow-kiosk-get',
    icon: square,
    link: '/kiosks',
    name: 'Киоски'
  },
  {
    id: 'allow-nomenclature-dealer-get',
    icon: person,
    link: '/dealers&pageNum=1&rowCount=10',
    name: 'Дилеры'
  },
  {
    id: 'allow-feed-get',
    icon: grid,
    name: 'Лента событий',
    link: '/purchase-history&pageNum=1&rowCount=10'
  },
  {
    id: 'allow-user-get',
    icon: person,
    name: 'Пользователи',
    items: [
      {
        id: 'allow-user-role-get',
        name: 'Роли',
        link: '/users/roles'
      },
      {
        id: 'allow-user-user-get',
        name: 'Учётные записи',
        link: '/users/profiles'
      }
    ]
  },
  {
    id: 'allow-report-get',
    icon: message,
    name: 'Отчётность',
    link: '/reports/kiosks'
  },
  {
    id: 'allow-nomenclature-get',
    icon: list,
    name: 'Номенклатура',
    items: [
      {
        id: 'allow-nomenclature-goods-get',
        name: 'Товары',
        link: '/nom/goods'
      },
      {
        id: 'allow-nomenclature-category-get',
        name: 'Категории',
        link: '/nom/categories'
      },
      {
        id: 'allow-nomenclature-group-get',
        name: 'Группы',
        link: '/nom/groups'
      },
      {
        id: 'allow-nomenclature-city-get',
        name: 'Учреждения',
        link: '/nom/cities'
      }
    ]
  }
]

export default function SideMenu(props) {
  const location = useLocation()

  const [isOpen, setOpen] = useState(true)

  const handleToggle = () => setOpen(!isOpen)

  return (
    <aside className={clsx(styles.menu, !isOpen && styles.collapsed)}>
      <Fade isShown={isOpen}>
        <img alt="Феникс" src={mainLogo} className={styles.logo} />
      </Fade>
      <Fade isShown={!isOpen}>
        <img alt="Феникс" src={phoenix} className={styles.logo} />
      </Fade>
      <div style={{height: 210, display: "block"}}/>

      <ul className={styles.list}>
        {MENU.map(item => (
          <ConditionalRender key={item.id} authKey={item.id} roles={item.roles}>
            <MenuItem
              name={item.name}
              link={item.link}
              icon={item.icon}
              items={item.items}
              active={location.pathname}
              menuIsOpen={isOpen}
              setMenuOpen={setOpen}
            />
          </ConditionalRender>
        ))}
      </ul>
      <div className={clsx(styles.item, styles.documentation)}>
        <div onClick={handleToggle} className={styles.row}>
          <div className={styles.icon}>
            <img alt="Свернуть меню" className={styles.toggleArrow} src={arrow} />
          </div>
          <span>Свернуть меню</span>
        </div>
      </div>
      <ConditionalRender roles={['dealer']}>
        <div className={clsx(styles.item, styles.documentation)}>
          <div onClick={() => window.open('https://phoenix-market.notion.site/e74457afa2194def9d000d8f05d66314')} className={styles.row}>
            <div className={styles.icon}>
              <img alt="Инструкция" src={infoCircle} />
            </div>
            <span>Инструкция</span>
          </div>
        </div>
      </ConditionalRender>
      <Link className={styles.changelog} to="/changelog">Версия: {version}</Link>
    </aside>
  )
}

const MenuItem = props => {
  const { icon, name, link, items, active, menuIsOpen, setMenuOpen } = props

  const [isOpen, setOpen] = useState(false)

  const container = useRef(null)

  const isActive = useMemo(() => {
    let flag = false
    if (link === active) {
      flag = true
    }
    if (!flag && !link) {
      const menuItem = MENU.find(item => item.name === name)
      if (menuItem?.items?.find(item => item.link === active)) {
        flag = true
      }
    }

    if (flag) {
      setTimeout(() => {
        if (container.current) {
          setOpen(true)
          container.current.style.maxHeight = container.current.scrollHeight + 'px'
        }
      }, 500)
    }

    return flag
  }, [link, name, active])

  const handleOpen = () => {
    const openSideMenu = !menuIsOpen && !!items

    if (container.current) {
      container.current.style.maxHeight =
        !isOpen || openSideMenu ? container.current.scrollHeight + 'px' : null
      setOpen(!isOpen || openSideMenu)
    }
    if (openSideMenu) {
      setMenuOpen(true)
    }
  }

  const content = (
    <li className={clsx(styles.item, isActive && styles.active)}>
      <div onClick={handleOpen} className={styles.row}>
        <div className={styles.icon}>
          <img alt={name} src={icon} />
        </div>
        <span>{name}</span>
        {items && (
          <img
            alt=""
            src={triangle}
            className={clsx(styles.arrow, isOpen && styles.rotated)}
          />
        )}
      </div>
      {items && (
        <div ref={container} className={styles.subMenu}>
          <ul>
            {items.map(item => (
              <ConditionalRender key={item.id} authKey={item.id}>
                <li key={item.id} className={clsx(item.link === active && styles.active)}>
                  <Link to={item.link}>{item.name}</Link>
                </li>
              </ConditionalRender>
            ))}
          </ul>
        </div>
      )}
    </li>
  )

  return items ? content : <Link to={link}>{content}</Link>
}
