import { createContext, useCallback, useContext, useEffect, useState } from 'react'
import axios from '../utils/axios'

const AppContext = createContext({
  search: {
    value: ''
  },
  hideSearch: false,
  profile: {
    user: null,
    logout: () => {
      /* empty */
    },
    login: () => {
      /* empty */
    },
    loading: true
  }
})

export const ProvideAppContext = ({ children }) => {
  const search = useProvideSearch()
  const profile = useProvideProfile()
  const hideSearch = useProvideHideSearch()

  return (
    <AppContext.Provider
      value={{
        search,
        profile,
        hideSearch
      }}
    >
      {children}
    </AppContext.Provider>
  )
}

export const useSearch = () => {
  const { search } = useContext(AppContext)
  return search
}

export const useProfile = () => {
  const { profile } = useContext(AppContext)
  return profile
}

const useProvideSearch = () => {
  const [value, setValue] = useState('')
  return { value, setValue }
}

export const useHideSearch = () => {
  const { hideSearch } = useContext(AppContext)
  return hideSearch
}

const useProvideHideSearch = () => {
  const [isHidden, setHidden] = useState(false)
  return { isHidden, setHidden }
}

const saveToken = token => {
  localStorage.setItem('token', token)
}

const deserializeUser = user => {
  return {
    uid: user.uid,
    name: user.visibleName,
    email: user.email,
    position: user.position,
    role: user.role.code,
    userInfo: {
      family: user?.family,
      name: user?.name,
      middleName: user?.middleName
    },
    visibleName: user.visibleName,
    phone: user.phone,
    userRole: user.role,
    active: user.profileActive,
    authKeys: user.sections.flatMap(s => (s.access === true ? [s.code] : []))
  }
}

const useProvideProfile = () => {
  const [user, setUser] = useState(null)
  const [loading, setLoading] = useState(true)

  const logout = useCallback(async () => {
    localStorage.removeItem('token')
    setUser(null)
  }, [])

  const loadUser = useCallback(async () => {
    const token = localStorage.getItem('token')

    if (token) {
      try {
        const res = await axios.get('/dealer/info', { withCode: true })
        const user = deserializeUser(res.data.user)
        setUser(user)
      } catch (error) {}
    }

    setLoading(false)
  }, [])

  const login = useCallback(async (login, pass, remember) => {
    try {
      const res = await axios.post('/dealer/authorize', {
        login,
        pass,
        remember
      })

      saveToken(res.token)

      const user = deserializeUser(res.user)

      setUser(user)
      await loadUser()
    } catch (error) {
      // console.log('got error', error)
    }
  }, [loadUser])

  useEffect(() => {
    loadUser()
  }, [loadUser])

  return {
    user,
    login,
    logout,
    setUser,
    loading,
    loadUser
  }
}
