/* eslint-disable no-unused-vars */
import { useState, useEffect, useRef } from 'react'
import clsx from 'clsx'
import useOnClickOutside from '../../hooks/useOnClickOutside'
import DropdownContainer from '../DropdownContainer'
import Checkbox from '../Checkbox'
import Button from '../Button'
import filter from '../../icons/filter.svg'
import triangle from '../../icons/triangle.svg'
import styles from './styles.module.scss'

export default function Select(props) {
  const {
    text,
    options,
    value,
    onSave,
    className,
    errorMessage,
    disabled,
    single,
    hidden,
    nothingFoundText,
    ...rest
  } = props

  const [naming, setNaming] = useState(true)
  const [setting, setSetting] = useState(false)
  const [aZ, setAZ] = useState(true)
  const [zA, setZA] = useState(false)
  const [springIdName, setSpringIdName] = useState('')
  const [shelfIdName, setShelfIdName] = useState('Name')
  const [shelfId, setShelfId] = useState('asc')
  const [springId, setSpringId] = useState('')

  const [encOptions, setEncOptions] = useState(
    options.map(option => ({
      ...option,
      selected: value ? value?.includes(option.id) : false
    }))
  )

  useEffect(() => {
    setEncOptions(
      single
        ? options.map(option => ({ ...option, selected: option.id === value }))
        : options.map(option => ({
            ...option,
            selected: value ? value?.includes(option.id) : false
          }))
    )
  }, [options, value, single])

  const [isOpen, setOpen] = useState(false)

  const ref = useRef()

  const handleOpen = () => {
    if (!disabled) {
      setEncOptions(options.map(o => ({ ...o })))
      setOpen(!isOpen)
    }
  }

  const handleClose = () => {
    setOpen(false)
    setEncOptions(options.map(o => ({ ...o })))
  }

  const handleSaveSelection = () => {
    onSave({ shelfIdName, shelfId, springIdName, springId })
    setOpen(false)
  }

  useOnClickOutside(ref, handleClose)

  const handleChangeSort = type => {
    if (type === 'naming') {
      if (setting === true) {
        setSetting(false)
        setNaming(true)
        setZA(false)
        setAZ(true)
        setShelfIdName('Name')
        setSpringIdName('')
        setSpringId('')
        setShelfId('asc')
      } else {
        setNaming(true)
        setZA(false)
        setAZ(true)
        setShelfIdName('Name')
        setShelfId('asc')
        setSpringIdName('')
        setSpringId('')
      }
    } else if (type === 'setting') {
      if (naming === true) {
        setNaming(false)
        setSetting(true)
        setShelfIdName('ShelfId')
        setSpringIdName('SpringID')
        setShelfId('asc')
        setSpringId('asc')
      } else {
        setSetting(true)
        setShelfId('asc')
        setSpringId('asc')
      }
    }
  }

  const handleChangeSortType = type => {
    if (type === 'AZ') {
      if (zA === true) {
        setZA(false)
        setAZ(true)
        setShelfId('asc')
        setShelfIdName('Name')
      } else {
        setAZ(true)
        setShelfId('asc')
        setShelfIdName('Name')
      }
    } else if (type === 'ZA') {
      if (aZ === true) {
        setAZ(false)
        setZA(true)
        setShelfIdName('Name')
        setShelfId('desc')
      } else {
        setZA(true)
        setShelfId('desc')
        setShelfIdName('Name')
      }
    }
  }

  return (
    <div
      ref={ref}
      className={clsx(
        styles.container,
        hidden && styles.hidden,
        disabled && styles.disabled,
        className
      )}
      {...rest}
    >
      <button type="button" onClick={handleOpen} className={styles.button}>
        <img src={filter} alt="" />
      </button>
      <DropdownContainer isShown={isOpen}>
        <div className={styles.listContainer}>
          <div className={styles.headerFilter}>
            <p>Сортировать по</p>
            <button onClick={handleClose}>
              <img src={triangle} alt="Треугольник" />
            </button>
          </div>
          <div className={styles.optionsContainer}>
            <ul className={styles.list}>
              <li className={styles.option}>
                <Checkbox
                  label={'Наименование'}
                  checked={naming}
                  onChange={() => handleChangeSort('naming')}
                />
              </li>
              <li className={styles.option}>
                <Checkbox
                  label={'По расположению'}
                  checked={setting}
                  onChange={() => handleChangeSort('setting')}
                />
              </li>
              {naming === true ? (
                <>
                  <div
                    style={{
                      width: '100%',
                      height: 1,
                      backgroundColor: '#E9E9E9',
                      marginBottom: 15
                    }}
                  />
                  <li className={styles.option}>
                    <Checkbox
                      label={'↓ A - Z'}
                      checked={aZ}
                      onChange={() => handleChangeSortType('AZ')}
                    />
                  </li>
                  <li className={styles.option}>
                    <Checkbox
                      label={'↑ Z - A'}
                      checked={zA}
                      onChange={() => handleChangeSortType('ZA')}
                    />
                  </li>
                </>
              ) : (
                <></>
              )}

              <div style={{ width: '100%', height: 1, backgroundColor: '#E9E9E9' }} />
            </ul>
            <Button className={styles.accept} onClick={handleSaveSelection}>
              Применить
            </Button>
          </div>
        </div>
      </DropdownContainer>
      {errorMessage && <p className={styles.error}>{errorMessage}</p>}
    </div>
  )
}
