import Modal from '../../components/Modal'
import Button from '../../components/Button'
import Checkbox from '../../components/Checkbox'
import TextInput from '../../components/TextInput'

import styles from './styles.module.scss'
import mainLogo from '../../icons/main-logo.svg'

import { useState } from 'react'
import { useFormik } from 'formik'
import { useProfile } from '../../hooks/useContext'
import * as Yup from 'yup'
import axios from '../../utils/axios'
import md5 from 'md5'

export default function Login() {
  const [isForgotModalOpen, setForgotModalOpen] = useState(false)

  const { login } = useProfile()

  const formik = useFormik({
    initialValues: {
      login: '',
      pass: '',
      remember: false
    },
    validationSchema: Yup.object({
      login: Yup.string().required('Введите логин'),
      pass: Yup.string().required('Введите пароль'),
      remember: Yup.boolean()
    }),
    onSubmit: async values => {
      const password = md5(values.pass).toUpperCase()
      await login(values.login, password, values.remember)
    }
  })

  const handleToggleForgotModal = () => {
    setForgotModalOpen(!isForgotModalOpen)
  }

  return (
    <>
      <header className={styles.header}>
        <img alt="Логотип Феникс" src={mainLogo} className={styles.logo} />
      </header>
      <main>
        <h1 className={styles.title}>Вход в личный кабинет</h1>

        <form className={styles.form} onSubmit={formik.handleSubmit}>
          <TextInput
            autoFocus
            className={styles.input}
            placeholder="Введите логин"
            errorMessage={formik.touched.login && formik.errors.login}
            {...formik.getFieldProps('login')}
          />

          <TextInput
            password
            className={styles.input}
            placeholder="Введите пароль"
            errorMessage={formik.touched.pass && formik.errors.pass}
            {...formik.getFieldProps('pass')}
          />

          <div className={styles.remember}>
            <Checkbox
              label="Запомнить меня"
              labelPositionLeft
              {...formik.getFieldProps('remember')}
              checked={formik.values.remember}
            />
          </div>

          <div className={styles.submit}>
            <Button type="submit" className={styles.button} loading={formik.isSubmitting}>
              Войти
            </Button>

            <button
              type="button"
              onClick={handleToggleForgotModal}
              className={styles.forgot}
            >
              Забыли пароль?
            </button>
          </div>
        </form>
      </main>

      <ForgotModal
        isOpen={isForgotModalOpen}
        onClose={handleToggleForgotModal}
        initLogin={formik.values.login}
      />
    </>
  )
}

function ForgotModal(props) {
  const { isOpen, onClose, initLogin } = props

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      login: initLogin
    },
    validationSchema: Yup.object({
      login: Yup.string().required('Введите логин')
    }),
    onSubmit: async values => {
      const payload = { ...values }
      await axios.post('/dealer/forgot', payload, { toast: true })
      onClose()
    }
  })

  const handleClose = () => {
    formik.resetForm()
    onClose()
  }

  return (
    <Modal isOpen={isOpen} onClose={handleClose}>
      <form className={styles.forgotContainer} onSubmit={formik.handleSubmit}>
        <p className={styles.forgotTitle}>Сброс пароля</p>
        <p>
          Введите email адрес или номер телефона, на который зарегистрирован ваш аккаунт.
          На указанный адрес будет отправлен новый пароль.
        </p>

        <TextInput
          type="text"
          required
          autoFocus
          placeholder="Логин"
          errorMessage={formik.errors.login}
          {...formik.getFieldProps('login')}
        />
        <Button type="submit">Продолжить</Button>
      </form>
    </Modal>
  )
}
