import { useState, useEffect, useCallback } from 'react'
import { useParams } from 'react-router'
import clsx from 'clsx'
import KioskSchema, { deepCloneSchema } from '../KioskSchema'
import TextInput from '../TextInput'
import GridLoader from '../Loader'
import Button from '../Button'
import Popup from '../Popup'
import TabCarousel from '../TabCarousel'
import ProductList from '../ProductList'
import { search } from '../Header/styles.module.scss'
import axios from '../../utils/axios'
import deserializeList from '../../utils/deserializeList'
import { useDebounce } from 'use-debounce'
import styles from './styles.module.scss'
// import ConfirmDialog from '../ConfirmDialog'
import ProductsSelectedList from '../ProductsSelectedList'
// import AddButton from '../AddButton'
import { ProductModal } from '../../pages/Nomenclature/Products'
import Modal from '../Modal'

export default function AddGoodsForm(props) {
  const { afterAdding } = props

  const [schema, setSchema] = useState(props.schema)
  const [nomenclatures, setNomenclatures] = useState([])
  const params = useParams()
  const [loading, setLoading] = useState(false)
  const [selectedTab, setSelectedTab] = useState()
  const [categories, setCategories] = useState([])
  const [selectedProduct, setSelectedProduct] = useState()
  const [popupOpen, setPopupOpen] = useState(false)
  const [selectedCells, setSelectedCells] = useState([])
  const [goodsToLoad, setGoodsToLoad] = useState({
    products: [],
    loading: false
  })
  const [goodsToChange, setGoodsToChange] = useState([])
  const [goodsToDelete, setGoodToDelete] = useState([])
  const [searchString, setSearchString] = useState('')
  const [debouncedSearchString] = useDebounce(searchString, 800)
  const [addModalOpen, setAddModalOpen] = useState(false)
  const [haveItem, setHaveItem] = useState(false)

  useEffect(() => {
    setHaveItem(selectedCells[0]?.isEmpty)
  }, [selectedCells])

  useEffect(() => {
    setSchema(props.schema)
  }, [props.schema])

  useEffect(() => {
    ;(async () => {
      try {
        setLoading(true)
        const res = await axios.get(`kiosk/${params.uid}/nomenclatures`, {
          params: {
            'Pagination.RowCount': -1,
            'Pagination.PageNum': 1,
            text: debouncedSearchString,
            categoryId: selectedTab
          }
        })
        setNomenclatures(res.nomenclatures)
      } catch {
      } finally {
        setLoading(false)
      }
    })()
  }, [debouncedSearchString, selectedTab, params.uid])

  const getCategories = useCallback(async () => {
    const categories = await axios.get('/category/list')
    const deserialized = deserializeList(categories)
    deserialized.unshift({ id: '', label: 'Все', selected: true })
    setCategories(deserialized)
  }, [])

  useEffect(() => {
    getCategories()
    setSelectedTab('')
  }, [getCategories])

  const handleSearch = event => {
    setSearchString(event.target.value)
  }

  const handleSelectTab = id => {
    setSelectedTab(id)
  }

  const handleProductClick = item => {
    setSelectedProduct(item)
    setPopupOpen(true)
  }

  const handleChange = () => {
    setHaveItem(!haveItem)
  }

  const handleClosePopup = () => {
    setPopupOpen(false)
  }

  const handleSave = async () => {
    try {
      if (goodsToLoad.products.length) {
        setGoodsToLoad(s => ({ ...s, loading: true }))
        await axios.post(`kiosk/${params.uid}/loading`, { goods: goodsToLoad.products })
      }

      if (goodsToDelete.length) {
        await axios.delete('/kiosk/product', {
          params: {
            remains: goodsToDelete
          }
        })
      }

      if (goodsToChange.length) {
        await axios.put(`kiosk/cell`, {
          cellContents: goodsToChange
        })
      }

      afterAdding()
    } catch {
    } finally {
      setGoodsToLoad(s => ({ ...s, loading: false }))
    }
  }

  const onDeleteProductHandler = async uid => {
    setGoodToDelete([...goodsToDelete, uid])
  }

  const onAmountChangeHandler = data => {
    const newGoodsToChange = goodsToChange
    const index = goodsToChange.findIndex(item => item.SpringUid === data.SpringUid)
    if (index !== -1) {
      newGoodsToChange[index].Quantity = data.Quantity
    } else {
      newGoodsToChange.push(data)
    }

    setGoodsToChange([...newGoodsToChange])
  }

  const handleAddProduct = product => {
    const products = [...goodsToLoad.products]
    const newSchema = deepCloneSchema(schema)
    for (const cell of selectedCells) {
      const idx = products.findIndex(p => p.springUid === cell.uid)
      if (idx !== -1) {
        // Если грузим в ту ячейку, в которой уже что-то есть
        products.splice(idx, 1)
      }

      products.push({
        nomenclatureUid: selectedProduct.uid,
        springUid: cell.uid,
        price: product.price,
        quantity: +product.quantity,
        dateExpiration: product.shelfLife !== null ? product.shelfLife : ''
      })

      outer: for (const shelf of newSchema.shelves) {
        for (const schemaCell of shelf.cells) {
          if (schemaCell.uid === cell.uid) {
            schemaCell.isEmpty = false
            break outer
          }
        }
      }
    }

    setSchema(newSchema)
    handleClosePopup()
    setSelectedCells([])
    setGoodsToLoad(s => ({ ...s, products }))
  }

  function handleClickAddProduct() {
    setAddModalOpen(!addModalOpen)
  }

  // Если был добавлен товар в номенклатуру, то обновляем список товаров
  async function updateAfterAdd() {
    const res = await axios.get(`kiosk/${params.uid}/nomenclatures`, {
      params: {
        'Pagination.RowCount': -1,
        'Pagination.PageNum': 1,
        text: debouncedSearchString,
        categoryId: selectedTab
      }
    })
    setNomenclatures(res.nomenclatures)
  }

  return (
    <div className={styles.container}>
      <Button
        orange
        className={clsx(styles.button, styles.saveToKiosk)}
        loading={goodsToLoad.loading}
        onClick={handleSave}
      >
        Сохранить
      </Button>
      <div className={styles.top}>
        <div className={styles.schemaContainer}>
          <KioskSchema
            schema={schema}
            onSelect={setSelectedCells}
            selectedCells={selectedCells}
          />
        </div>
        <div className={styles.searchContainer}>
          <div className={styles.inputAndButton}>
            <TextInput
              placeholder="Поиск по разделу"
              fullWidth
              className={clsx(search, styles.searchInput)}
              style={{ width: 260 }}
              value={searchString}
              onChange={handleSearch}
            />
            <Button onClick={handleClickAddProduct} style={{ width: 145, height: 45 }}>
              Создать товар
            </Button>
          </div>

          {!selectedCells.length ? (
            <h2>Выберите ячейку</h2>
          ) : (
            <>
              {loading ? (
                <GridLoader loading={loading} className={styles.centerLoading} />
              ) : (
                <>
                  {haveItem ? (
                    <>
                      <TabCarousel
                        tabs={categories}
                        onSelect={handleSelectTab}
                        selected={selectedTab}
                        className={styles.carousel}
                      />
                      <ProductList products={nomenclatures} choose={handleProductClick} />
                    </>
                  ) : (
                    <ProductsSelectedList
                      selectedCells={selectedCells}
                      preparedToDelete={goodsToDelete}
                      clear={handleChange}
                      onDeleteProduct={onDeleteProductHandler}
                      onAmountChange={onAmountChangeHandler}
                      changedAmounts={goodsToChange}
                    />
                  )}
                </>
              )}

              <Popup
                nomenclature={selectedProduct}
                open={popupOpen}
                onClose={handleClosePopup}
                nomenclatures={selectedProduct}
                cells={selectedCells}
                onSubmit={handleAddProduct}
                maxValue={Math.min(...selectedCells.map(c => c.depth))}
              />
            </>
          )}

          <Modal centered isOpen={addModalOpen} onClose={handleClickAddProduct}>
            <ProductModal onCloseEvent={handleClickAddProduct} update={updateAfterAdd} />
          </Modal>
        </div>
      </div>
    </div>
  )
}
