/* eslint-disable no-unused-vars */
import { useState, useMemo, useCallback, useEffect } from 'react'
import PageTopContainer from '../../components/PageTopContainer'
import ImageCard from '../../components/ImageCard'
import IconButton from '../../components/IconButton'
import Button from '../../components/Button'
import Collapse from '../../components/Collapse'
import ConfirmDialog from '../../components/ConfirmDialog'
import Modal from '../../components/Modal'
import CardTable from '../../components/CardTable'
import TextInput from '../../components/TextInput'
import PageLoader from '../../components/PageLoader'
import NothingFound from '../../components/NothingFound'
import edit from '../../icons/edit.svg'
import { useHideSearch } from '../../hooks/useContext'
import formatRuble from '../../utils/formatRuble'
import axios from '../../utils/axios'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import styles from './styles.module.scss'
import GoBackButton from '../../components/GoBackButton'

export default function Product(props) {
  const { setHidden } = useHideSearch()

  const [loading, setLoading] = useState(false)

  const [product, setProduct] = useState()

  const getProduct = useCallback(async () => {
    try {
      setLoading(true)
      const uid = props.match.params.productUid
      const result = await axios.get(`/kiosk/product/${uid}`)
      setProduct(result)
    } catch {
    } finally {
      setLoading(false)
    }
  }, [props.match.params.productUid])

  useEffect(() => {
    setHidden(true)
    getProduct()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getProduct])

  const handleSave = async values => {
    try {
      setLoading(true)
      await axios.put(`kiosk/product/${props.match.params.productUid}`, values)
    } catch {
    } finally {
      setLoading(false)
      handleExitEditMode()
      getProduct()
    }
  }

  const { values, errors, touched, setFieldValue, handleSubmit } = useFormik({
    onSubmit: values => handleSave(values),
    initialValues: {
      name: product ? product.name : '',
      info: product ? product.info : '',
      price: product ? product.price : null
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required('Это поле обязательно'),
      info: Yup.string().required('Это поле обязательно'),
      price: Yup.number()
        .required('Это поле обязательно')
        .typeError('Необходимо ввести число')
    }),
    enableReinitialize: true
  })

  const [saleSwitch, setSaleSwitch] = useState(true)

  const [deletePrompt, setDeletePrompt] = useState({
    open: false,
    name: '',
    loading: false
  })

  const [editProduct, setEditProduct] = useState({
    isEditing: false,
    name: '',
    price: '',
    description: ''
  })

  const handleToggleSale = e => {
    setSaleSwitch(e.target.checked)
  }

  const handleEditProduct = () => {
    setEditProduct({
      isEditing: true,
      name: product.name,
      price: product.price,
      description: product.info
    })
  }

  const handleExitEditMode = () => {
    setEditProduct(s => ({
      ...s,
      isEditing: false
    }))
  }

  const handleOpenDeletePrompt = () => {
    setDeletePrompt({
      open: true,
      name: product.name
    })
  }

  const handleCloseDeletePrompt = () => {
    setDeletePrompt(s => ({
      ...s,
      open: false
    }))
  }

  const deleteProduct = async () => {
    try {
      setDeletePrompt(s => ({ ...s, loading: true }))

      await axios.delete(`/kiosk/product/${props.match.params.productUid}`, {
        toast: true
      })
    } catch {
    } finally {
      setDeletePrompt(s => ({
        ...s,
        open: false,
        loading: false
      }))
    }
  }

  const availableProductsColumns = useMemo(
    () => [
      {
        Header: 'Киоск',
        accessor: 'alias',
        disableSortBy: true
      },
      {
        Header: 'Адрес киоска',
        accessor: 'address',
        disableSortBy: true
      }
    ],
    []
  )

  const handleChange = name => event => {
    setFieldValue(name, event.target.value)
  }

  const handleDeleteProduct = () => {
    setDeletePrompt({
      open: true,
      name: product ? product.name : '',
      loading: false
    })
  }

  return (
    <PageLoader loading={loading}>
      {product ? (
        <>
          <PageTopContainer>
            <div style={{ display: 'flex' }}>
              <GoBackButton />
              <h1>Карточка товара</h1>
            </div>
          </PageTopContainer>
          <div className={styles.container}>
            <div className={styles.image}>
              <ImageCard url={product.image} name={product.name} />
            </div>
            <div className={styles.right}>
              <div className={styles.firstRow}>
                <div className={styles.productInfo}>
                  <p>{product.name}</p>
                  <p>{formatRuble(product.price)}</p>
                </div>
                <div className={styles.icons}>
                  <IconButton icon={edit} onClick={handleEditProduct} />
                </div>
              </div>
              <div className={styles.secondRow}>
                <p className={styles.description}>{product.info}</p>
              </div>

              <Button className={styles.maxContent} onClick={handleDeleteProduct}>
                Снять товар с киоска
              </Button>
              {/* <div className={styles.thirdRow}>
                <div className={styles.switch}>
                  <SwitchButton
                    id='sale-switch'
                    onLabel='в продаже'
                    offLabel='в архиве'
                    checked={saleSwitch}
                    onChange={handleToggleSale}
                  />
                </div>
              </div> */}
            </div>
          </div>
          <Collapse title="Товар доступен:" className={styles.collapse}>
            <CardTable columns={availableProductsColumns} data={product.availability} />
          </Collapse>

          <ConfirmDialog
            isOpen={deletePrompt.open}
            message={`Вы уверены, что хотите убрать товар ${deletePrompt.name}?`}
            onOk={deleteProduct}
            onCancel={handleCloseDeletePrompt}
            loading={deletePrompt.loading}
          />

          <Modal isOpen={editProduct.isEditing} onClose={handleExitEditMode}>
            <div className={styles.modalContainer}>
              <h2>{`Редактирование продукта "${product.name}"`}</h2>
              <form className={styles.inputs} onSubmit={handleSubmit}>
                <TextInput
                  fullWidth
                  // {...formik.getFieldProps('name')}
                  value={values.name}
                  placeholder="Наименование продукта"
                  label="Наименование продукта"
                  errorMessage={touched.name && errors.name}
                  onChange={handleChange('name')}
                  tabIndex={1}
                />
                <TextInput
                  fullWidth
                  // {...formik.getFieldProps('price')}
                  placeholder={`Цена`}
                  label={`Цена`}
                  value={values.price}
                  errorMessage={touched.price && errors.price}
                  onChange={handleChange('price')}
                  tabIndex={2}
                />
                <TextInput
                  textarea
                  fullWidth
                  // {...formik.getFieldProps('info')}
                  value={values.info}
                  placeholder="Описание продукта"
                  label="Описание продукта"
                  onChange={handleChange('info')}
                  errorMessage={touched.info && errors.info}
                  tabIndex={4}
                />
                <div className={styles.modalButtons}>
                  <Button white onClick={handleExitEditMode}>
                    Отменить
                  </Button>
                  <Button type="submit">Сохранить</Button>
                </div>
              </form>
            </div>
          </Modal>
        </>
      ) : (
        <NothingFound />
      )}
    </PageLoader>
  )
}
