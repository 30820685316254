import { useFormik } from 'formik'
import * as yup from 'yup'
import { useMemo, useState, useEffect, useCallback } from 'react'
import Table from '../../../components/Table'
import ConfirmDialog from '../../../components/ConfirmDialog'
import PageTopContainer from '../../../components/PageTopContainer'
import ConditionalRender from '../../../components/ConditionalRender'
import Button from '../../../components/Button'
import AddButton from '../../../components/AddButton'
import IconButton from '../../../components/IconButton'
import TextInput from '../../../components/TextInput'
import edit from '../../../icons/edit.svg'
import cancel from '../../../icons/cancel.svg'
import { useSearch } from '../../../hooks/useContext'
import axios from '../../../utils/axios'
import styles from './styles.module.scss'
import Modal from '../../../components/Modal'

const Groups = () => {
  const { value } = useSearch()

  const [addModalOpen, setAddModalOpen] = useState(false)
  const [editGroup, setEditGroup] = useState(null)
  const [modalEditOpen, setModalEditOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])
  const [pagination, setPagination] = useState({
    rowCount: 20,
    pageNum: 1,
    pageCount: 1
  })
  const [deletePrompt, setDeletePrompt] = useState({
    open: false,
    name: '',
    id: '',
    loading: false
  })

  const getData = useCallback(async () => {
    try {
      setLoading(true)
      const res = await axios.get('/groups', {
        params: {
          text: value,
          'Pagination.RowCount': pagination.rowCount,
          'Pagination.PageNum': pagination.pageNum
        }
      })
      setData(res.groups)
      setPagination({ ...pagination, pageCount: res.pagination.pageCount })
    } catch {
    } finally {
      setLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, pagination.rowCount, pagination.pageNum])

  useEffect(() => {
    getData()
  }, [getData])

  const columns = useMemo(
    () => [
      {
        Header: '',
        accessor: 'name',
        disableSortBy: true
      },
      {
        Header: '',
        accessor: 'uid',
        disableSortBy: true,
        Cell: ({ value, row }) => (
          <ConditionalRender authKey="allow-nomenclature-group-update">
            <div className={styles.buttons}>
              <IconButton icon={edit} onClick={handleClickEditIcon(row.original)} />
              <IconButton icon={cancel} onClick={handleOpenDeletePrompt(row.original)} />
            </div>
          </ConditionalRender>
        )
      }
    ],
    []
  )

  const handleOpenDeletePrompt = row => () => {
    setDeletePrompt({
      open: true,
      name: row.name,
      id: row.uid
    })
  }

  const handleDeleteProduct = async () => {
    try {
      setDeletePrompt(s => ({ ...s, loading: true }))
      await axios.delete(`groups/${deletePrompt.id}`)
    } catch {
    } finally {
      setDeletePrompt(s => ({
        ...s,
        open: false,
        loading: false
      }))
      getData()
    }
  }

  const handleCloseDeletePrompt = () => {
    setDeletePrompt(s => ({
      ...s,
      open: false
    }))
  }

  const handlePaginationChange = p => {
    setPagination({
      ...pagination,
      ...p
    })
  }

  const onAddModalClose = () => {
    setAddModalOpen(false)
    getData()
  }

  const handleClickAddProduct = () => {
    setAddModalOpen(!addModalOpen)
  }

  const handleCloseEditModal = () => {
    setModalEditOpen(false)
    onAddModalClose()
  }

  const handleClickEditIcon = row => () => {
    setEditGroup(row)
    setModalEditOpen(true)
  }

  return (
    <div>
      <PageTopContainer>
        <div className={styles.firstRow}>
          <h1>Группы</h1>
          <ConditionalRender authKey="allow-nomenclature-group-update">
            <AddButton
              label="Добавить группу"
              isOpen={addModalOpen}
              onClick={handleClickAddProduct}
              onClose={handleClickAddProduct}
            >
              <GroupModal onCloseEvent={onAddModalClose} />
            </AddButton>
          </ConditionalRender>
        </div>
      </PageTopContainer>
      <Table
        loading={loading}
        data={data}
        columns={columns}
        pagination={pagination}
        onPaginationChange={handlePaginationChange}
        className={styles.table}
      />
      <Modal centered isOpen={modalEditOpen} onClose={handleCloseEditModal}>
        <GroupModal onCloseEvent={handleCloseEditModal} group={editGroup} />
      </Modal>

      <ConfirmDialog
        isOpen={deletePrompt.open}
        message={`Вы уверены, что хотите удалить группу ${deletePrompt.name}?`}
        onOk={handleDeleteProduct}
        onCancel={handleCloseDeletePrompt}
        loading={deletePrompt.loading}
      />
    </div>
  )
}

export default Groups

const GroupModal = props => {
  const { group, onCloseEvent } = props

  const handleSave = async values => {
    try {
      if (group) {
        let data = values
        data.active = true
        await axios.put(`groups/${group.uid}`, data)
      } else {
        let newGroup = values
        newGroup.img = ''
        newGroup.active = true
        await axios.post('groups', newGroup)
      }
    } catch {
    } finally {
      onCloseEvent()
    }
  }

  const { values, errors, touched, setFieldValue, handleSubmit } = useFormik({
    onSubmit: values => handleSave(values),
    initialValues: {
      name: group ? group.name : ''
    },
    validationSchema: yup.object().shape({
      name: yup.string().required('Это поле обязательно')
    }),
    enableReinitialize: true
  })

  const handleChange = name => event => {
    setFieldValue(name, event.target.value)
  }

  return (
    <div>
      <h2>{group ? 'Редактировать группу' : 'Добавить группу'}</h2>
      <form onSubmit={handleSubmit}>
        <div className={styles.textInputs}>
          <TextInput
            label="Название"
            placeholder="Название"
            value={values.name}
            errorMessage={touched.name && errors.name}
            onChange={handleChange('name')}
            tabIndex={1}
          />
        </div>

        <div className={styles.spacer1}></div>

        <div className={styles.modalButtons}>
          <Button primary type="submit">
            {group ? 'Изменить' : 'Добавить'}
          </Button>
          {group && (
            <Button white onClick={onCloseEvent}>
              Отменить
            </Button>
          )}
        </div>
      </form>
    </div>
  )
}
