import { useFormik } from 'formik'
import * as yup from 'yup'
import Button from '../../../../components/Button'
import TextInput from '../../../../components/TextInput'
import axios from '../../../../utils/axios'
import styles from './styles.module.scss'

const CitiesModal = props => {
  const { district, onCloseEvent, cityId } = props

  const handleSave = async values => {
    try {
      const data = values
      data.cityUid = cityId
      if (district) {
        await axios.put(`nomenclature/district/${district.uid}`, values)
      } else {
        await axios.post('nomenclature/district', values)
      }
    } catch {
    } finally {
      onCloseEvent()
    }
  }

  const { values, errors, touched, setFieldValue, handleSubmit } = useFormik({
    onSubmit: values => handleSave(values),
    initialValues: {
      name: district ? district.name : ''
    },
    validationSchema: yup.object().shape({
      name: yup.string().required('Это поле обязательно')
    }),
    enableReinitialize: true
  })

  const handleChange = name => event => {
    setFieldValue(name, event.target.value)
  }

  return (
    <div>
      <h2>{district ? 'Редактировать район' : 'Добавить район'}</h2>
      <form onSubmit={handleSubmit}>
        <div className={styles.textInputs}>
          <TextInput
            label="Название"
            placeholder="Название"
            value={values.name}
            errorMessage={touched.name && errors.name}
            onChange={handleChange('name')}
            tabIndex={1}
          />
        </div>

        <div className={styles.spacer1}></div>

        <div className={styles.modalButtons}>
          <Button primary type="submit">
            {district ? 'Изменить' : 'Добавить'}
          </Button>
          {district && (
            <Button white onClick={() => onCloseEvent()}>
              Отменить
            </Button>
          )}
        </div>
      </form>
    </div>
  )
}

export default CitiesModal
