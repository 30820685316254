import { useMemo, useState, useCallback, useEffect, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import clsx from 'clsx'
import PageTopContainer from '../../../components/PageTopContainer'
import Table from '../../../components/Table'
import IconButton from '../../../components/IconButton'
import ConfirmDialog from '../../../components/ConfirmDialog'
import Button from '../../../components/Button'
import ImageCard from '../../../components/ImageCard'
import LastSaleCard from '../../../components/InfoCard/LastSaleCard'
import UnitCountCard from '../../../components/InfoCard/UnitCountCard'
import Modal from '../../../components/Modal'
import KioskForm from '../../../components/KioskForm'
import AddGoodsForm from '../../../components/AddGoodsForm'
import Fade from '../../../components/Fade'
import Indicator from '../../../components/Indicator'
import PageLoader from '../../../components/PageLoader'
import ConditionalRender from '../../../components/ConditionalRender'
import NothingFound from '../../../components/NothingFound'
import location from '../../../icons/location.svg'
import axios from '../../../utils/axios'
import deserializeList, { deserializeListObject } from '../../../utils/deserializeList'
import formatRuble from '../../../utils/formatRuble'
import formatDate from '../../../utils/formatDate'
import formatNumberInUnits from '../../../utils/formatNumberInUnits'
import { useSearch, useHideSearch } from '../../../hooks/useContext'
// import edit from '../../../icons/edit.svg'
import cancel from '../../../icons/cancel.svg'
import isEqual from 'lodash.isequal'
import XLSX from 'xlsx'
import styles from './styles.module.scss'
import SelectSingle from '../../../components/SelectSingle'
import GoBackButton from '../../../components/GoBackButton'
import FilterInKiosk from '../../../components/FilterInKiosk'
import more from '../../../icons/more.svg'
import DropdownContainer from '../../../components/DropdownContainer'
import useOnClickOutside from '../../../hooks/useOnClickOutside'
import moment from 'moment'
import { isDate } from '../../../utils/isDate'
import TextInput from '../../../components/TextInput'

export default function Kiosk(props) {
  // const profile = useProfile()
  const history = useHistory()

  const { isHidden, setHidden } = useHideSearch()

  const [kiosk, setKiosk] = useState()
  const [saveLoading, setSaveLoading] = useState(false)

  const getKiosk = useCallback(async () => {
    const uid = props.match.params.uid
    const result = await axios.get(`kiosk/${uid}`)
    setKiosk(result ? result.kiosk : null)
  }, [props.match.params.uid])

  useEffect(() => {
    getKiosk()
  }, [getKiosk])

  const initFormValues = useMemo(() => {
    if (kiosk && kiosk.state.name !== 'Договор расторгнут') {
      return {
        form: {
          number: kiosk.number,
          alias: kiosk.alias,
          state: deserializeListObject(kiosk.state),
          city: deserializeListObject(kiosk.city),

          // Для редактирования дилера киоска добавлен отдельный функционал
          // dealer: deserializeListObject(kiosk.dealer),
          school: deserializeListObject(kiosk.school),
          buyCapacity: kiosk.buyCapacity,
          pinRequired: kiosk.pinRequired
        },
        schema: {
          shelves: kiosk.shelves
        }
      }
    }
  }, [kiosk])

  const { value } = useSearch()

  const [tableState, setTableState] = useState({
    loading: false,
    pagination: {
      pageCount: 1,
      pageNum: 1,
      rowCount: 10
    },
    status: undefined,
    lastSale: null,
    goods: []
  })

  const [tableStateExcel, setTableStateExcel] = useState({
    loading: false,
    pagination: {
      pageCount: 1,
      pageNum: 1,
      rowCount: -1
    },
    status: undefined,
    lastSale: null,
    goods: []
  })

  const [deleteModal, setDeleteModal] = useState({
    isOpen: false,
    loading: false,
    uid: '',
    name: ''
  })
  const [isFakeModalOpen, setFakeModalOpen] = useState({
    isOpen: false,
    loading: false,
    kioskUid: '',
    purchaseUid: ''
  })


  const [deleteKioskModal, setDeleteKioskModal] = useState({
    isOpen: false,
    isLoading: false
  })

  const [sortingType, setSortingType] = useState({
    shelfIdName: 'Name',
    shelfId: 'asc',
    springIdName: '',
    springId: ''
  })

  const [isEditModalOpen, setEditModalOpen] = useState(false)

  const [changeDealerModal, setChangeDealerModal] = useState({
    isOpen: false,
    isLoading: false,
    dealer: null
  })

  const getGoods = useCallback(async () => {
    setTableState(s => ({ ...s, loading: true }))

    const uid = props.match.params.uid
    let data, pagination
    let dataExcel, paginationExcel

    try {
      const res = await axios.post(`/kiosk/${uid}/goods`, {
        Pagination: {
          RowCount: tableState?.pagination?.rowCount,
          PageNum: tableState?.pagination?.pageNum,
          PageCount: 1
        },
        Filtering: [
          {
            FieldName: 'Name',
            ValueString: value
          }
        ],
        Sorting: [
          {
            FieldName: sortingType?.shelfIdName,
            Type: sortingType?.shelfId
          },
          {
            FieldName: sortingType?.springIdName,
            Type: sortingType?.springId
          }
        ]
      })

      const resExcel = await axios.post(`/kiosk/${uid}/goods`, {
        Pagination: {
          RowCount: -1,
          PageNum: 1,
          PageCount: 1
        },
        Filtering: [
          {
            FieldName: 'Name',
            ValueString: value
          }
        ],
        Sorting: [
          {
            FieldName: sortingType?.shelfIdName,
            Type: sortingType?.shelfId
          },
          {
            FieldName: sortingType?.springIdName,
            Type: sortingType?.springId
          }
        ]
      })

      const productsAndStatus = res.goods.map(item => {
        let colorIndicator
        let date = item.dateExpiration
        if (date !== null) {
          if (isDate(date)) {
            colorIndicator = '#B2EEC2'
            if (
              moment(new Date()).format('YYYY.MM.DD') ===
              moment(date).format('YYYY.MM.DD')
            ) {
              colorIndicator = '#FFBD7C'
            }
          } else {
            colorIndicator = '#E17D7D'
          }
        } else {
          colorIndicator = '#B2EEC2'
        }
        return {
          ...item,
          colorIndicator
        }
      })

      dataExcel = resExcel.goods
      data = productsAndStatus
      pagination = res.pagination
      paginationExcel = resExcel.pagination
    } finally {
      setTableState(s => ({ ...s, goods: data ?? s.goods, pagination, loading: false }))
      setTableStateExcel(s => ({
        ...s,
        goods: dataExcel ?? s.goods,
        paginationExcel,
        loading: false
      }))
    }
  }, [
    value,
    sortingType,
    props.match.params.uid,
    tableState?.pagination?.rowCount,
    tableState?.pagination?.pageNum
  ])

  useEffect(() => {
    getGoods()
  }, [getGoods])

  const [statuses, setStatuses] = useState([])

  const getStatuses = useCallback(async () => {
    const res = await axios.get('/kiosk/states')

    const statuses = deserializeList(res.states)

    setStatuses(statuses)
  }, [])

  useEffect(() => {
    getStatuses()
  }, [getStatuses])

  const [isAddingGoodsMode, setAddingGoodsMode] = useState(false)

  const handleDeletePieceOfGoods = (uid, name) => () => {
    setDeleteModal(s => ({
      ...s,
      uid,
      name,
      isOpen: true
    }))
  }

  const columns = useMemo(
    () => [
      {
        Header: 'Наименование',
        accessor: 'name',
        disableSortBy: true,
        Cell: ({ value, row }) => (
          <span className={styles.imageCell}>
            <ImageCard
              small
              url={row.original.image}
              name={value}
              className={styles.image}
            />
            {value}
          </span>
        )
      },
      {
        Header: 'Полка',
        accessor: 'shelfId',
        disableSortBy: true,
        Cell: ({ value }) => `№ ${value}`
      },
      {
        Header: 'Пружина',
        accessor: 'springId',
        disableSortBy: true,
        Cell: ({ value }) => `№ ${value}`
      },
      {
        Header: 'Цена',
        accessor: 'price',
        disableSortBy: true,
        Cell: ({ value }) => `${formatRuble(value)}`
      },
      {
        Header: 'Ожидают выдачи',
        accessor: 'soldQuantity',
        disableSortBy: true,
        Cell: ({ value }) => formatNumberInUnits(value)
      },
      {
        Header: 'Фактическое количество',
        accessor: 'quantity',
        disableSortBy: true,
        Cell: ({ value, row }) => formatNumberInUnits(+value + +row.original.soldQuantity)
      },
      {
        Header: 'Срок годности',
        accessor: 'dateExpiration',
        disableSortBy: true,
        Cell: ({ value, row }) =>
          value === null ? 'Не ограничен' : moment(value).format('DD.MM.YYYY HH:MM')
      },
      // {
      //   Header: '',
      //   accessor: 'edit',
      //   disableSortBy: true,
      //   // width: 20,
      //   Cell: ({ value, row }) => (
      //     <IconButton
      //       icon={edit}
      //       className={styles.iconCell}
      //       onClick={handleRowClick(row.original.goodUid)}
      //     />
      //   )
      // },
      {
        Header: '',
        accessor: 'remove',
        disableSortBy: true,
        // width: 20,
        Cell: ({ value, row }) => (
          <IconButton
            icon={cancel}
            className={styles.iconCell}
            onClick={handleDeletePieceOfGoods(row.original.uid, row.original.name)}
          />
        )
      },
      {
        Header: '',
        accessor: 'colorIndicator',
        disableSortBy: true
      }
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  const handleDownloadTableData = () => {
    const ws = XLSX.utils.table_to_sheet(document.getElementById('table-goods-excel'))

    const cols = [{ wch: 25 }, { wch: 15 }, { wch: 15 }, { wch: 15 }, { wch: 15 }]
    ws['!cols'] = cols

    const wb = XLSX.utils.book_new()

    XLSX.utils.book_append_sheet(wb, ws, 'Товары')
    XLSX.writeFile(
      wb,
      `Товары киоска ${kiosk.alias} ${new Date().toLocaleDateString('ru-RU')}.xlsx`
    )
  }

  const handleAddingGoodsMode = () => {
    setHidden(!isHidden)
    setAddingGoodsMode(!isAddingGoodsMode)
    getGoods()
    getKiosk()
  }

  const handlePaginationChange = pagination => {
    let { pageNum, rowCount } = pagination

    setTableState(s => ({
      ...s,
      pagination: {
        ...s.pagination,
        pageNum: pageNum ?? 1,
        rowCount: rowCount ?? s.pagination.rowCount
      }
    }))
  }

  const handleConfirmDeletion = async () => {
    setDeleteModal(s => ({ ...s, loading: true }))
    await axios.delete(`/kiosk/product`, {
      toast: true,
      params: { remains: [deleteModal.uid] }
    })
    await getGoods()
    await getKiosk()
    setDeleteModal(s => ({ ...s, loading: false, isOpen: false }))
  }

  const handleCancelDeletion = () => {
    setDeleteModal(s => ({ ...s, isOpen: false }))
  }

  const handleOpenEditModal = () => {
    setEditModalOpen(true)
  }

  const handleCloseEditModal = () => {
    setEditModalOpen(false)
  }

  const handleOpenFakeModal = () => {
    const uid = props.match.params.uid
    setFakeModalOpen({ isOpen: true, kioskUid: uid })
  }

  const handleFailureFakeModal = () => {
    axios.post('order/kiosk/imitation', {
      model: {
        orderUid: isFakeModalOpen.purchaseUid,
        isSuccess: false
      }
    })
    setFakeModalOpen({ isOpen: false })
  }

  const handleSubmitFakeModal = () => {
    axios.post('order/kiosk/imitation', {
      model: {
        orderUid: isFakeModalOpen.purchaseUid,
        isSuccess: true
      }
    })
    setFakeModalOpen({ isOpen: false })
  }

  const handleCloseFakeModal = () => {
    setFakeModalOpen({ isOpen: false })
  }

  const handleSubmitEditKiosk = async (values, schema) => {
    try {
      setSaveLoading(true)
      await Promise.all([
        (async () => {
          const payload = {
            number: +values.number,
            alias: values.alias,
            state: values.state.id,
            schoolUid: values.school.id,
            buyCapacity: values.buyCapacity,
            pinRequired: values.pinRequired
          }
          await axios.put(`/kiosk/${kiosk.uid}`, payload, { toast: true })
        })(),
        (async () => {
          if (!isEqual(schema, initFormValues.schema)) {
            await axios.put(`/kiosk/scheme/${kiosk.uid}`, schema, { toast: true })
          }
        })()
      ])

      getKiosk()
      getGoods()
      setEditModalOpen(false)
    } catch {
      //empty
    } finally {
      setSaveLoading(false)
    }
  }

  // const handleRowClick = id => () => {
  //   history.push(`/kiosks/kiosk/${props.match.params.uid}/product/${id}`)
  // }

  const handleGoToPurchaseHistory = () => {
    history.push('/purchase-history&pageNum=1&rowCount=10', {
      selectedCities: [kiosk.city.uid],
      selectedSchools: [kiosk.school.uid],
      selectedKiosks: [kiosk.uid]
    })
  }

  const handleOpenDeleteKioskModal = () => {
    setDeleteKioskModal({ isOpen: true, isLoading: false })
  }

  const handleCloseDeleteKioskModal = () => {
    setDeleteKioskModal({ ...deleteKioskModal, isOpen: false })
  }

  const handleDeleteKiosk = async () => {
    setDeleteKioskModal({ ...deleteKioskModal, isLoading: true })

    try {
      await axios.delete(`/kiosk/${kiosk.uid}`, { toast: true })
      await getKiosk()
    } catch (error) {
      console.log('debug error', error)
    } finally {
      setDeleteKioskModal({ isOpen: false, isLoading: false })
    }
  }

  // The functionality was cut on 07/13/2022.
  // const [dealers, setDealers] = useState()
  //
  // const getDealers = async () => {
  //   try {
  //     const res = await axios.get('/kiosk/dealers')
  //     if (res?.dealers) {
  //       const dealers = res.dealers.flatMap(d =>
  //         d.uid !== kiosk.dealer.uid ? [deserializeListObject(d)] : []
  //       )
  //       setDealers(dealers)
  //     }
  //   } catch {}
  // }
  //
  //
  // const handleOpenChangeDealerModal = async () => {
  //   if (!dealers) {
  //     await getDealers()
  //   }
  //
  //   setChangeDealerModal(s => ({ ...s, isOpen: true }))
  // }
  //
  // const handleCloseChangeDealerModal = async () => {
  //   setChangeDealerModal(s => ({ ...s, isLoading: false, isOpen: false, dealer: null }))
  // }

  const handleChangeDealerSelect = option => {
    setChangeDealerModal(s => ({ ...s, dealer: option.id }))
  }

  const handleChangeDealer = async () => {
    setChangeDealerModal(s => ({ ...s, isLoading: true }))

    try {
      const res = await axios.put(
        `/kiosk/${kiosk.uid}/dealer`,
        {
          dealerUid: changeDealerModal.dealer
        },
        { toast: true }
      )

      if (res?.kiosk) {
        setKiosk(res.kiosk)
      }

      setChangeDealerModal(s => ({ ...s, isOpen: false }))
    } catch {
    } finally {
      setChangeDealerModal(s => ({ ...s, isLoading: false }))
    }
  }

  const handleClickBackButton = () => {
    if (isAddingGoodsMode) {
      setAddingGoodsMode(!isAddingGoodsMode)
      setHidden(!isHidden)
    } else {
      history.goBack()
    }
  }

  const [moreActive, setMoreActive] = useState(false)

  // Closing Dropdown menu a outside visual zone
  const ref = useRef()
  useOnClickOutside(ref, () => setMoreActive(false))

  return (
    <PageLoader loading={kiosk === undefined}>
      {kiosk ? (
        <>
          <PageTopContainer>
            <div className={styles.firstRow}>
              <div className={styles.header}>
                <GoBackButton onClick={handleClickBackButton} />
                <h1 className={styles.kioskName}>
                  <Indicator className={styles.indicator} />
                  {kiosk?.alias}
                </h1>
                <p className={styles.address}>
                  <span>
                    <img src={location} alt="" />
                  </span>
                  {kiosk?.school?.address}
                </p>
              </div>
              <div className={styles.inputs}>
                {!isAddingGoodsMode ? (
                  <>
                    <Button
                      white
                      className={styles.button}
                      onClick={handleGoToPurchaseHistory}
                    >
                      История покупок
                    </Button>
                    {kiosk.state.name !== 'Заблокирован' &&
                      kiosk.state.name !== 'Договор расторгнут' && (
                        <ConditionalRender roles={['admin', 'dealer', 'dealerEmployee']}>
                          <Button
                            white
                            className={styles.button}
                            onClick={handleAddingGoodsMode}
                          >
                            Добавить товар
                          </Button>
                        </ConditionalRender>
                      )}
                    {kiosk.state.name !== 'Договор расторгнут' && (
                      <div ref={ref}>
                        <IconButton
                          style={{ marginRight: 0 }}
                          className={styles.moreButton}
                          onClick={() => setMoreActive(!moreActive)}
                          icon={more}
                        />
                        <DropdownContainer isShown={moreActive}>
                          <div className={styles.moreContainer}>
                            <Button
                              white
                              className={clsx(styles.button, styles.moreButtonChild)}
                              onClick={handleOpenEditModal}
                            >
                              Редактировать киоск
                            </Button>
                            {((process.env.REACT_APP_BASE_URL === 'https://market.dev.fcards.ru/api')
                              || (process.env.REACT_APP_BASE_URL === 'https://market.stg.fcards.ru/api'))
                              
                              && (
                              <Button
                                white
                                className={clsx(styles.button, styles.moreButtonChild)}
                                onClick={handleOpenFakeModal}
                              >
                                Имитация работы
                              </Button>
                            )}
                            <ConditionalRender roles={'admin'}>
                              {/* The functionality was cut on 07/13/2022. */}
                              {/*<Button*/}
                              {/*  white*/}
                              {/*  className={clsx(styles.button, styles.moreButtonChild)}*/}
                              {/*  onClick={handleOpenChangeDealerModal}*/}
                              {/*>*/}
                              {/*  Передать киоск другому дилеру*/}
                              {/*</Button>*/}
                              <Button
                                red
                                className={clsx(styles.button, styles.moreButtonChild)}
                                onClick={handleOpenDeleteKioskModal}
                              >
                                Удалить киоск
                              </Button>
                            </ConditionalRender>
                          </div>
                        </DropdownContainer>
                      </div>
                    )}
                  </>
                ) : (
                  <>
                    <Button
                      white
                      className={clsx(styles.button, styles.backToKiosk)}
                      onClick={handleAddingGoodsMode}
                    >
                      Завершить без сохранения
                    </Button>
                  </>
                )}
              </div>
            </div>
            {!isAddingGoodsMode && (
              <div className={styles.secondRowContainer}>
                <FilterInKiosk
                  options={statuses}
                  single
                  onSave={v => setSortingType(v)}
                />
                <div className={styles.secondRow}>
                  <LastSaleCard text={formatDate(kiosk?.lastSale)} />

                  <UnitCountCard
                    capacity={kiosk?.capacity}
                    inStock={kiosk?.remainsKiosk}
                    awaitingStock={kiosk?.awaitingIssue}
                  />
                </div>
              </div>
            )}
          </PageTopContainer>

          {!isAddingGoodsMode && (
            <>
              <Table
                id="table-goods"
                paginationOnTheTop
                loading={tableState.loading}
                data={tableState.goods}
                columns={columns}
                onExport={handleDownloadTableData}
                className={styles.table}
                pagination={tableState.pagination}
                onPaginationChange={handlePaginationChange}
                // onClick={handleRowClick}
              />
              <Table
                id="table-goods-excel"
                style={{ display: 'none' }}
                loading={tableStateExcel.loading}
                data={tableStateExcel.goods}
                columns={columns}
                className={styles.table}
                onPaginationChange={handlePaginationChange}
              />
            </>
          )}

          {initFormValues?.schema && (
            <Fade isShown={isAddingGoodsMode}>
              <AddGoodsForm
                schema={initFormValues.schema}
                afterDelete={() => getKiosk()}
                afterAdding={handleAddingGoodsMode}
              />
            </Fade>
          )}

          <ConfirmDialog
            isOpen={deleteModal.isOpen}
            loading={deleteModal.loading}
            message={`Вы уверены, что хотите удалить "${deleteModal.name}" с киоска?`}
            onOk={handleConfirmDeletion}
            onCancel={handleCancelDeletion}
          />

          <Modal centered isOpen={isFakeModalOpen.isOpen} onClose={handleCloseFakeModal}>
            <TextInput
              value={isFakeModalOpen.purchaseUid}
              onChange={e =>
                setFakeModalOpen({ ...isFakeModalOpen, purchaseUid: e.target.value })
              }
              label={'Идентификатор заказа'}
            />
            <div style={{ marginTop: 20, display: 'flex' }}>
              <Button
                className={styles.deleteKioskButton}
                onClick={handleFailureFakeModal}
              >
                Ошибка
              </Button>
              <Button
                className={styles.deleteKioskButton}
                onClick={handleSubmitFakeModal}
                loading={deleteKioskModal.isLoading}
              >
                Успешно
              </Button>
            </div>
          </Modal>

          <Modal
              centered
              isOpen={isFakeModalOpen.isOpen}
              onClose={handleCloseFakeModal}
          >
            <TextInput value={isFakeModalOpen.purchaseUid} onChange={(e) => setFakeModalOpen({...isFakeModalOpen, purchaseUid: e.target.value})} label={"Идентификатор заказа"}/>
            <div style={{marginTop: 20, display: "flex"}}>
              <Button
                  className={styles.deleteKioskButton}
                  onClick={handleFailureFakeModal}
              >
                Ошибка
              </Button>
              <Button
                  className={styles.deleteKioskButton}
                  onClick={handleSubmitFakeModal}
                  loading={deleteKioskModal.isLoading}
              >
                Успешно
              </Button>
            </div>
          </Modal>

          <Modal
            centered
            isOpen={isEditModalOpen}
            onClose={handleCloseEditModal}
            className={styles.modal}
          >
            <KioskForm
              title="Редактирование киоска"
              onSubmit={handleSubmitEditKiosk}
              initialValues={initFormValues}
              saveLoading={saveLoading}
              editingMode
              kioskLive={true}
            />
          </Modal>

          <Modal
            centered
            isOpen={deleteKioskModal.isOpen}
            onClose={handleCloseDeleteKioskModal}
          >
            <p className={styles.deleteKioskModalBody}>
              Вы уверены, что хотите удалить киоск?
            </p>
            <div className={styles.deleteKioskButtonsContainer}>
              <Button
                className={styles.deleteKioskButton}
                onClick={handleCloseDeleteKioskModal}
              >
                Отмена
              </Button>
              <Button
                className={styles.deleteKioskButton}
                onClick={handleDeleteKiosk}
                loading={deleteKioskModal.isLoading}
              >
                Да
              </Button>
            </div>
          </Modal>

          {/* The functionality was cut on 07/13/2022. */}
          {/*<Modal*/}
          {/*  centered*/}
          {/*  isOpen={changeDealerModal.isOpen}*/}
          {/*  onClose={handleCloseChangeDealerModal}*/}
          {/*  className={styles.changeDealerModal}*/}
          {/*>*/}
          {/*  <SelectSingle*/}
          {/*    options={dealers}*/}
          {/*    className={styles.dealerSelect}*/}
          {/*    visibleLabel="Выберите нового дилера"*/}
          {/*    text="Новый дилер"*/}
          {/*    value={changeDealerModal.dealer}*/}
          {/*    onChange={handleChangeDealerSelect}*/}
          {/*  />*/}
          {/*  <div className={styles.changeDealerButtons}>*/}
          {/*    <Button onClick={handleCloseChangeDealerModal}>Отмена</Button>*/}
          {/*    <Button loading={changeDealerModal.isLoading} onClick={handleChangeDealer}>*/}
          {/*      Подтвердить*/}
          {/*    </Button>*/}
          {/*  </div>*/}
          {/*</Modal>*/}
        </>
      ) : (
        <NothingFound />
      )}
    </PageLoader>
  )
}
