import { useMemo, useState, useEffect, useCallback } from 'react'
import Table from '../../../components/Table'
import ConfirmDialog from '../../../components/ConfirmDialog'
import PageTopContainer from '../../../components/PageTopContainer'
import ConditionalRender from '../../../components/ConditionalRender'
import AddButton from '../../../components/AddButton'
import IconButton from '../../../components/IconButton'
import bigArrow from '../../../icons/arrow-big.svg'
import cancel from '../../../icons/cancel.svg'
import { useSearch } from '../../../hooks/useContext'
import axios from '../../../utils/axios'
import styles from './styles.module.scss'
import { useHistory } from 'react-router'
import CitiesModal from './CititesModal'

const Cities = () => {
  const { value } = useSearch()
  const history = useHistory()

  const [data, setData] = useState([])
  const [addModalOpen, setAddModalOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [pagination, setPagination] = useState({
    rowCount: 10,
    pageNum: 1,
    pageCount: 1
  })
  const [deletePrompt, setDeletePrompt] = useState({
    open: false,
    name: '',
    id: '',
    loading: false
  })
  const getData = useCallback(async () => {
    try {
      setLoading(true)
      const res = await axios.get('nomenclature/cities', {
        params: {
          text: value,
          'Pagination.RowCount': pagination.rowCount,
          'Pagination.PageNum': pagination.pageNum
        }
      })
      setData(res.cities)
      setPagination({ ...pagination, pageCount: res.pagination.pageCount })
    } catch {
    } finally {
      setLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, pagination.rowCount, pagination.pageNum])

  useEffect(() => {
    getData()
  }, [getData])

  const columns = useMemo(
    () => [
      {
        Header: '',
        accessor: 'name',
        disableSortBy: true
      },
      {
        Header: '',
        accessor: 'uid',
        disableSortBy: true,
        Cell: ({ value, row }) => (
          <div className={styles.buttons}>
            <IconButton
              icon={bigArrow}
              onClick={handleEditClick(row.original)}
              className={styles.arrowIcon}
            />
            <ConditionalRender authKey="allow-nomenclature-city-update">
              <IconButton icon={cancel} onClick={handleOpenDeletePrompt(row.original)} />
            </ConditionalRender>
          </div>
        )
      }
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  const handleOpenDeletePrompt = row => () => {
    setDeletePrompt({
      open: true,
      name: row.name,
      id: row.uid
    })
  }

  const handlePaginationChange = p => {
    setPagination({
      ...pagination,
      ...p
    })
  }

  const handleClickAddProduct = () => {
    setAddModalOpen(!addModalOpen)
  }

  const onAddModalClose = () => {
    setAddModalOpen(false)
    getData()
  }
  const handleDeleteProduct = async () => {
    try {
      setDeletePrompt(s => ({ ...s, loading: true }))
      await axios.delete(`nomenclature/city/${deletePrompt.id}`)
    } catch {
    } finally {
      setDeletePrompt(s => ({
        ...s,
        open: false,
        loading: false
      }))
      getData()
    }
  }

  const handleEditClick = row => () => {
    history.push(`/nom/city/${row.uid}`)
  }

  const handleCloseDeletePrompt = () => {
    setDeletePrompt(s => ({
      ...s,
      open: false
    }))
  }

  return (
    <div>
      <PageTopContainer>
        <div className={styles.firstRow}>
          <h1>Города</h1>
          <ConditionalRender authKey="allow-nomenclature-city-update">
            <AddButton
              label="Добавить город"
              isOpen={addModalOpen}
              onClick={handleClickAddProduct}
              onClose={handleClickAddProduct}
            >
              <CitiesModal onCloseEvent={onAddModalClose} />
            </AddButton>
          </ConditionalRender>
        </div>
      </PageTopContainer>
      <Table
        loading={loading}
        data={data}
        columns={columns}
        pagination={pagination}
        onPaginationChange={handlePaginationChange}
        className={styles.table}
      />

      <ConfirmDialog
        isOpen={deletePrompt.open}
        message={`Вы уверены, что хотите удалить город ${deletePrompt.name}?`}
        onOk={handleDeleteProduct}
        onCancel={handleCloseDeletePrompt}
        loading={deletePrompt.loading}
      />
    </div>
  )
}

export default Cities
