import { useState, useMemo, useCallback, useEffect } from 'react'
import PageTopContainer from '../../components/PageTopContainer'
import Table from '../../components/Table'
import CardTable from '../../components/CardTable'
import Select from '../../components/Select'
import Calendar from '../../components/Calendar'
import { useSearch } from '../../hooks/useContext'
import moment from 'moment'
import IconButton from '../../components/IconButton'
import bigArrow from '../../icons/arrow-big.svg'
import axios from '../../utils/axios'
import { useHistory } from 'react-router'
import deserializeList from '../../utils/deserializeList'
import BeatLoader from 'react-spinners/BeatLoader'
import formatDate from '../../utils/formatDate'
import cancel from '../../icons/cancel.svg'
import styles from './styles.module.scss'
import { Link, useParams } from 'react-router-dom'
import { exportColumns, makeData, getProp, exportToCSV } from './exportHelper'
import { dateFromUTCToLocale } from '../../utils/helper'

export default function PurchaseHistory() {
  const history = useHistory()
  const params = useParams()
  const { value } = useSearch()
  const {
    location: { state }
  } = useHistory()

  const [tableState, setTableState] = useState({
    events: [],
    loading: false,
    pagination: {
      pageCount: 1,
      pageNum: params.pageNum,
      rowCount: params.rowCount
    },
    filter: {
      dateRegStart: state?.dateRegStart || '',
      dateRegEnd: state?.dateRegEnd || '',
      selectedStatus: state?.selectedStatus || [],
      selectedCities: state?.selectedCities || [],
      selectedSchools: state?.selectedSchools || [],
      selectedKiosks: state?.selectedKiosks || [],
      dateDeliveryStart: state?.dateDeliveryStart || '',
      dateDeliveryEnd: state?.dateDeliveryEnd || '',
      selectedUser: state?.selectedUser
      // sortParam: undefined
    }
  })

  const [cities, setCities] = useState([])
  const [kiosks, setKiosks] = useState([])
  const [schools, setSchools] = useState([])
  const [statuses, setStatuses] = useState([])

  const getCities = useCallback(async () => {
    const res = await axios.get('catalogue/cities')
    const deserialized = deserializeList(res.cities)
    if (state?.selectedCities) {
      const idx = deserialized.findIndex(i => i.id === state.selectedCities[0])
      if (idx !== -1) {
        deserialized[idx].selected = true
      }
    }
    setCities(deserialized)
  }, [state?.selectedCities])

  const getSchools = useCallback(
    async array => {
      const res = await axios.get('catalogue/schools', {
        params: { selectedCities: [array] }
      })

      const deserialized = deserializeList(res.schools)
      if (state?.selectedSchools) {
        const idx = deserialized.findIndex(i => i.id === state.selectedSchools[0])
        if (idx !== -1) {
          deserialized[idx].selected = true
        }
      }
      setSchools(deserialized)
    },
    [state?.selectedSchools]
  )

  const getKiosks = useCallback(
    async array => {
      const res = await axios.get('catalogue/kiosks', {
        params: { selectedSchools: [array] }
      })
      const deserialized = deserializeList(res.kiosks)
      if (state?.selectedKiosks) {
        const idx = deserialized.findIndex(i => i.id === state.selectedKiosks[0])
        if (idx !== -1) {
          deserialized[idx].selected = true
        }
      }
      setKiosks(deserialized)
    },
    [state?.selectedKiosks]
  )

  const getStatuses = useCallback(async () => {
    const res = await axios.get('event/statuses')
    const deserialized = deserializeList(res.statuses)
    if (state?.selectedStatus) {
      const idx = deserialized.findIndex(i => i.id === state.selectedStatus[0])
      if (idx !== -1) {
        deserialized[idx].selected = true
      }
    }
    setStatuses(deserialized)
  }, [state?.selectedStatus])

  const getEvents = useCallback(async () => {
    setTableState(s => ({ ...s, loading: true }))

    const res = await axios.get('event/list', {
      params: {
        text: value,
        'Pagination.RowCount': tableState.pagination.rowCount,
        'Pagination.PageNum': tableState.pagination.pageNum,
        dateRegStart: tableState.filter.dateRegStart,
        dateRegEnd: tableState.filter.dateRegEnd,
        selectedStatus: tableState.filter.selectedStatus,
        selectedCities: tableState.filter.selectedCities,
        selectedSchools: tableState.filter.selectedSchools,
        selectedKiosks: tableState.filter.selectedKiosks,
        dateDeliveryStart: tableState.filter.dateDeliveryStart,
        dateDeliveryEnd: tableState.filter.dateDeliveryEnd,
        selectedUser: tableState.filter.selectedUser?.uid
      }
    })

    const events = res.events.map(item => {
      let colorIndicator
      switch (item.orderState) {
        case 'Оплачен':
          colorIndicator = '#FFEBAF'
          break
        case 'Выдан':
          colorIndicator = '#B2EEC2'
          break
        case 'Новый':
          colorIndicator = '#7A28CB'
          break
        case 'В процессе выдачи заказа':
          colorIndicator = '#545863'
          break
        case 'Выдан частично':
          colorIndicator = '#ABC9FD'
          break
        case 'Критическая ошибка':
          colorIndicator = '#E17D7D'
          break
        case 'Время оплаты истекло':
          colorIndicator = '#50808E'
          break
        default:
          colorIndicator = '#E17D7D'
      }
      return {
        ...item,
        subRows: [{ value: null }],
        colorIndicator
      }
    })

    setTableState(s => ({
      ...s,
      loading: false,
      events,
      pagination: {
        ...s.pagination,
        pageCount: res.pagination.pageCount
      }
    }))
  }, [
    value,
    tableState.pagination.rowCount,
    tableState.pagination.pageNum,
    tableState.filter.dateRegStart,
    tableState.filter.dateRegEnd,
    tableState.filter.selectedCities,
    tableState.filter.selectedStatus,
    tableState.filter.selectedSchools,
    tableState.filter.selectedKiosks,
    tableState.filter.dateDeliveryStart,
    tableState.filter.dateDeliveryEnd,
    tableState.filter.selectedUser?.uid
  ])

  const getFullListWithParams = async () => {
    try {
      const res = await axios.get('event/list', {
        params: {
          text: value,
          'Pagination.RowCount': -1,
          dateRegStart: tableState.filter.dateRegStart,
          dateRegEnd: tableState.filter.dateRegEnd,
          selectedStatus: tableState.filter.selectedStatus,
          selectedCities: tableState.filter.selectedCities,
          selectedSchools: tableState.filter.selectedSchools,
          selectedKiosks: tableState.filter.selectedKiosks,
          dateDeliveryStart: tableState.filter.dateDeliveryStart,
          dateDeliveryEnd: tableState.filter.dateDeliveryEnd,
          selectedUser: tableState.filter.selectedUser?.uid
        }
      })
      return res.events
    } catch (e) {
      throw new Error(e)
    }
  }

  useEffect(() => {
    getCities()
    getStatuses()
  }, [getCities, getStatuses])

  useEffect(() => {
    if (state && state.selectedCities?.length && state.selectedSchools?.length) {
      getSchools(state.selectedCities[0])
      getKiosks(state.selectedSchools[0])
    }
  }, [state, getSchools, getKiosks])

  useEffect(() => {
    getEvents()
  }, [getEvents])

  const columns = useMemo(
    () => [
      {
        Header: 'Киоск',
        accessor: 'kiosk.alias',
        disableSortBy: true
      },
      {
        Header: 'Город',
        accessor: 'kiosk.school.address',
        disableSortBy: true
      },
      {
        Header: 'Дата покупки',
        accessor: 'datePayed',
        disableSortBy: true,
        Cell: ({ value }) => (value ? formatDate(dateFromUTCToLocale(value)) : '-')
      },
      {
        Header: 'Кол-во товара',
        accessor: 'quantity',
        disableSortBy: true
      },
      {
        Header: 'Итоговая сумма',
        accessor: 'Sum',
        disableSortBy: true
      },
      {
        Header: 'Дата выдачи',
        accessor: 'dateDelivery',
        disableSortBy: true,
        Cell: ({ value }) => (value ? formatDate(dateFromUTCToLocale(value)) : '-')
      },
      {
        Header: 'Номер клиента',
        accessor: 'user.phone',
        disableSortBy: true
      },
      {
        Header: 'Статус выдачи',
        accessor: 'orderState',
        disableSortBy: true
      },
      {
        Header: '',
        accessor: 'colorIndicator',
        disableSortBy: true
      }
    ],
    []
  )

  const handleChangeSelect = name => value => {
    const selectedIds = value.flatMap(i => (i.selected ? [i.id] : []))
    const filter = { ...tableState.filter }
    const mapCb = i => {
      i.selected = selectedIds.includes(i.id)
      return i
    }

    if (name === 'selectedCities') {
      setKiosks([])
      getSchools(selectedIds)

      filter.selectedSchools = []
      filter.selectedKiosks = []

      setCities(cities.map(mapCb))
    }

    if (name === 'selectedSchools') {
      getKiosks(selectedIds)

      filter.selectedKiosks = []

      setSchools(schools.map(mapCb))
    }

    if (name === 'selectedKiosks') {
      setKiosks(kiosks.map(mapCb))
    }

    if (name === 'selectedStatus') {
      setStatuses(statuses.map(mapCb))
    }

    filter[name] = selectedIds
    setTableState(s => ({ ...s, filter }))
  }

  const handleChangeCalendar = name => values => {
    const { startDate, endDate } = values
    const filter = { ...tableState.filter }

    const normalizeStartDate = moment(startDate).format('DD.MM.YYYY')
    const normalizeEndDate = moment(endDate).format('DD.MM.YYYY')

    if (name === 'dateReg') {
      if (startDate !== '') {
        filter.dateRegStart = normalizeStartDate
      } else {
        filter.dateRegStart = ''
      }
      if (endDate !== '') {
        filter.dateRegEnd = normalizeEndDate
      } else {
        filter.dateRegEnd = ''
      }
    } else {
      if (startDate !== '') {
        filter.dateDeliveryStart = normalizeStartDate
      } else {
        filter.dateDeliveryStart = ''
      }
      if (endDate !== '') {
        filter.dateDeliveryEnd = normalizeEndDate
      } else {
        filter.dateDeliveryEnd = ''
      }
    }

    setTableState(s => ({ ...s, filter }))
  }

  const renderRowComponent = useCallback(
    ({ row, visibleColumns }) => (
      <SubRowComponent
        row={row}
        visibleColumns={visibleColumns}
        pageNum={params.pageNum}
        rowCount={params.rowCount}
        dateRegStart={tableState.filter.dateRegStart}
        dateRegEnd={tableState.filter.dateRegEnd}
        selectedStatus={tableState.filter.selectedStatus}
        selectedCities={tableState.filter.selectedCities}
        selectedSchools={tableState.filter.selectedSchools}
        selectedKiosks={tableState.filter.selectedKiosks}
        dateDeliveryStart={tableState.filter.dateDeliveryStart}
        dateDeliveryEnd={tableState.filter.dateDeliveryEnd}
        selectedUser={tableState.filter.selectedUser}
      />
    ),
    [tableState, params]
  )

  const handlePaginationChange = values => {
    setTableState(s => ({ ...s, pagination: { ...s.pagination, ...values } }))
    history.push(`purchase-history&pageNum=${values.pageNum}&rowCount=${values.rowCount}`)
  }

  const handleDownloadTableData = async () => {
    // получаем список всех событий (без пагинации)
    const fullList = await getFullListWithParams()
    const data = []

    for (let event of fullList) {
      data.push(makeData(event))
    }

    let dataForExport = []
    let temp = []
    const headers = exportColumns.map(item => item.title)

    data.forEach(row => {
      temp.push(
        exportColumns.map(col => {
          let val = ''
          val = getProp(row, col.field)
          return val
        })
      )
    })
    temp.forEach(item => {
      dataForExport.push(Object.assign(...headers.map((k, i) => ({ [k]: item[i] }))))
    })

    exportToCSV(dataForExport, 'История покупок')
  }

  const handleClearSelectedUser = () => {
    setTableState(s => ({ ...s, filter: { ...s.filter, selectedUser: {} } }))
  }

  return (
    <div>
      <PageTopContainer>
        <div>
          <h1>
            Лента событий
            {tableState.filter.selectedUser?.uid && (
              <span className={styles.selectedUser}>
                {`(${
                  tableState.filter.selectedUser.name ||
                  tableState.filter.selectedUser.phoneNumber
                })`}
                <button
                  type="button"
                  onClick={handleClearSelectedUser}
                  className={styles.clearSelectedUserButton}
                >
                  <img alt="" src={cancel} />
                </button>
              </span>
            )}
          </h1>
        </div>
        <div className={styles.secondRow}>
          <Select
            text="Город"
            options={cities}
            value={tableState.filter.selectedCities}
            onSave={handleChangeSelect('selectedCities')}
          />
          <Select
            text="Учреждения"
            options={schools}
            value={tableState.filter.selectedSchools}
            nothingFoundText="Сначала выберите город"
            onSave={handleChangeSelect('selectedSchools')}
          />
          <Select
            text="Киоски"
            options={kiosks}
            value={tableState.filter.selectedKiosks}
            nothingFoundText="Сначала выберите учреждение"
            onSave={handleChangeSelect('selectedKiosks')}
          />
          <Calendar
            name="dateReg"
            withoutTime
            text="Период покупки"
            value={
              (tableState.filter.dateRegStart || tableState.filter.dateRegEnd) ?? {
                dateStart: tableState.filter.dateRegStart,
                dateEnd: tableState.filter.dateRegEnd
              }
            }
            onSave={handleChangeCalendar('dateReg')}
            className={styles.calendar}
          />
          <Calendar
            name="dateDelivery"
            text="Период выдачи"
            withoutTime
            onSave={handleChangeCalendar('dateDelivery')}
            value={
              (tableState.filter.dateDeliveryStart ||
                tableState.filter.dateDeliveryEnd) ?? {
                startDate: tableState.filter.dateDeliveryStart,
                endDate: tableState.filter.dateDeliveryEnd
              }
            }
            className={styles.calendar}
          />
          <Select
            name="statuses"
            text="Статус выдачи"
            options={statuses}
            onSave={handleChangeSelect('selectedStatus')}
            className={styles.status}
          />
        </div>
      </PageTopContainer>
      <Table
        id="table-events"
        paginationOnTheTop
        rowIdToScroll={state?.rowIndex}
        columns={columns}
        data={tableState.events}
        loading={tableState.loading}
        renderRowSubComponent={renderRowComponent}
        onExport={handleDownloadTableData}
        onPaginationChange={handlePaginationChange}
        pagination={tableState.pagination}
      />
    </div>
  )
}

const SubRowComponent = props => {
  const {
    row,
    visibleColumns,
    dateRegStart,
    dateRegEnd,
    selectedStatus,
    selectedCities,
    selectedSchools,
    selectedKiosks,
    dateDeliveryStart,
    dateDeliveryEnd,
    selectedUser,
    pageNum,
    rowCount
  } = props

  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true)

  const history = useHistory()

  const columns = useMemo(
    () => [
      {
        Header: 'Товары',
        accessor: 'name'
      },
      {
        Header: 'Количество',
        accessor: 'quantity'
      },
      {
        Header: 'Стоимость',
        accessor: 'price'
      },
      {
        Header: 'Сумма без скидки',
        accessor: 'discontPrice'
      },
      {
        Header: 'Скидка',
        accessor: 'discount'
      },
      {
        Header: 'Итоговая сумма',
        accessor: 'totalPrice'
      }
    ],
    []
  )

  const getOrders = useCallback(async () => {
    const id = row.original.uid

    try {
      const res = await axios.get(`event/${id}`)
      setData(res.orders)
    } catch {
    } finally {
      setLoading(false)
    }
  }, [row.original.uid])

  useEffect(() => {
    getOrders()
  }, [getOrders])

  const rowIndex = row.id

  const handleGoToPurchasePage = id => () => {
    history.push(`/purchase-history/purchase/${id}`, {
      rowIndex,
      dateRegStart,
      dateRegEnd,
      selectedStatus,
      selectedCities,
      selectedSchools,
      selectedKiosks,
      dateDeliveryStart,
      dateDeliveryEnd,
      selectedUser,
      pageNum,
      rowCount
    })
  }

  return loading ? (
    <tr>
      <td colSpan={9} className={styles.subTableLoading}>
        <BeatLoader color="#F56947" loading={true} />
      </td>
    </tr>
  ) : (
    <tr>
      <td className={styles.subTableContainer} colSpan={visibleColumns.length + 1}>
        <CardTable
          data={data}
          columns={columns}
          event={{
            date: row.original.datePayed ? dateFromUTCToLocale(row.original.datePayed, "DD.MM.YYYY hh:mm") : '-',
            sum: row.original.Sum
          }}
          icon={
            <Link
              to={{
                pathname: `/purchase-history/purchase/${row.original.uid}`,
                state: {
                  rowIndex,
                  dateRegStart,
                  dateRegEnd,
                  selectedStatus,
                  selectedCities,
                  selectedSchools,
                  selectedKiosks,
                  dateDeliveryStart,
                  dateDeliveryEnd,
                  selectedUser,
                  pageNum,
                  rowCount
                }
              }}
            >
              <IconButton icon={bigArrow} className={styles.goToPurchaseButton} />
            </Link>
          }
        />
      </td>
    </tr>
  )
}
