import { useProfile } from '../../hooks/useContext'

export default function ConditionalRender(props) {
  const { authKey, roles, children } = props

  const { user } = useProfile()

  return (
    <>
      {roles && authKey
        ? roles.includes(user.role) && user.authKeys.includes(authKey) && children
        : (user.authKeys.includes(authKey) || roles?.includes(user.role)) && children}
    </>
  )
}
