import { useState, useEffect } from 'react'
import { useDebounce } from 'use-debounce'
import { useHistory } from 'react-router-dom'

import Avatar from '../Avatar'
import TextInput from '../TextInput'
import IconButton from '../IconButton'

import styles from './styles.module.scss'
import logoutIcon from '../../icons/logout.svg'
import { useSearch, useHideSearch, useProfile } from '../../hooks/useContext'

export default function Header(props) {
  const [query, setQuery] = useState('')
  const { setValue } = useSearch()
  const { isHidden, setHidden } = useHideSearch()
  const history = useHistory()

  const handleChange = event => {
    setQuery(event.target.value)
  }

  const [searchString] = useDebounce(query, 800)

  useEffect(() => {
    history.listen(location => {
      // Сброс поиска при смене страницы
      setQuery('')
      setValue('')
      setHidden(false)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setValue(searchString.trim())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchString])

  const { user, logout } = useProfile()

  return (
    <header className={styles.header}>
      {!isHidden && (
        <div className={styles.searchContainer}>
          <TextInput
            value={query}
            onChange={handleChange}
            className={styles.search}
            fullWidth
            placeholder="Поиск по таблице"
          />
        </div>
      )}

      <div className={styles.accountContainer}>
        <div onClick={props.onAvatarClick} className={styles.account}>
          <Avatar />
          <div className={styles.userInfoContainer}>
            <div className={styles.accountName}>{user?.visibleName ?? '-'}</div>
            <div className={styles.accountName}>{user?.userRole?.name}</div>
          </div>
        </div>
      </div>

      <IconButton icon={logoutIcon} className={styles.accountLogout} onClick={logout} />
    </header>
  )
}
