export const formatProductsCount = (total: number) => {
  const count = total;
  const numbers = String(count);

  if (numbers.length === 2 && numbers[0] === "1") {
    return "товаров";
  }

  const lastNumber = numbers[numbers.length - 1];

  switch (lastNumber) {
    case "1":
      return "товар";
    case "2":
    case "3":
    case "4":
      return "товара";
    case "5":
    case "6":
    case "7":
    case "8":
    case "9":
      return "товаров";

    default:
      return "товаров";
  }
};
