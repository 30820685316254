import { useFormik } from 'formik'
import * as yup from 'yup'
import Button from '../../../../components/Button'
import TextInput from '../../../../components/TextInput'
import axios from '../../../../utils/axios'
import { useMemo, useState, useEffect, useCallback } from 'react'
import Table from '../../../../components/Table'
// import InputMaskField from '../../../../components/InputMaskField'
import ConditionalRender from '../../../../components/ConditionalRender'
import ConfirmDialog from '../../../../components/ConfirmDialog'
import PageTopContainer from '../../../../components/PageTopContainer'
import AddButton from '../../../../components/AddButton'
import IconButton from '../../../../components/IconButton'
import edit from '../../../../icons/edit.svg'
import cancel from '../../../../icons/cancel.svg'
import Modal from '../../../../components/Modal'
import { useParams } from 'react-router'
import DistrictModal from '../Districts/DistrictsModal'
import GoBackButton from '../../../../components/GoBackButton'
import styles from './styles.module.scss'

const Schools = () => {
  const params = useParams()

  const [data, setData] = useState([])
  const [addModalOpen, setAddModalOpen] = useState(false)
  const [modalEditDistrictOpen, setModalEditDistrictOpen] = useState(false)
  const [modalEditSchoolOpen, setModalEditSchoolOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [editSchool, setEditSchool] = useState(null)
  const [district, setDistrict] = useState({})
  const [pagination, setPagination] = useState({
    rowCount: 10,
    pageNum: 1,
    pageCount: 1
  })
  const [deletePrompt, setDeletePrompt] = useState({
    open: false,
    name: '',
    id: '',
    loading: false
  })
  const getData = useCallback(async () => {
    try {
      setLoading(true)
      const schoolsRes = await axios.get(
        `nomenclature/district/${params.district}/schools`,
        {
          params: {
            'Pagination.RowCount': pagination.rowCount,
            'Pagination.PageNum': pagination.pageNum
          }
        }
      )
      const cityRes = await axios.get(`nomenclature/city/${params.city}`)
      const currentDistrict = cityRes.city.districts.filter(
        f => f.uid === params.district
      )
      setData(schoolsRes.schools)
      setDistrict({ name: currentDistrict[0].name, uid: currentDistrict[0].uid })
    } catch (e) {
      console.log(e)
    } finally {
      setLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination.rowCount, pagination.pageNum])

  useEffect(() => {
    getData()
  }, [getData])

  const columns = useMemo(
    () => [
      {
        Header: '',
        accessor: 'name',
        disableSortBy: true
      },
      {
        Header: '',
        accessor: 'address',
        disableSortBy: true
      },
      {
        Header: '',
        accessor: 'uid',
        disableSortBy: true,
        Cell: ({ value, row }) => (
          <ConditionalRender authKey="allow-nomenclature-city-update">
            <div className={styles.buttons}>
              <IconButton icon={edit} onClick={handleEditIconClick(row.original)} />
              <IconButton icon={cancel} onClick={handleOpenDeletePrompt(row.original)} />
            </div>
          </ConditionalRender>
        )
      }
    ],
    []
  )

  const handlePaginationChange = p => {
    setPagination({
      ...pagination,
      ...p
    })
  }

  const handleEditIconClick = row => () => {
    setEditSchool(row)
    setModalEditSchoolOpen(true)
  }

  const handleOpenDeletePrompt = row => () => {
    setDeletePrompt({
      open: true,
      name: row.name,
      id: row.uid
    })
  }

  const handleClickAddProduct = () => {
    setAddModalOpen(!addModalOpen)
  }

  const handleCloseModal = () => {
    setModalEditDistrictOpen(false)
    setModalEditSchoolOpen(false)
  }

  const handleEditDistrict = () => {
    setModalEditDistrictOpen(true)
  }

  const handleEventClose = () => {
    setModalEditDistrictOpen(false)
    setModalEditSchoolOpen(false)
    setAddModalOpen(false)
    getData()
  }

  const handleDeleteSchool = async () => {
    try {
      setDeletePrompt(s => ({ ...s, loading: true }))
      await axios.delete(`nomenclature/school/${deletePrompt.id}`)
    } catch {
    } finally {
      setDeletePrompt(s => ({
        ...s,
        open: false,
        loading: false
      }))
      getData()
    }
  }

  const handleCloseDeletePrompt = () => {
    setDeletePrompt(s => ({
      ...s,
      open: false
    }))
  }

  return (
    <div>
      <PageTopContainer>
        <div className={styles.firstRow}>
          <GoBackButton />
          <h1>Школы</h1>
        </div>
        <div className={styles.secondRow}>
          <div class={styles.titleContainer}>
            <h2>{district.name}</h2>
            <ConditionalRender authKey="allow-nomenclature-city-update">
              <IconButton icon={edit} onClick={handleEditDistrict} />
            </ConditionalRender>
          </div>
          <ConditionalRender authKey="allow-nomenclature-city-update">
            <AddButton
              label="Добавить учреждение"
              isOpen={addModalOpen}
              onClick={handleClickAddProduct}
              onClose={handleClickAddProduct}
              className={styles.addButton}
            >
              <SchoolModal
                onCloseEvent={handleEventClose}
                schoolId={params.id}
                districtId={district.uid}
              />
            </AddButton>
          </ConditionalRender>
        </div>
      </PageTopContainer>
      <Table
        loading={loading}
        data={data}
        columns={columns}
        pagination={pagination}
        onPaginationChange={handlePaginationChange}
        className={styles.table}
      />
      <Modal centered isOpen={modalEditSchoolOpen} onClose={handleCloseModal}>
        <SchoolModal onCloseEvent={handleEventClose} school={editSchool} />
      </Modal>
      <Modal centered isOpen={modalEditDistrictOpen} onClose={handleCloseModal}>
        <DistrictModal
          onCloseEvent={handleEventClose}
          district={district}
          cityId={params.city}
        />
      </Modal>

      <ConfirmDialog
        isOpen={deletePrompt.open}
        message={`Вы уверены, что хотите удалить учреждение ${deletePrompt.name}?`}
        onOk={handleDeleteSchool}
        onCancel={handleCloseDeletePrompt}
        loading={deletePrompt.loading}
      />
    </div>
  )
}

export default Schools

const SchoolModal = props => {
  const { school, onCloseEvent, districtId } = props

  const handleSave = async values => {
    try {
      const data = values
      data.districtUid = districtId
      if (school) {
        await axios.put(`nomenclature/school/${school.uid}`, data)
      } else {
        await axios.post('nomenclature/school', data)
      }
    } catch {
    } finally {
      onCloseEvent()
    }
  }

  const { values, errors, touched, setFieldValue, handleSubmit } = useFormik({
    onSubmit: values => handleSave(values),
    initialValues: {
      name: school ? school.name : '',
      address: school ? school.address : '',
      latitude: school ? school.latitude : '',
      longitude: school ? school.latitude : ''
    },
    validationSchema: yup.object().shape({
      name: yup.string().required('Это поле обязательно'),
      address: yup.string().required('Это поле обязательно'),
      latitude: yup
        .string()
        .required('Это поле обязательно')
        .matches(/^(\d{2})(\.)(\d{2,15})$/, 'Неверный формат (56.7891011)'),
      longitude: yup
        .string()
        .required('Это поле обязательно')
        .matches(/^(\d{2})(\.)(\d{2,15})$/, 'Неверный формат (56.7891011)')
    }),
    enableReinitialize: true
  })

  const handleChange = name => event => {
    setFieldValue(name, event.target.value)
  }

  return (
    <div>
      <h2>{school ? 'Редактировать учреждение' : 'Добавить учреждение'}</h2>
      <form onSubmit={handleSubmit}>
        <div className={styles.textInputs}>
          <TextInput
            label="Название"
            placeholder="МБОУ СОШ №149"
            value={values.name}
            errorMessage={touched.name && errors.name}
            onChange={handleChange('name')}
            tabIndex={1}
          />

          <div className={styles.spacer1} />

          <TextInput
            label="Адрес"
            placeholder="г. Красноярск, ул. Взлетная, д.23"
            value={values.address}
            errorMessage={touched.address && errors.address}
            onChange={handleChange('address')}
            tabIndex={2}
          />

          <div className={styles.spacer1} />

          <TextInput
            label="Ширина"
            placeholder="56.036160633777286"
            value={values.latitude}
            errorMessage={touched.latitude && errors.latitude}
            onChange={handleChange('latitude')}
            tabIndex={3}
          />

          <div className={styles.spacer1} />

          <TextInput
            label="Долгота"
            placeholder="92.87651998115226"
            value={values.longitude}
            errorMessage={touched.longitude && errors.longitude}
            onChange={handleChange('longitude')}
            tabIndex={4}
          />
        </div>

        <div className={styles.spacer1}></div>

        <div className={styles.modalButtons}>
          <Button primary type="submit">
            {school ? 'Изменить' : 'Добавить'}
          </Button>
          {school && (
            <Button white onClick={() => onCloseEvent()}>
              Отменить
            </Button>
          )}
        </div>
      </form>
    </div>
  )
}
