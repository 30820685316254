import GridLoader from 'react-spinners/GridLoader'

export default function Spinner(props) {
  const { loading, className } = props

  return (
    <div className={className}>
      <GridLoader
        color="#F56947"
        loading={loading}
        css={{
          width: 57,
          display: 'block'
        }}
      />
    </div>
  )
}
