import Modal from '../Modal'
import Button from '../Button'
import styles from './styles.module.scss'

export default function ConfirmDialog(props) {
  const {
    message,
    isOpen,
    onOk,
    onCancel,
    loading,
    okText,
    cancelText,
    okType,
    okGray,
    cancelGray
  } = props

  return (
    <Modal isOpen={isOpen} onClose={onCancel} centered>
      <div className={styles.container}>
        <div className={styles.text}>{message}</div>
        <div className={styles.actions}>
          <Button
            onClick={onOk}
            type={okType}
            className={styles.action}
            gray={okGray}
            disabled={loading}
            loading={loading}
          >
            {okText || 'Да'}
          </Button>
          <Button onClick={onCancel} gray={cancelGray} className={styles.action}>
            {cancelText || 'Отменить'}
          </Button>
        </div>
      </div>
    </Modal>
  )
}
