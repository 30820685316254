import { useFormik } from 'formik'
import * as yup from 'yup'

import { useMemo, useState, useEffect, useCallback } from 'react'
// import ConfirmDialog from '../../../components/ConfirmDialog'

import { useProfile, useSearch } from '../../../hooks/useContext'
import Table from '../../../components/Table'
import PageTopContainer from '../../../components/PageTopContainer'
import Select from '../../../components/Select'
import AddButton from '../../../components/AddButton'
import TextInput from '../../../components/TextInput'
import ConditionalRender from '../../../components/ConditionalRender'
import Modal from '../../../components/Modal'
import Button from '../../../components/Button'
import IconButton from '../../../components/IconButton'
import axios from '../../../utils/axios'
import Checkbox from '../../../components/Checkbox'

import EditIcon from '../../../icons/edit.svg'
// import CrossIcon from '../../../icons/close-thick.svg'
import styles from './styles.module.scss'
import SelectSingle from '../../../components/SelectSingle'
import deserializeList, { deserializeListObject } from '../../../utils/deserializeList'
import NumberInput from '../../../components/NumberInput'
import { EditingImage } from '../../../components/EditingImage'
import { dataURLtoFile } from '../../../utils/dataURLtoFile'
import { useHistory } from 'react-router'
import { toast } from 'react-toastify'

export default function NomGoods() {
  const { value } = useSearch()
  const {
    location: { state }
  } = useHistory()

  const [goods, setGoods] = useState({
    data: [],
    loading: true,
    pagination: {
      rowCount: 10,
      pageNum: 1,
      pageCount: 1
    },
    filters: {
      category: [],
      archived: false
    }
  })

  // eslint-disable-next-line no-unused-vars
  const [deletePrompt, setDeletePrompt] = useState({
    open: false,
    name: '',
    id: '',
    loading: false
  })
  const [editProductId, setEditProductId] = useState('')
  const [dealers, setDealers] = useState([])
  const [selectedDealers, setSelectedDealers] = useState('')
  const [modalEditOpen, setModalEditOpen] = useState(false)
  const [categories, setCategories] = useState([])
  const [addModalOpen, setAddModalOpen] = useState(false)

  const getProducts = useCallback(async () => {
    let data, pagination

    try {
      setGoods(g => ({ ...g, loading: true }))

      const filtering = []

      if (value) {
        filtering.push({
          FieldName: 'Text',
          ValueString: value
        })
      }

      if (selectedDealers) {
        if (selectedDealers[0] !== state?.dealer) {
          if (selectedDealers !== null) {
            filtering.push({
              FieldName: 'DealerUid',
              ValueUid: selectedDealers[0] || ''
            })
          }
        }
      }

      if (state) {
        if (state?.dealer !== undefined) {
          filtering.push({
            FieldName: 'DealerUid',
            ValueUid: state?.dealer || ''
          })
        }
      }

      if (goods.filters.category.length !== 0) {
        goods.filters.category.forEach(c => {
          filtering.push({
            FieldName: 'CategoryUid',
            ValueUid: c
          })
        })
      }

      if (!goods.filters.archived) {
        filtering.push({
          FieldName: 'Active',
          ValueString: 'true'
        })
      }

      if (goods.filters.archived) {
        filtering.push()
      }

      const res = await axios.post('nomenclature/product/list', {
        Pagination: {
          RowCount: goods.pagination.rowCount,
          PageNum: goods.pagination.pageNum,
          PageCount: goods.pagination.pageCount
        },
        Filtering: filtering
      })

      data = res.nomenclatures
      pagination = res.pagination
    } catch (e) {
      console.log('debug error', e)
    } finally {
      setGoods(g => ({ ...g, data: data ?? g.data, loading: false, pagination }))
    }
  }, [
    value,
    state,
    selectedDealers,
    goods.pagination.rowCount,
    goods.pagination.pageNum,
    goods.pagination.pageCount,
    goods.filters.category,
    goods.filters.archived
  ])

  const handleUpdateActive = useCallback(
    async (id, value) => {
      try {
        await axios.put(`nomenclature/product/archive/${id}?isActive=${!value}`)
      } catch {
      } finally {
        getProducts()
      }
    },
    [getProducts]
  )

  // const handleDeleteProduct = async () => {
  //   try {
  //     setDeletePrompt(s => ({ ...s, loading: true }))
  //     await axios.delete(`nomenclature/product/${deletePrompt.id}`)
  //   } catch {
  //   } finally {
  //     setDeletePrompt(s => ({
  //       ...s,
  //       open: false,
  //       loading: false
  //     }))
  //     getProducts()
  //   }
  // }

  const handleCategoryFilter = values => {
    const category = values.flatMap(v => (v.selected ? [v.id] : []))
    setGoods(s => ({
      ...s,
      filters: { ...s.filters, category },
      pagination: { ...s.pagination, pageNum: 1 }
    }))
    setCategories(values)
  }

  useEffect(() => {
    getProducts()
  }, [getProducts])

  const getCategories = async () => {
    const res = await axios('category/list')
    setCategories(res.map(i => deserializeListObject(i)))
  }

  const handleEditClick = id => {
    setEditProductId(id)
    setModalEditOpen(true)
  }

  const onAddModalClose = () => {
    setAddModalOpen(false)
  }

  const handleClickAddProduct = () => {
    setAddModalOpen(true)
  }

  const handleCloseAddModal = () => {
    setAddModalOpen(false)
  }

  const handleCloseEditModal = () => {
    setEditProductId('')
    setModalEditOpen(false)
  }

  const handleChangeArhive = () =>
    setGoods(g => ({ ...g, filters: { ...g.filters, archived: !g.filters.archived } }))

  const handleSuccess = () => {
    getProducts()
  }

  useEffect(() => {
    if (!categories.length) {
      getCategories()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getDealers = useCallback(async () => {
    const res = await axios.get('/kiosk/dealers')
    const deserialized = deserializeList(res.dealers)
    if (state?.dealer) {
      const idx = deserialized.findIndex(i => i.id === state.dealer)
      if (idx !== -1) {
        deserialized[idx].selected = true
      }
    }
    setDealers(deserialized)
  }, [state?.dealer])

  useEffect(() => {
    getDealers()
  }, [getDealers])

  const сolumns = useMemo(
    () => [
      {
        Header: 'Категория товара',
        accessor: 'category.name',
        disableSortBy: true,
        Cell: ({ value }) => {
          return value ? value : '-'
        }
      },
      {
        Header: 'Группа товара',
        accessor: 'group.name',
        disableSortBy: true,
        Cell: ({ value }) => value || '-'
      },
      {
        Header: 'Наименование товара',
        accessor: 'name',
        disableSortBy: true,
        Cell: ({ value, row }) => (
          <div className={styles.nomination}>
            <div className={styles.image}>
              <img src={row.original.image} alt={row.original.name} />
            </div>
            <p>{row.original.name}</p>
          </div>
        )
      },
      {
        Header: 'Дилер',
        accessor: 'dealer.visibleName',
        disableSortBy: true
      },
      {
        Header: '',
        accessor: 'uid',
        disableSortBy: true,
        Cell: ({ value, row }) => (
          <ConditionalRender authKey="allow-nomenclature-goods-update">
            <div className={styles.buttons}>
              <IconButton
                icon={EditIcon}
                onClick={() => handleEditClick(row.original.uid)}
              />
              {/*<IconButton*/}
              {/*  icon={CrossIcon}*/}
              {/*  onClick={handleOpenDeletePrompt(row.original)}*/}
              {/*/>*/}
              <Button
                white
                onClick={() => handleUpdateActive(row.original.uid, row.original.active)}
              >
                {row.original.active === true ? 'Отправить в архив' : 'Вернуть из архива'}
              </Button>
            </div>
          </ConditionalRender>
        )
      }
    ],
    [handleUpdateActive]
  )

  const handlePaginationChange = p => {
    setGoods(g => ({ ...g, pagination: { ...g.pagination, ...p } }))
  }

  // eslint-disable-next-line no-unused-vars
  const handleOpenDeletePrompt = row => () => {
    setDeletePrompt({
      open: true,
      name: row.name,
      id: row.uid
    })
  }

  // eslint-disable-next-line no-unused-vars
  const handleCloseDeletePrompt = () => {
    setDeletePrompt(s => ({
      ...s,
      open: false
    }))
  }

  const handleFilterByKioskDealer = values => {
    const dealer = values.flatMap(v => (v.selected ? [v.id] : []))
    setSelectedDealers(dealer)
    setDealers(values)
  }

  return (
    <div>
      <PageTopContainer>
        <div className={styles.firstRow}>
          <div>
            <h1>Номенклатура</h1>
            <span>Товары</span>
          </div>
          <ConditionalRender roles={['dealer', 'dealerEmployee', 'admin']}>
            <AddButton label="Добавить товар" onClick={handleClickAddProduct} />
          </ConditionalRender>
        </div>
        <div className={styles.secondRow}>
          <ConditionalRender roles={'admin'}>
            <Select
              text="Дилеры"
              single
              options={dealers}
              value={[selectedDealers]}
              onSave={handleFilterByKioskDealer}
              className={styles.input}
            />
          </ConditionalRender>
          <Select
            text="Категория товара"
            options={categories}
            onSave={handleCategoryFilter}
          />
          <div className={styles.checkboxContainer}>
            <Checkbox
              label="Показать архив"
              className={styles.checkbox}
              onChange={handleChangeArhive}
              checked={goods.filters.archived}
            />
          </div>
        </div>
      </PageTopContainer>
      <Table
        loading={goods.loading}
        data={goods.data}
        columns={сolumns}
        pagination={goods.pagination}
        onPaginationChange={handlePaginationChange}
        className={styles.table}
      />

      <Modal centered isOpen={addModalOpen} onClose={handleCloseAddModal}>
        <ProductModal onCloseEvent={onAddModalClose} update={handleSuccess} />
      </Modal>

      <Modal centered isOpen={modalEditOpen} onClose={handleCloseEditModal}>
        <ProductModal
          product={editProductId}
          onCloseEvent={handleCloseEditModal}
          update={handleSuccess}
        />
      </Modal>

      {/*<ConfirmDialog*/}
      {/*  isOpen={deletePrompt.open}*/}
      {/*  message={`Вы уверены, что хотите убрать товар ${deletePrompt.name}?`}*/}
      {/*  onOk={handleDeleteProduct}*/}
      {/*  onCancel={handleCloseDeletePrompt}*/}
      {/*  loading={deletePrompt.loading}*/}
      {/*/>*/}
    </div>
  )
}

export const ProductModal = props => {
  const { onCloseEvent, product, update } = props

  const [initialState, setInitialState] = useState([])
  const isAdmin = useProfile().user.role === 'admin'

  const [productCategories, setProductCategories] = useState([])
  const [productGroups, setProductGroups] = useState([])
  const [nds, setNds] = useState([])

  const getCategoriesProduct = async () => {
    const res = await axios.get('/category/list')
    setProductCategories(res.map(i => deserializeListObject(i)))
  }
  const getGroups = async () => {
    const res = await axios.get('/nomenclature/product/groups')
    setProductGroups(res.map(i => deserializeListObject(i)))
  }
  const getNds = async () => {
    const res = await axios.get('nomenclature/NDSTypes')
    setNds(res.map(i => ({ id: i.id, label: i.name })))
  }

  useEffect(() => {
    getCategoriesProduct()
    getGroups()
    getNds()
  }, [])

  const getProduct = useCallback(async () => {
    try {
      const res = await axios.get(`nomenclature/product/${product}`)
      const nom = res.nomenclature

      const initialState = {
        name: nom.name,
        info: nom.info,
        categoryUid: nom.categoryUid,
        parentUid: nom.parentUid,
        // weight: nom.weight,
        expirationDays: nom.expirationDays,
        nds: nom.nds,
        price: nom.price,
        priceIn: nom.priceIn,
        image: undefined
      }

      if (isAdmin) {
        initialState.dealerUid = nom.dealerUid
      }

      setInitialState(initialState)
    } catch {}
  }, [product, isAdmin])

  const [dealers, setDealers] = useState([])

  const getDealers = async () => {
    const res = await axios.get('/kiosk/dealers')
    if (res?.dealers) {
      setDealers(res.dealers.map(d => deserializeListObject(d)))
    }
  }

  useEffect(() => {
    if (product) {
      getProduct()
    }

    if (isAdmin) {
      getDealers()
    }
  }, [product, getProduct, isAdmin])

  const handleSave = async values => {
    try {
      const formData = new FormData()
      for (let key in values) {
        if (key === 'expirationDays' && values.unlimitedExprirationDate === true) {
          formData.append(key, '')
        } else {
          formData.append(key, values[key])
        }
      }

      if (product) {
        await axios.put(`nomenclature/product/${product}`, formData)
      } else {
        await axios.post('nomenclature/product', formData)
      }
    } catch {
    } finally {
      onCloseEvent()
      update()
    }
  }

  const { values, errors, touched, setFieldValue, handleSubmit, isSubmitting } =
    useFormik({
      onSubmit: handleSave,
      initialValues: product
        ? initialState
        : (() => {
            const state = {
              name: '',
              info: '',
              categoryUid: '',
              unlimitedExprirationDate: false,
              parentUid: '',
              // weight: '',
              expirationDays: 1,
              nds: '',
              price: null,
              priceIn: null,
              image: undefined
            }

            if (isAdmin) {
              state.dealerUid = ''
            }

            return state
          })(),
      validationSchema: yup.object().shape({
        name: yup.string().required('Это поле обязательно'),
        info: yup.string().required('Это поле обязательно'),
        categoryUid: yup.string().required('Это поле обязательно'),
        dealerUid: isAdmin ? yup.string().required('Это поле обязательно') : null,
        parentUid: yup.string().required('Это поле обязательно'),
        expirationDays: yup.number().when('unlimitedExprirationDate', {
          is: false,
          then: yup
            .number()
            .min(1, 'Неверное значение')
            .nullable(true)
            .typeError('Необходимо ввести число')
        }),
        nds: yup.string().required('Это поле обязательно'),
        price: yup
          .number()
          .min(1, 'Неверное значение')
          .required('Это поле обязательно')
          .typeError('Необходимо ввести число'),
        priceIn: yup
          .number()
          .min(1, 'Неверное значение')
          .required('Это поле обязательно')
          .typeError('Необходимо ввести число'),
        image: product
          ? yup.mixed().notRequired()
          : yup.mixed().required('Изображение обязательно')
      }),
      enableReinitialize: true
    })

  const handleChange = name => event => {
    setFieldValue(name, event.target.value)
  }

  useEffect(() => {
    if (values.expirationDays === null) {
      setFieldValue('expirationDays', '')
    }
    // eslint-disable-next-line
  }, [values.expirationDays])

  const handleChangeCheckbox = name => value => {
    setFieldValue(name, value.target.checked)
    if (value.target.checked === false) {
      setFieldValue('expirationDays', '1')
    } else {
      setFieldValue('expirationDays', '')
    }
  }

  const handleChangeSelector = name => value => {
    setFieldValue(name, value?.id)
  }

  // const handleChangePhoto = (name, event) => {
  //   setFieldValue(name, event.target.files[0])
  // }

  const [editModal, setEditModal] = useState(false)
  const [selectedFile, setSelectedFile] = useState()
  const [preview, setPreview] = useState()

  // create a preview as a side effect, whenever selected file is changed
  useEffect(() => {
    if (!selectedFile) {
      setPreview(undefined)
      return
    }

    const objectUrl = URL.createObjectURL(selectedFile)
    setPreview(objectUrl)

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl)
  }, [selectedFile])

  const onSelectFile = e => {
    if (!/image\/[\w]*/g.test(e.target.files[0].type)) {
      setSelectedFile(undefined)
      return toast.error('Неверный формат файла')
    }

    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile(undefined)
      return
    }

    // I've kept this example simple by using the first image instead of multiple
    setSelectedFile(e.target.files[0])
    setEditModal(true)
  }

  function closeModalEdit() {
    setEditModal(false)
    setSelectedFile(undefined)
    setPreview(undefined)
    document.getElementById('itemFile').value = ''
  }

  return (
    <div>
      <h2>{product ? 'Редактировать товар' : 'Добавить товар'}</h2>
      <form enctype="multipart/form-data" onSubmit={handleSubmit}>
        <div className={styles.textInputs}>
          <ConditionalRender roles={['admin']}>
            <SelectSingle
              name="dealerUid"
              visibleLabel="Дилер"
              text="Дилер"
              disabled={product}
              value={values.dealerUid}
              errorMessage={touched.dealerUid && errors.dealerUid}
              onChange={handleChangeSelector('dealerUid')}
              options={dealers}
              tabIndex={4}
            />
            <div className={styles.spacer1} />
          </ConditionalRender>

          <TextInput
            label="Название"
            name="name"
            value={values.name}
            errorMessage={touched.name && errors.name}
            onChange={handleChange('name')}
            tabIndex={1}
            maxSymbols={64}
            showMaxSymbols={false}
          />

          <div className={styles.spacer1}></div>

          <TextInput
            label="Описание"
            name="info"
            value={values.info}
            errorMessage={touched.info && errors.info}
            onChange={handleChange('info')}
            textarea
            maxSymbols={320}
            tabIndex={2}
          />
        </div>

        <div className={styles.spacer1}></div>

        <div className={styles.fullWidth}>
          <SelectSingle
            visibleLabel="Категория товара"
            name="categoryUid"
            text="Категория товара"
            options={productCategories}
            value={values.categoryUid}
            errorMessage={touched.categoryUid && errors.categoryUid}
            onChange={handleChangeSelector('categoryUid')}
            tabIndex={3}
          />
        </div>

        <div className={styles.spacer1}></div>

        <div className={styles.addCol}>
          <div className={styles.addRow}>
            <SelectSingle
              name="parentUid"
              visibleLabel="Группа товара"
              text="Группа товара"
              value={values.parentUid}
              errorMessage={touched.parentUid && errors.parentUid}
              onChange={handleChangeSelector('parentUid')}
              options={productGroups}
              tabIndex={4}
            />

            <div className={styles.spacer1}></div>

            {/* <TextInput
              label="Единица измерения"
              name="weight"
              value={values.weight}
              errorMessage={touched.weight && errors.weight}
              onChange={handleChange('weight')}
              tabIndex={5}
            /> */}

            <div className={styles.spacer1}></div>

            <div>
              <Checkbox
                label="Неограниченный срок годности"
                checked={values.expirationDays === ''}
                className={styles.unlimited_expriration_date_checkbox}
                onChange={handleChangeCheckbox('unlimitedExprirationDate')}
                value={values.unlimitedExprirationDate}
                error={
                  touched.unlimitedExprirationDate && errors.unlimitedExprirationDate
                }
              />

              {values?.expirationDays !== null && (
                <>
                  {values.expirationDays !== '' && (
                    <NumberInput
                      label="Срок годности по умолчанию (в днях)"
                      className={styles.number_input}
                      name="expirationDays"
                      value={values.expirationDays}
                      errorMessage={touched.expirationDays && errors.expirationDays}
                      onChange={handleChange('expirationDays')}
                      tabIndex={6}
                    />
                  )}
                </>
              )}
            </div>
          </div>

          <div className={styles.addRow}>
            <SelectSingle
              single
              visibleLabel="Ставка НДС"
              name="nds"
              text="НДС"
              value={values.nds}
              errorMessage={touched.nds && errors.nds}
              onChange={handleChangeSelector('nds')}
              options={nds}
              tabIndex={7}
            />

            <div className={styles.spacer1}></div>

            <NumberInput
              label="Отпускная цена"
              value={values.price}
              errorMessage={touched.price && errors.price}
              onChange={handleChange('price')}
              tabIndex={8}
            />

            <div className={styles.spacer1}></div>

            <NumberInput
              label="Себестоимость"
              name="priceIn"
              value={values.priceIn}
              errorMessage={touched.priceIn && errors.priceIn}
              onChange={handleChange('priceIn')}
              tabIndex={9}
            />
          </div>
        </div>

        <div className={styles.spacer1}></div>

        <div className={styles.addImage}>
          <input type="file" id="itemFile" accept="image/*" onChange={onSelectFile} />
          {touched.image && <p className={styles.addError}>{errors.image}</p>}
        </div>

        <div className={styles.spacer1}></div>
        {editModal && (
          <EditingImage
            imageOriginal={preview}
            onCancel={() => closeModalEdit()}
            onChange={e => {
              const image = dataURLtoFile(e, 'Тест')
              setFieldValue('image', image)
            }}
            onContinue={() => setEditModal(false)}
          />
        )}

        <div className={styles.modalButtons}>
          <Button loading={isSubmitting} primary type="submit">
            {product ? 'Изменить' : 'Добавить'}
          </Button>
          {product && (
            <Button white onClick={() => onCloseEvent()}>
              Отменить
            </Button>
          )}
        </div>
      </form>
    </div>
  )
}
