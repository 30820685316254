import styles from './styles.module.scss'
import download from '../../icons/download.svg'
import arrowNext from '../../icons/arrow-back.svg'
import IconButton from '../IconButton'
import SelectSingle from '../SelectSingle'
import ReactPaginate from 'react-paginate'

const ROWS_PER_PAGE = [
  { id: 10, label: 10 },
  { id: 20, label: 20 },
  { id: 40, label: 40 }
  // { id: -1, label: 'Все' }
]

export default function Pagination(props) {
  const { pagination, onChange, onDownload } = props

  const handlePageChange = page => {
    onChange({ rowCount: pagination.rowCount, pageNum: page.selected + 1 })
  }

  const handleChangeRowCount = rowCount => {
    onChange({ rowCount: rowCount.id, pageNum: 1 })
  }

  return (
    <div className={styles.pagination}>
      <div className={styles.showPerPage}>
        <span>Выводить по:</span>
        <SelectSingle
          value={pagination.rowCount}
          options={ROWS_PER_PAGE}
          positionRight
          onChange={handleChangeRowCount}
        />
      </div>
      {pagination.pageCount > 0 && (
        <ReactPaginate
          forcePage={pagination.pageNum - 1}
          pageCount={pagination.pageCount}
          pageRangeDisplayed={5}
          marginPagesDisplayed={2}
          onPageChange={handlePageChange}
          pageClassName={styles.page}
          nextClassName={styles.next}
          breakClassName={styles.break}
          activeClassName={styles.active}
          previousClassName={styles.prev}
          disabledClassName={styles.disabled}
          containerClassName={styles.container}
          nextLabel={<img alt="Далее" src={arrowNext} />}
          previousLabel={<img alt="Назад" src={arrowNext} />}
        />
      )}
      <div className={styles.download}>
        {onDownload && <IconButton icon={download} onClick={onDownload} />}
      </div>
    </div>
  )
}
