import Fade from '../Fade'
import BeatLoader from 'react-spinners/BeatLoader'
import styles from './styles.module.scss'

export default function PageLoader(props) {
  const { loading, children } = props

  return (
    <>
      <Fade isShown={!loading}>{children}</Fade>
      {loading && (
        <div className={styles.loader}>
          <BeatLoader color="#F56947" loading={true} />
        </div>
      )}
    </>
  )
}
