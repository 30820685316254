import clsx from 'clsx'
import styles from './styles.module.scss'

export default function NumberInput(props) {
  const {
    value,
    onChange,
    placeholder,
    className,
    label,
    fullWidth,
    errorMessage,
    textarea,
    type,
    ...rest
  } = props

  return (
    <div className={clsx(styles.container, fullWidth && styles.fullWidth, className)}>
      {label && <label className={styles.label}>{label}</label>}
      {textarea ? (
        <textarea
          className={styles.input}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          rows={6}
          {...rest}
        />
      ) : (
        <input
          type="number"
          className={styles.input}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          step="any"
          {...rest}
        />
      )}
      {errorMessage && <p className={styles.error}>{errorMessage}</p>}
    </div>
  )
}
