/* eslint-disable no-unused-vars */
import React, {useRef, useState} from 'react'
import styles from './style.module.scss'
import Button from '../Button'
import {Cropper} from "react-cropper";
import "cropperjs/dist/cropper.css";

export const EditingImage = props => {
  const { imageOriginal, onCancel, onChange, onContinue } = props
  const [cropper, setCropper] = useState();
  const imageRef = useRef(null);

  const getCropData = async () => {
    if (typeof cropper !== "undefined") {
      onChange(cropper.getCroppedCanvas().toDataURL())
      onContinue()
    }
  };

  return (
    <div style={{ transition: '300ms' }}>
      <div className={styles.cropperContainer}>
        <Cropper
            style={{ height: 400, width: 600 }}
            aspectRatio={1}
            src={imageOriginal}
            ref={imageRef}
            guides={true}
            onInitialized={(instance) => {
              setCropper(instance);
            }}
        />
        <h1 className={styles.title}>Кадрирование</h1>
        <div className={styles.modalButtons}>
          <Button onClick={onCancel} white className={styles.button}>
            Назад
          </Button>
          <Button onClick={getCropData} className={styles.button}>
            Сохранить
          </Button>
        </div>
      </div>
    </div>
  )
}
