import './styles.scss'

export default function ProductList(props) {
  const { products, choose } = props

  const handleItemClick = item => () => {
    choose(item)
  }

  return (
    <div>
        <ul className="product-container">
            {products.length === 0 ? (
                <p>Ничего не найдено</p>
            ) : (
                products.map(p => (
                    <li className="product-list" key={p.uid} onClick={handleItemClick(p)}>
                        <div className="product-list__img">
                            <img src={p.image} alt={p.name} />
                        </div>
                        <p>{p.name}</p>
                        <p>{p.price} руб.</p>
                    </li>
                ))
            )}
        </ul>
    </div>
  )
}
