import clsx from 'clsx'
import styles from './styles.module.scss'
import { useEffect, useState } from 'react'
import { parseWorkingDays } from '../../utils/parseWorkingDays'

const ScheduleDays = props => {
  const scheduleDays = [
    {
      id: 1,
      title: 'Понедельник',
      shortTitle: 'Пн',
      active: '0'
    },
    {
      id: 2,
      title: 'Вторник',
      shortTitle: 'Вт',
      active: '0'
    },
    {
      id: 3,
      title: 'Среда',
      shortTitle: 'Ср',
      active: '0'
    },
    {
      id: 4,
      title: 'Четверг',
      shortTitle: 'Чт',
      active: '0'
    },
    {
      id: 5,
      title: 'Пятница',
      shortTitle: 'Пт',
      active: '0'
    },
    {
      id: 6,
      title: 'Суббота',
      shortTitle: 'Сб',
      active: '0'
    },
    {
      id: 7,
      title: 'Воскресенье',
      shortTitle: 'Вс',
      active: '0'
    }
  ]

  const { onChange, errors, activeDays } = props
  const [days, setDays] = useState([])

  const handleClickList = index => {
    const newDays = days
    newDays[index] =
      newDays[index].active === '1'
        ? { ...newDays[index], active: '0' }
        : { ...newDays[index], active: '1' }
    setDays(newDays)

    let activeDaysArray = []
    for (let i = 0; i < days.length; i++) {
      activeDaysArray.push(days[i].active)
    }
    onChange(activeDaysArray.join(''))
  }

  useEffect(() => {
    if (activeDays) {
      const parsedDays = parseWorkingDays(activeDays)
      setDays(parsedDays)
    } else {
      setDays(scheduleDays)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeDays])

  return (
    <div className={styles.container}>
      <ul className={styles.daysList}>
        {days.map((day, index) => (
          <li
            className={clsx(styles.daysListItem, day.active === '1' && styles.active)}
            key={index}
            onClick={() => handleClickList(index)}
          >
            {day.shortTitle}
          </li>
        ))}
      </ul>
      {errors && (
        <div className={styles.error}>
          <span>{errors}</span>
        </div>
      )}
    </div>
  )
}

export default ScheduleDays
