import { CSSTransition } from 'react-transition-group'
import styles from './styles.module.scss'

export default function DropdownContainer(props) {
  const { isShown, children, ...rest } = props

  return (
    <CSSTransition
      in={isShown}
      timeout={200}
      unmountOnExit
      classNames={{ ...styles }}
      {...rest}
    >
      {children}
    </CSSTransition>
  )
}
