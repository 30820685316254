export const parseWorkingDays = stringDays => {
  return stringDays.split('').map((item, index) => {
    switch (index) {
      case 0:
        return { id: 1, title: 'Понедельник', shortTitle: 'Пн', active: item }
      case 1:
        return { id: 2, title: 'Вторник', shortTitle: 'Вт', active: item }
      case 2:
        return { id: 3, title: 'Среда', shortTitle: 'Ср', active: item }
      case 3:
        return { id: 4, title: 'Четверг', shortTitle: 'Чт', active: item }
      case 4:
        return { id: 5, title: 'Пятница', shortTitle: 'Пт', active: item }
      case 5:
        return { id: 6, title: 'Суббота', shortTitle: 'Сб', active: item }
      case 6:
        return { id: 7, title: 'Воскресенье', shortTitle: 'Вс', active: item }
      default:
        return { id: 0, title: 'Не работает', active: 1 }
    }
  })
}
