import { useState, useEffect, useMemo, useCallback } from 'react'
import { useHistory } from 'react-router'
// import { useSearch } from '../../hooks/useContext'
import Table from '../../components/Table'
import PageTopContainer from '../../components/PageTopContainer'
// import ConditionalRender from '../../components/ConditionalRender'
import Select from '../../components/Select'
// import AddButton from '../../components/AddButton'
// import Modal from '../../components/Modal'
import Calendar from '../../components/Calendar'
// import KioskForm from '../../components/KioskForm'
// import deserializeList from '../../utils/deserializeList'
// import formatRuble from '../../utils/formatRuble'
// import formatDate from '../../utils/formatDate'
// import axios from '../../utils/axios'
// import XLSX from 'xlsx'
import styles from './styles.module.scss'

export default function RechargeHistory() {
  const {
    location: { state }
  } = useHistory()

  const handleDownloadTableData = () => {}

  const columns = useMemo(
    () => [
      {
        Header: 'Дата пополнения',
        accessor: 'number',
        disableSortBy: true
      },
      {
        Header: 'Сумма пополнения',
        accessor: 'alias',
        disableSortBy: true
      },
      {
        Header: 'ФИО клиента',
        accessor: 'visibleName',
        disableSortBy: true
      },
      {
        Header: 'Статус пополнения',
        accessor: 'dealer.visibleName',
        disableSortBy: true
      },
      {
        Header: '',
        accessor: 'colorIndicator',
        disableSortBy: true
      }
    ],
    []
  )

  const getHistory = useCallback(async () => {
    // const filtering = []
    // const res = await axios.post('')
  }, [])

  useEffect(() => {
    getHistory()

    const getStatuses = async () => {
      // const res = await axios.get()
    }

    getStatuses()
  }, [getHistory])

  const [tableState, setTableState] = useState({
    data: [],
    loading: false,
    pagination: {
      pageCount: 1,
      pageNum: 1,
      rowCount: 10
    },
    filters: {
      statuses: [],
      period: null,
      clientUid: state?.clientUid || ''
    }
  })
  const [statuses] = useState([])

  const handleSelectStatuses = () => {}

  const handleFilterByBuyPeriod = value => {
    const period = value.startDate ? value : null
    setTableState(s => ({ ...s, period }))
  }

  const handlePaginationChange = pagination => {
    setTableState(s => ({
      ...s,
      pagination: {
        ...tableState.pagination,
        ...pagination
      }
    }))
  }

  return (
    <div>
      <PageTopContainer>
        <div className={styles.firstRow}>
          <h1>История пополнений</h1>
        </div>
        <div className={styles.secondRow}>
          <Select
            text="Статус пополнения"
            options={statuses}
            onSave={handleSelectStatuses}
            className={styles.select}
          />
          <Calendar
            text="Период покупки"
            onSave={handleFilterByBuyPeriod}
            className={styles.input}
            value={tableState.lastSale}
          />
        </div>
      </PageTopContainer>
      <Table
        id="table-profiles"
        loading={tableState.loading}
        className={styles.table}
        columns={columns}
        data={tableState.data}
        onExport={handleDownloadTableData}
        pagination={tableState.pagination}
        onPaginationChange={handlePaginationChange}
      />
    </div>
  )
}
