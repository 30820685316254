import clsx from 'clsx'
import { useState, useRef, useMemo, useEffect } from 'react'
import useOnClickOutside from '../../hooks/useOnClickOutside'
import styles from './styles.module.scss'
import triangle from '../../icons/triangle.svg'
import DropdownContainer from '../DropdownContainer'

export default function SelectSingle(props) {
  const {
    value,
    visibleLabel,
    options,
    onChange,
    positionRight,
    text,
    required = false,
    className,
    errorMessage,
    disabled
  } = props

  const ref = useRef()
  const list = useRef()

  const [isOpen, setOpen] = useState(false)

  const handleOpen = () => {
    !disabled && setOpen(!isOpen)
  }

  const handleClose = () => setOpen(false)

  const handleChange = option => () => {
    onChange(option)
    handleClose()
  }

  const selected = useMemo(
    () => options?.find(option => option.id === value),
    [value, options]
  )

  useOnClickOutside(ref, handleClose)

  useEffect(() => {
    if (isOpen && list?.current && selected) {
      const index = options?.findIndex(option => option.id === selected.id)
      list.current.scrollTo({ top: index * 29 })
    }
  }, [isOpen, options, selected])

  return (
    <div ref={ref} className={clsx(styles.container, className)}>
      {visibleLabel && <div className={styles.label}>{visibleLabel}</div>}
      <button
        type="button"
        style={{ cursor: disabled ? 'not-allowed' : 'pointer' }}
        className={clsx(
          !visibleLabel && styles.button,
          visibleLabel && styles.buttonLable,
          disabled && styles.disabled
        )}
        onClick={handleOpen}
        disabled={disabled}
      >
        <div
          className={clsx(
            styles.buttonText,
            selected?.label && styles.selectedButtonText,
            visibleLabel && !selected?.label && styles.tinyLabelText
          )}
        >
          {required && <span style={{ color: 'red' }}> *</span>} {selected?.label || text}
        </div>
        <img
          className={clsx(styles.triangle, isOpen && styles.rotated)}
          src={triangle}
          alt=""
        />
      </button>
      {errorMessage && <p className={styles.error}>{errorMessage}</p>}

      {positionRight ? (
        <div className={clsx(styles.listContainerTop, isOpen && styles.visibleList)}>
          <ul ref={list} className={styles.list}>
            {options?.map(option => (
              <li
                key={option.id}
                onClick={handleChange(option)}
                className={clsx(
                  styles.option,
                  selected?.id === option.id && styles.optionSelected
                )}
              >
                {option.label}
              </li>
            ))}
          </ul>
        </div>
      ) : (
        <DropdownContainer isShown={isOpen}>
          <div
            className={clsx(
              styles.listContainer,
              visibleLabel && styles.listContainerWithLabel
            )}
          >
            <ul ref={list} className={styles.list}>
              {options.length === 0 ? (
                <li className={clsx(styles.option, styles.nothingFound)}>
                  Ничего не найдено
                </li>
              ) : (
                options?.map(option => (
                  <li
                    key={option.id}
                    onClick={handleChange(option)}
                    className={clsx(
                      styles.option,
                      selected?.id === option.id && styles.optionSelected
                    )}
                  >
                    {option.label}
                  </li>
                ))
              )}
            </ul>
          </div>
        </DropdownContainer>
      )}
    </div>
  )
}
