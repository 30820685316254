export default function formatRuble(number) {
  if (number == null) return ''

  const decimal = String(number).split('.')[1] ? 2 : 0

  const re = '\\d(?=(\\d{3})+' + (decimal > 0 ? '\\D' : '$') + ')'
  const num = parseFloat(number).toFixed(Math.max(0, ~~decimal))

  return num.replace('.', ',').replace(new RegExp(re, 'g'), '$&\xa0') + '\xa0р.'
}
