import { useState, useMemo, useCallback, useEffect } from 'react'
import PageTopContainer from '../../components/PageTopContainer'
import Table from '../../components/Table'
import Select from '../../components/Select'
import IconButton from '../../components/IconButton'
import AddButton from '../../components/AddButton'
import TextInput from '../../components/TextInput'
import Button from '../../components/Button'
import ConfirmDialog from '../../components/ConfirmDialog'
import ConditionalRender from '../../components/ConditionalRender'
import Modal from '../../components/Modal'
import axios from '../../utils/axios'
import deserializeList from '../../utils/deserializeList'
import capitalize from '../../utils/capitalize'
import { useSearch } from '../../hooks/useContext'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import edit from '../../icons/edit.svg'
import cancel from '../../icons/cancel.svg'
import Role from './Role'
import styles from './styles.module.scss'
import SwitchButton from '../../components/SwitchButton'

const roleKeys = [
  {
    name: 'Киоски',
    write: 'allow-kiosk-get',
    read: 'allow-kiosk-update'
  },
  {
    name: 'Интернет магазин',
    write: 'allow-shop-get',
    read: 'allow-shop-update'
  },
  {
    name: 'Лента событий',
    write: 'allow-feed-get',
    read: 'allow-feed-update'
  },
  {
    name: 'Система оповещения',
    write: 'allow-notification-get',
    read: 'allow-notification-update'
  },
  {
    name: 'Акции',
    write: 'allow-stock-get',
    read: 'allow-stock-update'
  },
  {
    name: 'Пользователи',
    write: 'allow-user-get',
    read: 'allow-user-update'
  },
  {
    name: 'Отчётность',
    write: 'allow-report-get',
    read: 'allow-report-update'
  },
  {
    name: 'Номенклатура',
    write: 'allow-nomenclature-get',
    read: 'allow-nomenclature-update'
  }
]

const nomRoles = [
  {
    name: 'Товары',
    write: 'allow-nomenclature-goods-get',
    read: 'allow-nomenclature-goods-update'
  },
  {
    name: 'Дилеры',
    write: 'allow-nomenclature-dealer-get',
    read: 'allow-nomenclature-dealer-update'
  },
  {
    name: 'Категории',
    write: 'allow-nomenclature-category-get',
    read: 'allow-nomenclature-category-update'
  },
  {
    name: 'Группы',
    write: 'allow-nomenclature-group-get',
    read: 'allow-nomenclature-group-update'
  },
  {
    name: 'Города',
    write: 'allow-nomenclature-city-get',
    read: 'allow-nomenclature-city-update'
  }
]

const shopRoles = [
  {
    name: 'Товары',
    write: 'allow-shop-goods-get',
    read: 'allow-shop-goods-update'
  },
  {
    name: 'Пункты выдачи',
    write: 'allow-shop-pick-point-get',
    read: 'allow-shop-pick-point-update'
  },
  {
    name: 'Условия доставки',
    write: 'allow-shop-delivery-get',
    read: 'allow-shop-delivery-update'
  }
]

const eventRoles = [
  {
    name: 'История покупок',
    write: 'allow-feed-purchase-get',
    read: 'allow-feed-purchase-update'
  },
  {
    name: 'Технические события',
    write: 'allow-feed-tech-get',
    read: 'allow-feed-tech-update'
  }
]

const promoRoles = [
  {
    name: 'Киоски',
    write: 'allow-stock-kiosk-get',
    read: 'allow-stock-kiosk-update'
  },
  {
    name: 'Интернет-магазин',
    write: 'allow-stock-shop-get',
    read: 'allow-stock-shop-update'
  },
  {
    name: 'Рекламные баннеры',
    write: 'allow-stock-banner-get',
    read: 'allow-stock-banner-update'
  }
]

const userRoles = [
  {
    name: 'Роли',
    write: 'allow-user-role-get',
    read: 'allow-user-role-update'
  },
  {
    name: 'Учетные записи',
    write: 'allow-user-user-get',
    read: 'allow-user-user-update'
  }
]

const reportRoles = [
  {
    name: 'Киоски',
    write: 'allow-report-kiosk-get',
    read: 'allow-report-kiosk-update'
  },
  {
    name: 'Интернет-магазин',
    write: 'allow-report-shop-get',
    read: 'allow-report-shop-update'
  }
]

function RoleSwitcher(props) {
  const { initialValues, title, onSubmit } = props

  const formik = useFormik({
    initialValues: initialValues
      ? initialValues
      : {
          name: '',
          employeeDealer: false,
          permissions: {
            ...roleKeys.reduce(
              (p, c) => ({ ...p, [c.write]: false, [c.read]: false }),
              {}
            ),
            ...nomRoles.reduce(
              (p, c) => ({ ...p, [c.write]: false, [c.read]: false }),
              {}
            ),
            ...shopRoles.reduce(
              (p, c) => ({ ...p, [c.write]: false, [c.read]: false }),
              {}
            ),
            ...eventRoles.reduce(
              (p, c) => ({ ...p, [c.write]: false, [c.read]: false }),
              {}
            ),
            ...promoRoles.reduce(
              (p, c) => ({ ...p, [c.write]: false, [c.read]: false }),
              {}
            ),
            ...userRoles.reduce(
              (p, c) => ({ ...p, [c.write]: false, [c.read]: false }),
              {}
            ),
            ...reportRoles.reduce(
              (p, c) => ({ ...p, [c.write]: false, [c.read]: false }),
              {}
            )
          }
        },
    validationSchema: Yup.object({
      name: Yup.string().required('Название не может быть пустым'),
      permissions: Yup.object()
    }),
    onSubmit
  })

  const handleChangeName = ev => {
    const val = ev.target.value

    if (formik.values.name.length <= 64) {
      formik.setFieldValue('name', val)
    } else if (val < formik.values.name) {
      formik.setFieldValue('name', val)
    }
  }

  // const handleSwitchChange = ({ target }) => {
  //   const { id, checked } = target
  //   console.log(id, checked)

  //   let newPermissions = {
  //     ...formik.values.permissions,
  //     [id]: checked
  //   }

  //   if (id === 'allow-nomenclature-get' && !checked) {
  //     const key = id.split('get')
  //     return formik.setFieldValue('permissions', {
  //       ...formik.values.permissions, [id]: false, [key[0] + 'update']: false, ...nomRoles.reduce((p, c) => (
  //         { ...p, [c.write]: false, [c.read]: false }
  //       ), {})
  //     })
  //   }

  //   if (id === 'allow-nomenclature-update' && !checked) {
  //     return formik.setFieldValue('permissions', {
  //       ...formik.values.permissions, [id]: false, ...nomRoles.reduce((p, c) => (
  //         { ...p, [c.read]: false }
  //       ), {})
  //     })
  //   }

  //   const updateKey = id.split('update')
  //   if (updateKey.length === 2 && checked) {
  //     newPermissions = {
  //       ...newPermissions,
  //       [updateKey[0] + 'get']: checked
  //     }
  //   }

  //   const getKey = id.split('get')
  //   if (getKey.length === 2 && !checked) {
  //     const correspondingUpdateKey = getKey[0] + 'update'
  //     newPermissions[correspondingUpdateKey] = checked
  //   }

  //   formik.setFieldValue('permissions', newPermissions)
  // }

  const handleRoleChange = permissions => {
    formik.setFieldValue('permissions', permissions)
  }

  const handleEmployeeChange = e => {
    formik.setFieldValue('dealerEmployee', e.target.checked)
  }

  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <h2 className={styles.title}>{title}</h2>
        <SwitchButton
          id="employeeSwitch"
          onLabel="Сотрудник дилера"
          checked={formik.values.dealerEmployee}
          onChange={handleEmployeeChange}
        />
      </div>
      <form onSubmit={formik.handleSubmit}>
        <TextInput
          placeholder="Название"
          label="Название роли"
          errorMessage={formik.touched.name && formik.errors.name}
          value={formik.values.name}
          onChange={handleChangeName}
          className={styles.roleNameInput}
        />
        <div className={styles.switchesContainer}>
          <h3 className={styles.subtitle}>Доступные разделы</h3>

          <Role
            soloRole={roleKeys[0]}
            soloSwitcher
            values={formik.values}
            onRoleChange={handleRoleChange}
          />
          <Role
            parentRole={roleKeys[1]}
            childRoles={shopRoles}
            values={formik.values}
            onRoleChange={handleRoleChange}
          />
          <Role
            parentRole={roleKeys[2]}
            childRoles={eventRoles}
            values={formik.values}
            onRoleChange={handleRoleChange}
          />
          <Role
            soloRole={roleKeys[3]}
            soloSwitcher
            values={formik.values}
            onRoleChange={handleRoleChange}
          />
          <Role
            parentRole={roleKeys[4]}
            childRoles={promoRoles}
            values={formik.values}
            onRoleChange={handleRoleChange}
          />
          <Role
            parentRole={roleKeys[5]}
            childRoles={userRoles}
            values={formik.values}
            onRoleChange={handleRoleChange}
          />
          <Role
            parentRole={roleKeys[6]}
            childRoles={reportRoles}
            values={formik.values}
            onRoleChange={handleRoleChange}
          />
          <Role
            parentRole={roleKeys[7]}
            childRoles={nomRoles}
            values={formik.values}
            onRoleChange={handleRoleChange}
          />
          {/* {roleKeys.map(k => (
            <div
              key={k.name}
              className={styles.switchRow}
            >
              <div className={styles.section}>
                {k.name}
              </div>
              <div className={styles.switches}>
                <SwitchButton
                  id={k.write}
                  onLabel='Просмотр'
                  checked={formik.values.permissions[k.write]}
                  onChange={handleSwitchChange}
                />

                <SwitchButton
                  id={k.read}
                  onLabel='Редактирование'
                  checked={formik.values.permissions[k.read]}
                  onChange={handleSwitchChange}
                />
              </div>
            </div>
          )
          )}
          {formik.values.permissions['allow-nomenclature-get'] && nomRoles.map(k => (
            <div
              key={k.name}
              className={styles.switchRow}
            >
              <div className={styles.section}>
                {k.name}
              </div>
              <div className={styles.switches}>
                <SwitchButton
                  id={k.write}
                  onLabel='Просмотр'
                  checked={formik.values.permissions[k.write]}
                  onChange={handleSwitchChange}
                />

                <SwitchButton
                  id={k.read}
                  onLabel='Редактирование'
                  checked={formik.values.permissions[k.read]}
                  onChange={handleSwitchChange}
                />
              </div>
            </div>
          ))
          } */}
        </div>
        <div className={styles.buttonContainer}>
          <Button type="submit" className={styles.submit}>
            Сохранить
          </Button>
        </div>
      </form>
    </div>
  )
}

export default function Roles() {
  const { value } = useSearch()
  const [sections, setSections] = useState([])
  const [permissions, setPermissions] = useState([])
  const [isAddOpen, setAddOpen] = useState(false)

  const [tableState, setTableState] = useState({
    loading: false,
    pagination: {
      pageCount: 1,
      pageNum: 1,
      rowCount: 10
    },
    sections: [],
    permissions: [],
    roles: []
  })

  const [deleteModal, setDeleteModal] = useState({
    loading: false,
    isOpen: false,
    roleId: '',
    roleName: ''
  })

  const [editModal, setEditModal] = useState({
    isOpen: false,
    roleName: '',
    roleId: '',
    employeeDealer: '',
    initialValues: undefined
  })

  const handleOpenDeleteModal = (id, name) => () => {
    setDeleteModal({
      loading: false,
      isOpen: true,
      roleId: id,
      roleName: name
    })
  }

  const handleCloseDeleteModal = () => {
    setDeleteModal(s => ({
      ...s,
      loading: false,
      isOpen: false
    }))
  }

  const handleDeleteRole = async () => {
    setDeleteModal(s => ({ ...s, loading: true }))
    try {
      await axios.delete(`/role/${deleteModal.roleId}`, { toast: true })
      handleCloseDeleteModal()
      getRoles()
    } catch (error) {
      setDeleteModal(s => ({ ...s, loading: false }))
    }
  }

  const handleOpenEditModal = (id, name, sections, dealerEmployee) => () => {
    setEditModal({
      isOpen: true,
      roleName: name,
      roleId: id,
      initialValues: {
        name: name,
        permissions: sections.reduce((p, c) => ({ ...p, [c.code]: c.access }), {}),
        dealerEmployee: dealerEmployee
      }
    })
  }

  const handleCloseEditModal = () => {
    setEditModal(s => ({ ...s, isOpen: false }))
  }

  const handleOpenAddModal = () => setAddOpen(true)

  const handleCloseAddModal = () => {
    setAddOpen(false)
  }

  const getRoles = useCallback(async () => {
    setTableState(s => ({ ...s, loading: true }))

    const res = await axios.get('/role/list', {
      params: {
        text: value,
        'Pagination.RowCount': tableState.pagination.rowCount,
        'Pagination.PageNum': tableState.pagination.pageNum,
        sections: tableState.sections,
        access: tableState.permissions
      }
    })

    const { pagination, roles } = res
    setTableState(s => ({
      ...s,
      pagination,
      roles,
      loading: false
    }))
  }, [
    value,
    tableState.pagination.pageNum,
    tableState.pagination.rowCount,
    tableState.sections,
    tableState.permissions
  ])

  const getSections = useCallback(async () => {
    const res = await axios.get('/dealer/sections')

    const sections = deserializeList(res.sections)

    setSections(sections)
  }, [])

  const getPermissions = useCallback(async () => {
    const res = await axios.get('/dealer/access')

    const permissions = deserializeList(res.access)

    setPermissions(permissions)
  }, [])

  useEffect(() => {
    getRoles()
  }, [getRoles])

  useEffect(() => {
    getSections()
    getPermissions()
  }, [getSections, getPermissions])

  const columns = useMemo(
    () => [
      {
        Header: 'Роль',
        accessor: 'name',
        disableSortBy: true
      },
      {
        Header: 'Просмотр',
        accessor: 'canGet',
        disableSortBy: true,
        Cell: ({ value }) => capitalize(value.join(', '))
      },
      {
        Header: 'Редактирование',
        accessor: 'canUpdate',
        disableSortBy: true,
        Cell: ({ value }) => capitalize(value.join(', '))
      },
      {
        Header: '',
        accessor: 'edit',
        disableSortBy: true,
        Cell: ({ row }) => (
          <ConditionalRender authKey="allow-user-role-update">
            <IconButton
              icon={edit}
              className={styles.iconCell}
              disabled={!row.original.allowUpdate}
              onClick={handleOpenEditModal(
                row.original.uid,
                row.original.name,
                row.original.sections,
                row.original.dealerEmployee
              )}
            />
          </ConditionalRender>
        )
      },
      {
        Header: '',
        accessor: 'delete',
        disableSortBy: true,
        Cell: ({ row }) => (
          <ConditionalRender authKey="allow-user-role-update">
            <IconButton
              icon={cancel}
              className={styles.iconCell}
              disabled={!row.original.allowDelete}
              onClick={handleOpenDeleteModal(row.original.uid, row.original.name)}
            />
          </ConditionalRender>
        )
      }
    ],
    []
  )

  const handleFilterBySections = values => {
    const codes = values.flatMap(s => (s.selected ? [s.id] : []))

    const isEmpty = codes.length === 0

    if (isEmpty) {
      const defaults = permissions.map(p => ({ ...p, selected: false }))
      setPermissions(defaults)
    }

    setTableState(s => ({
      ...s,
      sections: codes
    }))

    setSections(values)
  }

  const handleFilterByPermissions = values => {
    const codes = values.flatMap(s => (s.selected ? [s.id] : []))

    setTableState(s => ({
      ...s,
      permissions: codes
    }))

    setPermissions(values)
  }

  // const handleDownloadTableData = () => {

  // }

  const handlePaginationChange = pagination => {
    setTableState(s => ({
      ...s,
      roles: tableState.roles,
      pagination: {
        ...tableState.pagination,
        ...pagination
      }
    }))
  }

  const handleSubmitAddRole = async values => {
    const payload = {
      name: values.name,
      dealerEmployee: values.dealerEmployee,
      sections: Object.entries(values.permissions).map(([k, v]) => ({
        code: k,
        access: v
      }))
    }

    await axios.post('/role', payload, { toast: true })
    getRoles()
    handleCloseAddModal()
  }

  const handleSubmitEditRole = async values => {
    const payload = {
      name: values.name,
      dealerEmployee: values.dealerEmployee,
      sections: Object.entries(values.permissions).map(([k, v]) => ({
        code: k,
        access: v
      }))
    }

    console.log(payload)

    await axios.put(`/role/${editModal.roleId}`, payload, { toast: true })
    getRoles()
    handleCloseEditModal()
  }

  return (
    <div>
      <PageTopContainer>
        <div className={styles.firstRow}>
          <h1>Роли</h1>
          <ConditionalRender authKey="allow-user-role-update">
            <AddButton
              label="Добавить роль"
              isOpen={isAddOpen}
              onClick={handleOpenAddModal}
              onClose={handleCloseAddModal}
            >
              <RoleSwitcher title="Новая роль" onSubmit={handleSubmitAddRole} />
            </AddButton>
          </ConditionalRender>
        </div>
        <div className={styles.secondRow}>
          <Select
            text="Разделы"
            options={sections}
            onSave={handleFilterBySections}
            className={styles.select}
          />
          <Select
            text="Доступ"
            options={permissions}
            onSave={handleFilterByPermissions}
            className={styles.select}
            disabled={sections.every(s => !s.selected)}
          />
        </div>
      </PageTopContainer>
      <Table
        loading={tableState.loading}
        columns={columns}
        data={tableState.roles}
        // onExport={handleDownloadTableData}
        className={styles.table}
        pagination={tableState.pagination}
        onPaginationChange={handlePaginationChange}
      />

      <ConfirmDialog
        isOpen={deleteModal.isOpen}
        message={`Вы уверены, что хотите удалить роль "${deleteModal.roleName}"?`}
        onOk={handleDeleteRole}
        onCancel={handleCloseDeleteModal}
        loading={deleteModal.loading}
      />

      <Modal
        centered
        isOpen={editModal.isOpen}
        onClose={handleCloseEditModal}
        className={styles.modal}
      >
        <RoleSwitcher
          title={`Редактирование роли ${editModal.roleName}`}
          onSubmit={handleSubmitEditRole}
          initialValues={editModal.initialValues}
        />
      </Modal>
    </div>
  )
}
