import { useState, useEffect, useRef } from 'react'
import clsx from 'clsx'
import useOnClickOutside from '../../hooks/useOnClickOutside'
import DropdownContainer from '../DropdownContainer'
import Checkbox from '../Checkbox'
import Button from '../Button'
import triangle from '../../icons/triangle.svg'
import styles from './styles.module.scss'

export default function Select(props) {
  const {
    text,
    options,
    value,
    onSave,
    className,
    errorMessage,
    disabled,
    single,
    hidden,
      required = false,
    nothingFoundText,
    ...rest
  } = props

  const [encOptions, setEncOptions] = useState(
    options.map(option => ({
      ...option,

      selected: value ? value?.includes(option.id) : false
    }))
  )

  useEffect(() => {
    setEncOptions(
      single
        ? options.map(option => ({ ...option, selected: option.id === value }))
        : options.map(option => ({
            ...option,
            selected: value ? value?.includes(option.id) : false
          }))
    )
  }, [options, value, single])

  const [isOpen, setOpen] = useState(false)

  const ref = useRef()

  const handleOpen = () => {
    if (!disabled) {
      setEncOptions(options.map(o => ({ ...o })))
      setOpen(!isOpen)
    }
  }

  const handleClose = () => {
    setOpen(false)
    setEncOptions(options.map(o => ({ ...o })))
  }

  const handleSaveSelection = () => {
    onSave(encOptions.map(o => ({ ...o })))
    setOpen(false)
  }

  const handleSelectValue = v => () => {
    const newOptions = encOptions.map(o => ({ ...o }))
    const option = newOptions.find(o => o.id === v.id)

    if (single) {
      newOptions.forEach(o => (o.selected = false))
    }

    option.selected = !v.selected

    setEncOptions(newOptions)
  }

  useOnClickOutside(ref, handleClose)

  return (
    <div
      ref={ref}
      className={clsx(
        styles.container,
        hidden && styles.hidden,
        disabled && styles.disabled,
        className
      )}
      {...rest}
    >
      <button type="button" onClick={handleOpen} className={styles.button}>
        {required && (<span style={{color: "red"}}> *</span>)} {text}
        <img
          className={clsx(styles.triangle, isOpen && styles.rotated)}
          src={triangle}
          alt=""
        />
        {options.some(o => o.selected) && <span className={styles.indicator} />}
      </button>
      <DropdownContainer isShown={isOpen}>
        <div className={styles.listContainer}>
          {/* <div className={styles.header}>
            <button
              className={styles.close}
              onClick={handleClose}
            >
              <img src={triangle} alt='' />
            </button>
          </div> */}
          <div className={styles.optionsContainer}>
            <ul className={styles.list}>
              {!encOptions.length && (
                <div>{nothingFoundText ? nothingFoundText : 'Не найдено'}</div>
              )}
              {encOptions.map(v => (
                <li key={v.id} className={styles.option}>
                  <Checkbox
                    label={v.label}
                    checked={v.selected}
                    onChange={handleSelectValue(v)}
                  />
                </li>
              ))}
            </ul>
            <Button className={styles.accept} onClick={handleSaveSelection}>
              Применить
            </Button>
          </div>
        </div>
      </DropdownContainer>
      {errorMessage && <p className={styles.error}>{errorMessage}</p>}
    </div>
  )
}
