import clsx from 'clsx'
import styles from './styles.module.scss'
import InputMask from 'react-input-mask'

const InputMaskField = props => {
  const { label, errors, value, onChange, mask, className, ...rest } = props

  return (
    <div className={clsx(styles.maskInput, className)}>
      <div className={styles.inputLabelContainer}>
        <label htmlFor="shelfLife">{label}</label>
        <InputMask mask={mask} value={value} onChange={onChange} type="text" {...rest} />
      </div>

      <div className={styles.errorContainer}>
        <span>{errors}</span>
      </div>
    </div>
  )
}

export default InputMaskField
