import { useMemo, useState, useEffect, useCallback } from 'react'
import Table from '../../../components/Table'
import XLSX from 'xlsx'
import PageTopContainer from '../../../components/PageTopContainer'
import PageLoader from '../../../components/PageLoader'
import Button from '../../../components/Button'
import axios from '../../../utils/axios'
import { useHideSearch } from '../../../hooks/useContext'
import styles from './styles.module.scss'
import GoBackButton from '../../../components/GoBackButton'

export default function DealersNomenclatures(props) {
  const { setHidden } = useHideSearch()

  useEffect(() => {
    setHidden(true)
    return () => setHidden(false)
  }, [setHidden])

  const [tableState, setTableState] = useState({
    loading: false,
    pagination: {
      pageCount: 1,
      pageNum: 1,
      rowCount: 10
    },
    nomenclatures: [],
    dealer: null
  })

  const getDealersNomenclatures = useCallback(async () => {
    setTableState(s => ({ ...s, loading: true }))
    const { dealer, products } = await axios.get(
      `/nomenclature/dealer/products/${props.match.params.uid}`
    )
    setTableState(s => ({ ...s, loading: false, dealer, nomenclatures: products }))
  }, [props.match.params.uid])

  useEffect(() => {
    getDealersNomenclatures()
  }, [getDealersNomenclatures])

  const handleUpdateActive = (uid, isActive) => async () => {
    await axios.put(`nomenclature/product/archive/${uid}`, null, {
      params: {
        isActive: !isActive
      }
    })
    await getDealersNomenclatures()
  }

  const handleArchive = (uid, isActive) => async () => {
    await axios.put(`/nomenclature/dealer/archive/${uid}`, null, {
      params: {
        isActive: !isActive
      }
    })
    await getDealersNomenclatures()
  }

  let isActive = tableState?.dealer?.active || false

  const columns = useMemo(
    () => [
      {
        Header: 'Категория товара',
        accessor: 'category.name',
        disableSortBy: true
        // Cell: ({ value }) => {
        //   return value
        // }
      },
      {
        Header: 'Наименование',
        accessor: 'name',
        disableSortBy: true
      },
      {
        Header: 'Единица измерения',
        accessor: 'weight',
        disableSortBy: true
      },
      {
        Header: '',
        accessor: 'uid',
        disableSortBy: true,
        Cell: ({ row }) => (
          <Button
            white
            disabled={!isActive}
            onClick={handleUpdateActive(row.original.uid, row.original.active)}
            className={styles.archiveButton}
          >
            {row.original.active === true ? 'Отправить в архив' : 'Вернуть из архива'}
          </Button>
        )
      }
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isActive]
  )

  const handlePaginationChange = pagination => {
    setTableState(s => ({
      ...s,
      pagination: {
        ...s.pagination,
        ...pagination
      }
    }))
  }

  const handleDownloadTableData = () => {
    const ws = XLSX.utils.table_to_sheet(
      document.getElementById('table-dealers-nomenclatures')
    )

    const entries = Object.entries(ws)

    const columnLetter = entries
      .find(([key, value]) => value.v === 'Отправить в архив')[0]
      .charAt(0)
    for (const [key] of entries) {
      if (key.startsWith(columnLetter)) {
        delete ws[key]
      }
    }

    const cols = [{ wch: 25 }, { wch: 25 }, { wch: 25 }]
    ws['!cols'] = cols

    const wb = XLSX.utils.book_new()

    XLSX.utils.book_append_sheet(wb, ws, 'Номенклатуры')
    XLSX.writeFile(
      wb,
      `Номеклатуры дилера ${
        tableState.dealer.visibleName
      } ${new Date().toLocaleDateString('ru-RU')}.xlsx`
    )
  }

  return (
    <div>
      <PageLoader loading={!tableState.dealer}>
        <PageTopContainer>
          <div className={styles.firstRow}>
            <div style={{ display: 'flex' }}>
              <GoBackButton />
              <h1>
                Номенклатура дилера {tableState.dealer?.visibleName}{' '}
                {tableState.dealer?.active ? '' : '(В архиве)'}
              </h1>
            </div>
            <Button
              onClick={handleArchive(tableState.dealer?.uid, tableState.dealer?.active)}
              style={{ width: 180 }}
            >
              {tableState.dealer?.active ? 'Отправить в архив' : 'Вернуть из архива'}
            </Button>
          </div>
          <div className={styles.secondRow}>
            <p>
              <span className={styles.bold}>Телефон: </span>
              {tableState.dealer?.phone || '-'}
            </p>
            <p>
              <span className={styles.bold}>Электронный адрес: </span>
              {tableState.dealer?.email || '-'}
            </p>
          </div>
        </PageTopContainer>
        <Table
          id="table-dealers-nomenclatures"
          loading={tableState.loading}
          data={tableState.nomenclatures}
          columns={columns}
          className={styles.table}
          pagination={tableState.pagination}
          onPaginationChange={handlePaginationChange}
          onExport={handleDownloadTableData}
        />
      </PageLoader>
    </div>
  )
}
