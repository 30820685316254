import axios from 'axios'
import { toast } from 'react-toastify'
import qs from 'qs'

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  timeout: 60000,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  },
  paramsSerializer: params => {
    return qs.stringify(params, { arrayFormat: 'repeat' })
  }
})

axiosInstance.interceptors.response.use(
  res => {
    const { data } = res

    const { code, info } = data

    if (code === 0) {
      if (res.config.toast) {
        toast.success(info, { toastId: 0 })
      }
    } else {
      if (info) {
        toast.error(info, { toastId: 1 })
      }
      return Promise.reject(data)
    }

    if (res.config.withCode) {
      return Promise.resolve(data)
    }

    return Promise.resolve(data.data)
  },
  err => {
    const res = err.response

    toast.error(
      res ? res?.data?.info || 'Ошибка выполнения запроса' : 'Ошибка соединения',
      { toastId: 2 }
    )

    return Promise.reject(err)
  }
)

axiosInstance.interceptors.request.use(config => {
  const token = localStorage.getItem('token')
  config.headers.Authorization = `Bearer ${token}`
  return config
})

export default axiosInstance
