import { useMemo, useState, useEffect, useCallback } from 'react'
import { useHistory } from 'react-router'
import ConditionalRender from '../../../components/ConditionalRender'
import PageTopContainer from '../../../components/PageTopContainer'
import AddButton from '../../../components/AddButton'
import Calendar from '../../../components/Calendar'
import Checkbox from '../../../components/Checkbox'
import Table from '../../../components/Table'
import BannerModal from './BannerModal'
import { parseWorkingDays } from '../../../utils/parseWorkingDays'
import formatDate from '../../../utils/formatDate'
import axios from '../../../utils/axios'
import { useSearch } from '../../../hooks/useContext'
import styles from './styles.module.scss'

const Banners = () => {
  const { value } = useSearch()

  const history = useHistory()

  const [banners, setBanners] = useState({
    data: [],
    loading: true,
    pagination: {
      rowCount: 10,
      pageNum: 1,
      pageCount: 1
    },
    filters: {
      active: false,
      dateStart: '',
      dateEnd: ''
    }
  })

  const [addModalOpen, setAddModalOpen] = useState(false)

  const getBanners = useCallback(async () => {
    let data, pagination
    setBanners(b => ({ ...b, loading: true }))

    try {
      const { active, dateStart, dateEnd } = banners.filters

      const filtering = []
      if (dateStart && dateEnd) {
        filtering.push({
          FieldName: 'DateCreate',
          ValueDateBeg: dateStart ? dateStart.toISOString() : '',
          ValueDateEnd: dateEnd ? dateEnd.toISOString() : ''
        })
      }

      if (active) {
        filtering.push({
          FieldName: 'active',
          ValueString: 'true'
        })
      }

      if (value) {
        filtering.push(
          { FieldName: "Text", ValueString: value },
        )
      }

      const res = await axios.post('promotion/banners', {
        Pagination: {
          RowCount: banners.pagination.rowCount,
          PageNum: banners.pagination.pageNum,
          PageCount: banners.pagination.pageCount
        },
        Filtering: filtering,
        Sorting: []
      })

      if (res.banner) {
        pagination = res.pagination
        data = res.banner.map(b => {
          let colorIndicator
          switch (b.active) {
            case true:
              colorIndicator = '#B2EEC2'
              break
            case false:
              colorIndicator = '#E17D7D'
              break
            default:
              colorIndicator = '#B2EEC2'
          }

          return { ...b, colorIndicator }
        })
      }
    } catch (e) {
      console.log(e)
    } finally {
      setBanners(s => ({ ...s, data: data ?? s.data, loading: false, pagination }))
    }
  }, [
    value,
    banners?.pagination?.rowCount,
    banners?.pagination?.pageNum,
    banners?.pagination?.pageCount,
    banners?.filters
  ])

  useEffect(() => {
    getBanners()
  }, [getBanners])

  const сolumns = useMemo(
    () => [
      {
        Header: 'Название',
        accessor: 'title',
        disableSortBy: true
      },
      {
        Header: 'Дата создания',
        accessor: 'dateCreate',
        disableSortBy: true,
        Cell: ({ value }) => formatDate(value)
      },
      {
        Header: 'Время показа',
        accessor: 'scheduleDays',
        disableSortBy: true,
        Cell: ({ value, row }) => {
          const parsed = parseWorkingDays(value)
          const days = parsed.every(d => d.active !== '0')
            ? 'Ежедневно'
            : parsed
                .flatMap(item => (item.active === '0' ? [] : [item.shortTitle]))
                .join(', ')
          return (
            <div>
              {row.original.schedulePeriod === 1
                ? `Еженедельно \u2013 ${days}`
                : `Кажд. ${row.original.schedulePeriod} нед. \u2013 ${days}`}
            </div>
          )
        }
      },
      {
        Header: 'Кому показывать',
        accessor: 'schools',
        disableSortBy: true,
        Cell: ({ value, row }) => {
          if (row.original.showToAll === false) {
            return (
              <div>
                {value
                  .map(item => item.name)
                  .join(', ')
                  .slice(0, 15)}
                ...
              </div>
            )
          } else {
            return <div>Всем</div>
          }
        }
      },
      {
        Header: '',
        accessor: 'colorIndicator',
        disableSortBy: true
      }
    ],
    []
  )

  const handleClickAddBanner = () => {
    setAddModalOpen(!addModalOpen)
  }

  const handlePaginationChange = p => {
    setBanners({ ...banners, pagination: { ...banners.pagination, ...p } })
  }

  const handleChangeCalendar = value => {
    const { startDate, endDate } = value
    setBanners({
      ...banners,
      filters: { ...banners.filters, dateStart: startDate, dateEnd: endDate }
    })
  }

  const handleChangeCheckbox = () => {
    setBanners({
      ...banners,
      filters: { ...banners.filters, active: !banners.filters.active }
    })
  }

  const handleClickRow = row => () => {
    history.push(`/promotions/banners/${row.original.uid}`)
  }

  const onModalClose = () => {
    setAddModalOpen(false)
    getBanners()
  }

  return (
    <div>
      <PageTopContainer>
        <div className={styles.firstRow}>
          <div>
            <h1>Акции</h1>
            <span>Рекламные баннеры</span>
          </div>
          <ConditionalRender roles={['dealer', 'dealerEmployee']}>
            <AddButton
              label="Добавить акцию"
              isOpen={addModalOpen}
              onClick={handleClickAddBanner}
              onClose={handleClickAddBanner}
            >
              <BannerModal onCloseEvent={onModalClose} />
            </AddButton>
          </ConditionalRender>
        </div>
        <div className={styles.secondRow}>
          <Calendar
            name="dateDelivery"
            text="Период создания"
            onSave={handleChangeCalendar}
          />
          <div className={styles.checkboxContainer}>
            <Checkbox
              label="Только активные банеры"
              className={styles.checkbox}
              onChange={handleChangeCheckbox}
              checked={banners.filters.active}
            />
          </div>
        </div>
      </PageTopContainer>
      <Table
        loading={banners.loading}
        data={banners.data}
        onClick={handleClickRow}
        columns={сolumns}
        pagination={banners.pagination}
        onPaginationChange={handlePaginationChange}
        className={styles.table}
      />
    </div>
  )
}

export default Banners
