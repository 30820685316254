import styles from './styles.module.scss'
import clsx from 'clsx'

export default function Checkbox(props) {
  const { label, checked, onChange, labelPositionLeft, className, ...rest } = props

  return (
    <label
      className={clsx(
        styles.container,
        className,
        labelPositionLeft ? styles.right : styles.left
      )}
    >
      {label}
      <input
        {...rest}
        type="checkbox"
        className={styles.input}
        checked={checked}
        onChange={onChange}
      />
      <span
        className={clsx(
          styles.checkmark,
          labelPositionLeft ? styles.checkRight : styles.checkLeft
        )}
      />
    </label>
  )
}
