import { useRef } from 'react'
import Button from '../Button'
import Fade from '../Fade'
import useOnClickOutside from '../../hooks/useOnClickOutside'
import plus from '../../icons/plus.svg'
import closeThick from '../../icons/close-thick.svg'
import styles from './styles.module.scss'
import clsx from 'clsx'

export default function AddButton(props) {
  const { children, onClose, label, isOpen, className, onClick } = props

  const ref = useRef()

  useOnClickOutside(ref, onClose)

  return (
    <div className={clsx(styles.container, className)}>
      <label className={styles.label}>{label}</label>
      <Button onClick={onClick} className={styles.button} disabled={isOpen}>
        <img src={plus} alt="" />
      </Button>
      {children && (
        <Fade isShown={isOpen}>
          <div ref={ref} className={styles.popup}>
            <div className={styles.head}>
              <button onClick={onClose} className={styles.close}>
                <img src={closeThick} alt="" />
              </button>
            </div>
            <div className={styles.content}>{children}</div>
          </div>
        </Fade>
      )}
    </div>
  )
}
