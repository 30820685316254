import './styles.scss'
import Button from '../Button'
import { useEffect, useState } from 'react'
import axios from '../../utils/axios'
import ProductItem from './components/ProductItem'
import { cellObjToCellNumber } from '../../utils/helper'

export default function ProductsSelectedList(props) {
  const {
    selectedCells,
    clear,
    onDeleteProduct,
    preparedToDelete,
    onAmountChange,
    changedAmounts
  } = props
  const [selectedCellsList, setSelectedCellsList] = useState([])

  const fetchProductOnCell = async () => {
    try {
      const res = await axios.post('kiosk/cell', {
        Cells: selectedCells.map(item => item.uid)
      })

      setSelectedCellsList(
        res
          .flatMap(cell =>
            !preparedToDelete.includes(cell.uid)
              ? {
                  normalNumber: cellObjToCellNumber(cell),
                  ...cell
                }
              : []
          )
          .sort((a, b) => Number(a.normalNumber) - Number(b.normalNumber))
      )
    } catch {
      /* isEmpty */
    }
  }

  useEffect(() => {
    fetchProductOnCell()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCells])

  useEffect(() => {
    setSelectedCellsList(
      selectedCellsList
        .flatMap(cell =>
          !preparedToDelete.includes(cell.uid)
            ? {
                normalNumber: cellObjToCellNumber(cell),
                ...cell
              }
            : []
        )
        .sort((a, b) => Number(a.normalNumber) - Number(b.normalNumber))
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onDeleteProduct])

  const onDeleteProductHandler = value => {
    onDeleteProduct(value)
  }

  const changedAmount = spring => {
    const index = changedAmounts.findIndex(item => item.SpringUid === spring)
    if (index !== -1) {
      return changedAmounts[index].Quantity
    } else {
      return false
    }
  }

  return (
    <>
      <h2>Товары в выбранных ячейках</h2>
      <ul className="productContainer">
        {selectedCellsList.map((product, index) => (
          <ProductItem
            zIndexKey={index}
            product={product}
            springUid={product.springUid}
            key={`${product.shelfId}${product.springId}-${index}`}
            numberCell={
              product.springId >= 10
                ? `${product.shelfId}${product.springId}`
                : `${product.shelfId}0${product.springId}`
            }
            imageProduct={product.image}
            nameProduct={product.name}
            amountProduct={
              changedAmount(product.springUid)
                ? changedAmount(product.springUid)
                : product.quantity
            }
            priceProduct={product.price}
            onDelete={onDeleteProductHandler}
            onAmountChange={onAmountChange}
            soldQuantity={product.soldQuantity}
          />
        ))}
      </ul>
      <Button onClick={clear} className="productButton">
        Заменить товары
      </Button>
    </>
  )
}
