import styles from './styles.module.scss'
import clsx from 'clsx'

export default function SwitchButton(props) {
  const { checked, onChange, onLabel, offLabel, id, className, disabled, ...rest } = props

  return (
    <div className={clsx(styles.container, className)} {...rest}>
      {offLabel && <label className={clsx(styles.state, styles.left)}>{offLabel}</label>}
      <input
        type="checkbox"
        id={id}
        className={styles.switch}
        checked={checked}
        onChange={onChange}
        disabled={disabled}
      />
      <label htmlFor={id} className={styles.label} />
      {onLabel && <label className={clsx(styles.state, styles.right)}>{onLabel}</label>}
    </div>
  )
}
