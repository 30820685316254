import { useState, useMemo, useEffect, useCallback } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import { useHistory } from 'react-router'
import PageTopContainer from '../../components/PageTopContainer'
import Table from '../../components/Table'
import Select from '../../components/Select'
import SelectSingle from '../../components/SelectSingle'
import IconButton from '../../components/IconButton'
import AddButton from '../../components/AddButton'
import SwitchButton from '../../components/SwitchButton'
import TextInput from '../../components/TextInput'
import Button from '../../components/Button'
import { useSearch } from '../../hooks/useContext'
import ConfirmDialog from '../../components/ConfirmDialog'
import ConditionalRender from '../../components/ConditionalRender'
import axios from '../../utils/axios'
import deserializeList from '../../utils/deserializeList'
import XLSX from 'xlsx'
import edit from '../../icons/edit.svg'
import arrow from '../../icons/arrow-back.svg'
import styles from './styles.module.scss'
import Modal from '../../components/Modal'

function ProfileForm(props) {
  const { title, roles, onSubmit, initialValues, readOnly } = props

  const history = useHistory()

  const formik = useFormik({
    initialValues: initialValues
      ? initialValues
      : {
          name: '',
          position: '',
          phoneNumber: '',
          email: '',
          role: null,
          active: true,
        },
    validationSchema: Yup.object({
      name: Yup.string().required('ФИО не может быть пустым'),
      position: Yup.string(),
      role: Yup.object().typeError('Роль не может быть пустой'),
      phoneNumber: Yup.string()
        .when('role', {
          is: x => x?.id === '77667e62-dd2c-479d-ab4b-b7407d8751c8',
          then: Yup.string().required('Номер не может быть пустым')
        })
        .matches(
          /^((8|\+7)[- ]?)?(\(?\d{3}\)?[- ]?)?[\d\- ]{7,10}$/,
          'Неверный номер телефона'
        ),
      email: Yup.string()
        .email('Указан невалидный Email')
        .required('Email не может быть пустым'),
      active: Yup.boolean()
    }),
    onSubmit
  })

  const handleFormRoleChange = role => {
    formik.setFieldValue('role', role)
  }

  const handleFormActiveChange = e => {
    formik.setFieldValue('active', e.target.checked)
  }

  const handleGoToPurhaseHistory = () => {
    const { uid, name, phoneNumber } = initialValues
    history.push('/purchase-history&pageNum=1&rowCount=10', {
      selectedUser: { uid, name, phoneNumber }
    })
  }

  const handleGoToRechargeHistory = () => {
    history.push('/users/recharge-history', { clientUid: initialValues.uid })
  }

  const indexDealerRole = roles.findIndex(
    x => x.id === '22ddccbe-b8ce-480b-a19a-7d96b38591a1'
  )
  if (indexDealerRole !== -1) {
    roles.splice(indexDealerRole, 1)
  }

  return (
    <>
      <h3 className={styles.title}>{title}</h3>
      <form className={styles.form} onSubmit={formik.handleSubmit}>
        <div className={styles.formFirstRow}>
          <SelectSingle
            text="Роль"
            options={roles}
            value={formik.values.role?.id}
            className={styles.roleSelect}
            onChange={handleFormRoleChange}
            errorMessage={formik.touched.role && formik.errors.role}
            disabled={readOnly}
          />
          <SwitchButton
            id="activitySwitch"
            offLabel="Неактивен"
            onLabel="Активен"
            checked={formik.values.active}
            onChange={handleFormActiveChange}
            disabled={readOnly}
          />
        </div>
        <div>
          <TextInput
            placeholder="ФИО"
            label="ФИО"
            className={styles.input}
            errorMessage={formik.touched.name && formik.errors.name}
            disabled={readOnly}
            {...formik.getFieldProps('name')}
          />
          <TextInput
            placeholder="Должность"
            label="Должность"
            className={styles.input}
            errorMessage={formik.touched.position && formik.errors.position}
            disabled={readOnly}
            {...formik.getFieldProps('position')}
          />
        </div>
        <div>
          <TextInput
            placeholder="Номер"
            label="Номер телефона"
            className={styles.input}
            errorMessage={formik.touched.phoneNumber && formik.errors.phoneNumber}
            disabled={readOnly}
            {...formik.getFieldProps('phoneNumber')}
          />
          <TextInput
            placeholder="Почтовый адрес"
            label="E-mail"
            className={styles.input}
            errorMessage={formik.touched.email && formik.errors.email}
            disabled={readOnly}
            {...formik.getFieldProps('email')}
          />
        </div>
        {!readOnly ? (
          <Button type="submit" className={styles.button} loading={formik.isSubmitting}>
            Сохранить
          </Button>
        ) : (
          <div className={styles.readOnlyButtons}>
            <Button className={styles.readOnlyButton} onClick={handleGoToPurhaseHistory}>
              История покупок
              <img src={arrow} alt="" />
            </Button>
            {/*<Button*/}
            {/*  className={clsx(styles.readOnlyButton, styles.wideReadOnlyButton)}*/}
            {/*  onClick={handleGoToRechargeHistory}*/}
            {/*>*/}
            {/*  История пополнений*/}
            {/*  <img src={arrow} alt="" />*/}
            {/*</Button>*/}
          </div>
        )}
      </form>
    </>
  )
}

export default function Profiles() {
  const {
    location: { state }
  } = useHistory()
  const [sections, setSections] = useState([])
  const [dealers, setDealers] = useState([])
  const [permissions, setPermissions] = useState([])
  const [roles, setRoles] = useState([])
  const [isAddOpen, setAddOpen] = useState(false)
  const { value } = useSearch()

  const [tableState, setTableState] = useState({
    loading: false,
    pagination: {
      pageCount: 1,
      pageNum: 1,
      rowCount: 10
    },
    sections: [],
    permissions: [],
    users: [],
    dealer: state?.dealer || []
  })

  const [editModal, setEditModal] = useState({
    isOpen: false,
    profileName: '',
    profileId: ''
  })

  const [readModal, setReadModal] = useState({
    isOpen: false,
    profileName: '',
    profileId: ''
  })

  const handleCloseEditModal = () => {
    setEditModal({
      ...editModal,
      isOpen: false
    })
  }

  const handleCloseReadModal = () => {
    setReadModal({ ...readModal, isOpen: false })
  }

  const handleOpenAddModal = () => setAddOpen(true)

  const handleCloseAddModal = () => setAddOpen(false)

  const getUsers = useCallback(
    async props => {
      setTableState(s => ({ ...s, loading: true }))

      let params = {}

      if (props) {
        params.dealerUid = props.dealer.uid
      } else {
        params = {
          text: value,
          'Pagination.RowCount': tableState.pagination.rowCount,
          'Pagination.PageNum': tableState.pagination.pageNum,
          sections: tableState.sections,
          access: tableState.permissions,
          dealerUid: tableState.dealer
        }
      }

      const res = await axios.get('/dealer/list', {
        params
      })

      const { pagination, users } = res

      users.forEach(u => {
        u.colorIndicator = u.profileActive ? '#B2EEC2' : '#E17D7D'
      })

      setTableState(s => ({
        ...s,
        pagination,
        users,
        loading: false
      }))
    },
    [
      value,
      tableState.pagination.pageNum,
      tableState.pagination.rowCount,
      tableState.sections,
      tableState.permissions,
      tableState.dealer
    ]
  )

  const getSections = useCallback(async () => {
    const res = await axios.get('/dealer/sections')

    const sections = deserializeList(res.sections)

    setSections(sections)
  }, [])

  const getPermissions = useCallback(async () => {
    const res = await axios.get('/dealer/access')

    const permissions = deserializeList(res.access)

    setPermissions(permissions)
  }, [])

  const getRoles = useCallback(async () => {
    const res = await axios.get('/dealer/roles')

    const roles = res.roles.map(r => ({
      id: r.uid,
      label: r.name
    }))

    setRoles(roles)
  }, [])

  useEffect(() => {
    getSections()
    getPermissions()
    getRoles()
  }, [getSections, getPermissions, getRoles])

  const handleEditProfile = profile => e => {
    e.stopPropagation()
    setEditModal({
      isOpen: true,
      profileId: profile.uid,
      profileName: profile.visibleName,
      initialValues: {
        name: profile.visibleName ?? '',
        position: profile.position ?? '',
        phoneNumber: profile.phone ?? '',
        email: profile.email ?? '',
        role: roles.find(r => r.label === profile.role[0]?.name),
        active: profile.profileActive,
        bik: profile.bik ?? '',
        accountNumber: profile.accountNumber ?? '',
        inn: profile.inn ?? '',
        kpp: profile.kpp ?? '',
        addressName: profile.addressName ?? '',
        taxSystemType: profile.taxSystemType?.id ?? { id: '1' },
        orangeDataCabinetTypeEnum: profile.orangeDataCabinetTypeEnum?.id ?? { id: '1' }
      }
    })
  }

  const [restorePasswordModal, setRestorePasswordModal] = useState({
    isOpen: false,
    userId: '',
    userName: '',
    loading: false
  })

  console.log(restorePasswordModal)

  const handleRestorePassword = async e => {
    try {
      setRestorePasswordModal(s => ({ ...s, loading: true }))
      await axios.put(
        `/dealer/reset-password/${restorePasswordModal.userId}`,
        {},
        { toast: true }
      )
      handleCloseRestorePasswordModal()
    } catch (error) {
      setRestorePasswordModal(s => ({ ...s, isOpen: false, loading: false }))
    }
  }

  const handleCloseRestorePasswordModal = () => {
    setRestorePasswordModal(s => ({
      ...s,
      isOpen: false,
      loading: false
    }))
    handleCloseReadModal()
    handleCloseEditModal()
  }

  const handleOpenRestorePasswordModal = (id, name) => e => {
    e.stopPropagation()

    setRestorePasswordModal({
      isOpen: true,
      userId: id,
      userName: name,
      loading: false
    })
  }

  const columns = useMemo(
    () => [
      {
        Header: 'ФИО',
        accessor: 'visibleName',
        disableSortBy: true
      },
      {
        Header: 'Должность',
        accessor: 'position',
        disableSortBy: true
      },
      {
        Header: 'Телефон',
        accessor: 'phone',
        disableSortBy: true
      },
      {
        Header: 'Электронная почта',
        accessor: 'email',
        disableSortBy: true
      },
      {
        Header: 'Роль',
        accessor: 'role',
        disableSortBy: true,
        Cell: ({ value }) => value?.name || '-'
      },
      {
        Header: '',
        accessor: 'edit',
        disableSortBy: true,
        Cell: ({ row }) => (
          <ConditionalRender authKey="allow-user-user-update">
            <IconButton icon={edit} onClick={handleEditProfile(row.original)} />
          </ConditionalRender>
        )
      },
      {
        Header: '',
        accessor: 'restore',
        disableSortBy: true,
        Cell: ({ row }) => (
          <ConditionalRender authKey="allow-user-user-update">
            <Button
              white
              className={styles.restoreButton}
              onClick={handleOpenRestorePasswordModal(
                row.original.uid,
                row.original.visibleName
              )}
            >
              Сбросить пароль
            </Button>
          </ConditionalRender>
        )
      },
      {
        Header: '',
        accessor: 'colorIndicator',
        disableSortBy: true
      }
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [roles]
  )

  const handleDownloadTableData = () => {
    const ws = XLSX.utils.table_to_sheet(document.getElementById('table-profiles'))

    const entries = Object.entries(ws)

    const columnLetter = entries
      .find(([key, value]) => value.v === 'Сбросить пароль')[0]
      .charAt(0)
    for (const [key] of entries) {
      if (key.startsWith(columnLetter)) {
        delete ws[key]
      }
    }

    const cols = [{ wch: 25 }, { wch: 13 }, { wch: 15 }, { wch: 20 }, { wch: 20 }]
    ws['!cols'] = cols

    const wb = XLSX.utils.book_new()

    XLSX.utils.book_append_sheet(wb, ws, 'Учётные записи')
    XLSX.writeFile(wb, `Учётные записи ${new Date().toLocaleDateString('ru-RU')}.xlsx`)
  }

  const handleFilterBySections = values => {
    const codes = values.flatMap(s => (s.selected ? [s.id] : []))

    const isEmpty = codes.length === 0

    if (isEmpty) {
      const defaults = permissions.map(p => ({ ...p, selected: false }))
      setPermissions(defaults)
    }

    setTableState(s => ({
      ...s,
      sections: codes
    }))

    setSections(values)
  }

  const handleFilterByPermissions = values => {
    const codes = values.flatMap(s => (s.selected ? [s.id] : []))

    setTableState(s => ({
      ...s,
      permissions: codes
    }))

    setPermissions(values)
  }

  const serializeProfilePayload = values => ({
    email: values.email,
    phone: values.phoneNumber,
    visibleName: values.name,
    position: values.position,
    role: values.role.id,
    profileActive: values.active,
    bik: values.bik,
    accountNumber: values.accountNumber,
    inn: values.inn,
    kpp: values.kpp,
    addressName: values.addressName,
    taxSystemType: values.taxSystemType?.id,
    orangeDataCabinetTypeEnum: values.orangeDataCabinetTypeEnum?.id
  })

  const handleSubmitProfile = async values => {
    const payload = serializeProfilePayload(values)

    await axios.post('/dealer/employee', payload, { toast: true })
    getUsers()
    handleCloseAddModal()
  }

  const handleSubmitEdit = async values => {
    const payload = serializeProfilePayload(values)

    await axios.put(`/dealer/employee/${editModal.profileId}`, payload, { toast: true })
    getUsers()
    setEditModal(s => ({ ...s, isOpen: false }))
  }

  useEffect(() => {
    getUsers(state)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getUsers])

  const handlePaginationChange = pagination => {
    setTableState(s => ({
      ...s,
      pagination: {
        ...tableState.pagination,
        ...pagination
      }
    }))
  }

  const handleClickRow = value => () => {
    const profile = value.original
    setReadModal({
      isOpen: true,
      profileId: profile.uid,
      profileName: profile.visibleName,
      initialValues: {
        uid: profile.uid,
        name: profile.visibleName ?? '',
        position: profile.position ?? '',
        phoneNumber: profile.phone ?? '',
        email: profile.email,
        role: roles.find(r => r.label === profile.role[0]?.name),
        active: profile.profileActive,
        bik: profile.bik ?? '',
        accountNumber: profile.accountNumber ?? '',
        inn: profile.inn ?? '',
        kpp: profile.kpp ?? '',
        addressName: profile.addressName ?? '',
        taxSystemType: profile.taxSystemType?.id ?? { id: '1' },
        orangeDataCabinetTypeEnum: profile.orangeDataCabinetTypeEnum?.id ?? { id: '1' }
      }
    })
  }

  const getDealers = useCallback(async () => {
    const res = await axios.get('/kiosk/dealers')
    const deserialized = deserializeList(res.dealers)
    if (state?.dealer) {
      const idx = deserialized.findIndex(i => i.id === state.dealer[0])
      if (idx !== -1) {
        deserialized[idx].selected = true
      }
    }
    setDealers(deserialized)
  }, [state?.dealer])

  useEffect(() => {
    getDealers()
  }, [getDealers])

  const selectedDealer = dealers.filter(dealer => dealer.selected === true)

  return (
    <div>
      <PageTopContainer>
        <div className={styles.firstRow}>
          <h1>
            Учётные записи{' '}
            {selectedDealer[0] != null && `дилера (${selectedDealer[0].label})`}
          </h1>
          <ConditionalRender authKey="allow-user-user-update">
            <AddButton
              label="Добавить запись"
              isOpen={isAddOpen}
              onClick={handleOpenAddModal}
              onClose={handleCloseAddModal}
            >
              <ProfileForm
                title="Новая учётная запись"
                onSubmit={handleSubmitProfile}
                roles={roles}
              />
            </AddButton>
          </ConditionalRender>
        </div>
        <div className={styles.secondRow}>
          {/*<Select*/}
          {/*  text="Сотрудник дилера"*/}
          {/*  options={dealers}*/}
          {/*  onSave={handleFilterByKioskDealer}*/}
          {/*  className={styles.select}*/}
          {/*/>*/}
          <Select
            text="Разделы"
            options={sections}
            onSave={handleFilterBySections}
            className={styles.select}
          />
          <Select
            text="Доступ"
            options={permissions}
            onSave={handleFilterByPermissions}
            className={styles.select}
            disabled={sections.every(s => !s.selected)}
          />
        </div>
      </PageTopContainer>
      <Table
        id="table-profiles"
        loading={tableState.loading}
        className={styles.table}
        columns={columns}
        data={tableState.users}
        onExport={handleDownloadTableData}
        pagination={tableState.pagination}
        onPaginationChange={handlePaginationChange}
        onClick={handleClickRow}
      />

      <ConfirmDialog
        isOpen={restorePasswordModal.isOpen}
        message={`Вы уверены, что хотите сбросить пароль пользователя ${restorePasswordModal.userName}?`}
        onOk={handleRestorePassword}
        onCancel={handleCloseRestorePasswordModal}
        loading={restorePasswordModal.loading}
      />

      <Modal centered isOpen={editModal.isOpen} onClose={handleCloseEditModal}>
        <ProfileForm
          title={`Редактирование пользователя ${editModal.profileName}`}
          onSubmit={handleSubmitEdit}
          initialValues={editModal.initialValues}
          roles={roles}
        />
      </Modal>

      <Modal centered isOpen={readModal.isOpen} onClose={handleCloseReadModal}>
        <ProfileForm
          title={`Пользователь ${readModal.profileName}`}
          initialValues={readModal.initialValues}
          roles={roles}
          readOnly
        />
      </Modal>
    </div>
  )
}
