import { useState, useCallback, useEffect } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import SelectSingle from '../../components/SelectSingle'
import Button from '../../components/Button'
import TextInput from '../../components/TextInput'
import KioskSchema, { defaultShelf } from '../../components/KioskSchema'
import axios from '../../utils/axios'
import deserializeList from '../../utils/deserializeList'
import styles from './styles.module.scss'
import NumberInput from '../NumberInput'
import { toast } from 'react-toastify'
import ConfirmDialog from '../ConfirmDialog'
import Checkbox from '../Checkbox'
import { validateShelves } from '../../utils/helper'

function getDefaultSchema() {
  const shelves = []
  for (let i = 0; i < 6; i++) {
    shelves.push({
      cells: defaultShelf.cells.map(c => ({ ...c }))
    })
  }
  return { shelves }
}

const defaultSchema = getDefaultSchema()

export default function KioskForm(props) {
  const {
    title,
    initialValues,
    onSubmit,
    editingMode,
    kioskLive = false,
    saveLoading
  } = props

  const [cities, setCities] = useState([])
  const [dealers, setDealers] = useState([])
  const [schools, setSchools] = useState([])

  const [schema, setSchema] = useState(initialValues?.schema || defaultSchema)

  function toastBuyCapacityErr() {
    toast.error(
      'В кол-ве товаров на человека в киоске, может быть установлено только положительное число'
    )
  }

  const toastSchemaValidationErr = shelves => {
    const nonUniqueShelves = []
    for (const shelve of shelves) {
      if (shelve.nonUnique) {
        nonUniqueShelves.push(shelve.shelve)
      }
    }
    if (nonUniqueShelves.length === 1) {
      toast.error(`На полке: ${nonUniqueShelves.join('')} повторяется номер мотора`)
    } else {
      toast.error(`На полках: ${nonUniqueShelves.join(', ')} повторяются номера моторов`)
    }
  }

  const handleSubmit = schema => values => {
    const validate = validateShelves(schema.shelves)

    if (validate === true) {
      if (values.buyCapacity <= -1) {
        toastBuyCapacityErr()
        formik.isSubmitting = false
      } else if (values.buyCapacity === 0) {
        toastBuyCapacityErr()
        formik.isSubmitting = false
      } else {
        console.log(values)
        onSubmit(values, schema)
      }
    } else {
      toastSchemaValidationErr(validate)
      formik.isSubmitting = false
    }
  }

  const formik = useFormik({
    initialValues: initialValues
      ? initialValues.form
      : {
          number: '',
          alias: '',
          state: null,
          city: null,
          dealer: null,
          school: null,
          buyCapacity: null,
          pinRequired: true
        },
    validationSchema: Yup.object(
      (function () {
        const schema = {
          number: Yup.number().notRequired().typeError('Необходимо ввести число'),
          alias: Yup.string().required('Наименование не может быть пустым'),
          state: Yup.object().typeError('Необходимо выбрать статус'),
          city: Yup.object().typeError('Необходимо выбрать город'),
          school: Yup.object().typeError('Необходимо выбрать школу')
        }

        if (!editingMode) {
          schema.dealer = Yup.object().typeError('Необходимо выбрать дилера')
        }

        return schema
      })()
    ),
    onSubmit: handleSubmit(schema)
  })

  const getDealers = useCallback(async () => {
    const res = await axios.get('/kiosk/dealers')
    const dealers = deserializeList(res.dealers)
    setDealers(dealers)
  }, [])

  const getCities = useCallback(async () => {
    const res = await axios.get('/kiosk/cities')
    const cities = deserializeList(res.cities)
    setCities(cities)
  }, [])

  const getSchools = useCallback(async () => {
    const cityId = formik.values.city.id
    if (cityId) {
      const res = await axios.get(`catalogue/schools?SelectedCities=${cityId}`)
      const schools = deserializeList(res.schools)
      setSchools(schools)
    }
  }, [formik.values.city?.id])

  const [statuses, setStatuses] = useState([])

  const getStatuses = useCallback(async () => {
    const res = await axios.get('/kiosk/states')
    if (res?.states) {
      setStatuses(deserializeList(res.states))
    }
  }, [])

  useEffect(() => {
    getDealers()
    getCities()
    getStatuses()
  }, [getCities, getDealers, getStatuses])

  useEffect(() => {
    if (formik.values.city) {
      getSchools()
    }
  }, [getSchools, formik.values.city])

  const handleCityChange = city => {
    formik.setFieldValue('city', city)
    formik.setFieldValue('school', null)
  }

  const handleSchoolChange = school => {
    formik.setFieldValue('school', school)
  }

  const handleDealerChange = dealer => {
    formik.setFieldValue('dealer', dealer)
  }

  const handleStatusChange = status => {
    formik.setFieldValue('state', status)
  }

  const [addGoodsSave, setAddGoodsSave] = useState({
    isOpen: false,
    loading: false
  })

  const handleOpenAddGoods = () => {
    setAddGoodsSave({ isOpen: true, isLoading: false })
  }

  const handleCancelAddGoods = () => {
    setAddGoodsSave({ isOpen: false, isLoading: false })
  }

  return (
    <>
      <form>
        <div className={styles.formBody}>
          <div className={styles.inputsContainer}>
            <h3 className={styles.title}>{title}</h3>
            {editingMode && (
              <TextInput
                placeholder="Номер"
                disabled={true}
                label="Номер"
                errorMessage={formik.touched.number && formik.errors.number}
                className={styles.textInput}
                {...formik.getFieldProps('number')}
              />
            )}
            <TextInput
              placeholder="Наименование"
              label="Наименование"
              errorMessage={formik.touched.alias && formik.errors.alias}
              className={styles.textInput}
              {...formik.getFieldProps('alias')}
            />
            <SelectSingle
              text="Статус"
              options={statuses}
              value={formik.values.state?.id}
              errorMessage={formik.touched.state && formik.errors.state}
              onChange={handleStatusChange}
              className={styles.selectSingle}
            />
            {!editingMode && (
              <SelectSingle
                text="Дилер"
                options={dealers}
                value={formik.values.dealer?.id}
                errorMessage={formik.touched.dealer && formik.errors.dealer}
                onChange={handleDealerChange}
                className={styles.selectSingle}
              />
            )}
            <SelectSingle
              text="Город"
              options={cities}
              value={formik.values.city?.id}
              errorMessage={formik.touched.city && formik.errors.city}
              onChange={handleCityChange}
              className={styles.selectSingle}
            />
            <SelectSingle
              text="Учреждение"
              options={schools}
              value={formik.values.school?.id}
              errorMessage={formik.touched.school && formik.errors.school}
              onChange={handleSchoolChange}
              className={styles.selectSingle}
            />
            <NumberInput
              placeholder="Без ограничения"
              label="Кол-во товаров на человека в киоске"
              className={styles.textInput}
              {...formik.getFieldProps('buyCapacity')}
            />
            <Checkbox
              checked={formik.values.pinRequired}
              {...formik.getFieldProps('pinRequired')}
              label="Подтверждение личности через пин-код при покупке по школьной карте"
            />
          </div>
          <div className={styles.vendingSchemaContainer}>
            <KioskSchema
              schema={schema}
              onChange={setSchema}
              selectable
              editingMode={editingMode}
            />
          </div>
        </div>
        <ConfirmDialog
          isOpen={addGoodsSave.isOpen}
          loading={saveLoading}
          okText={'Продолжить'}
          okGray
          okType={'submit'}
          message={`При редактирование схемы киоска все остатки товаров обнулятся. Продолжить?`}
          onOk={formik.handleSubmit}
          onCancel={handleCancelAddGoods}
        />
        <div className={styles.formBottom}>
          <Button
            onClick={kioskLive ? handleOpenAddGoods : formik.handleSubmit}
            className={styles.submitButton}
          >
            Сохранить
          </Button>
        </div>
      </form>
    </>
  )
}
