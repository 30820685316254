import { useState } from 'react'
import clsx from 'clsx'
import Fade from '../Fade'
import styles from './styles.module.scss'

export default function Tooltip(props) {
  const { content, leftSide, children } = props

  const [isOpen, setOpen] = useState(false)

  const handleOpen = () => setOpen(true)

  const handleClose = () => setOpen(false)

  return (
    <div className={styles.container}>
      <div onMouseEnter={handleOpen} onMouseLeave={handleClose}>
        {children}
      </div>
      <Fade isShown={isOpen}>
        <div className={clsx(styles.tooltip, leftSide && styles.leftSide)}>{content}</div>
      </Fade>
    </div>
  )
}
