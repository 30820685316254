import _ from 'lodash'
import moment from "moment";

export const cellObjToCellNumber = cell => {
  return Number(
    String(cell.shelfId) +
      (Number(cell.springId) < 10 ? `0${cell.springId}` : cell.springId)
  )
}

export const validateShelves = shelves => {
  const parsedShelves = []
  let valid = true

  for (let key in shelves) {
    parsedShelves.push({ shelve: Number(key) + 1, motors: [] })
    for (const cell of shelves[key].cells) {
      parsedShelves[key].motors.push(cell.motor)
    }
  }

  for (const key in parsedShelves) {
    const nonUnique = _(parsedShelves[key].motors)
      .countBy()
      .reduce((acc, val, key) => (val > 1 ? acc.concat(key) : acc), [])
      .map(_.toNumber)
    if (nonUnique.length) {
      if (valid) {
        valid = false
      }
      parsedShelves[key].nonUnique = nonUnique
    }
  }

  if (valid) {
    return true
  } else {
    return parsedShelves
  }
}


/**
 * Вернет дату исходя из текущего часового пояса
 * @param date - string
 * @param format - string like moment.format
 * @returns date in local time zone
 */
export const dateFromUTCToLocale = (
    date,
    format,
) => {
  if (!date) {
    return ""
  }
  const dateFromUTC = new Date(date + "Z")
  return format ? moment(dateFromUTC).format(format) : dateFromUTC
}