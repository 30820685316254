/* eslint-disable eqeqeq */
import { useMemo, useState, useEffect, useCallback } from 'react'
import {
  useHistory
  // useParams
} from 'react-router-dom'
import { useSearch } from '../../hooks/useContext'
import Table from '../../components/Table'
import PageTopContainer from '../../components/PageTopContainer'
import ConditionalRender from '../../components/ConditionalRender'
import Select from '../../components/Select'
import AddButton from '../../components/AddButton'
import Modal from '../../components/Modal'
import Calendar from '../../components/Calendar'
import KioskForm from '../../components/KioskForm'
import deserializeList from '../../utils/deserializeList'
import formatRuble from '../../utils/formatRuble'
import formatDate from '../../utils/formatDate'
import axios from '../../utils/axios'
import XLSX from 'xlsx'
import styles from './styles.module.scss'
import {dateFromUTCToLocale} from "../../utils/helper";

export default function Kiosks() {
  const { value } = useSearch()
  // const params = useParams()
  const {
    location: { state }
  } = useHistory()

  const [statuses, setStatuses] = useState([])
  const [dealers, setDealers] = useState([])
  const [saveLoading, setSaveLoading] = useState(false)
  // const [lastSale, setLastSale] = useState([])
  const [tableState, setTableState] = useState({
    loading: false,
    pagination: {
      pageCount: 1,
      pageNum: 1,
      rowCount: 10
    },
    status: [],
    dealer: state?.dealer || [],
    lastSale: null,
    kiosks: []
  })
  const [isAddOpen, setAddOpen] = useState(false)

  const handleOpenAddModal = () => setAddOpen(true)

  const handleCloseAddModal = () => setAddOpen(false)

  const getKiosks = useCallback(async () => {
    setTableState(s => ({ ...s, loading: true }))

    let dateStart, dateEnd
    const lastSale = tableState.lastSale
    const timeStart = lastSale?.startTime || '00:00'
    const timeEnd = lastSale?.endTime || '23:59'

    const [sh, sm] = timeStart.split(':')
    dateStart = new Date(lastSale?.startDate)
    dateStart.setHours(sh)
    dateStart.setMinutes(sm)

    const [eh, em] = timeEnd.split(':')
    dateEnd = new Date(lastSale?.endDate)
    dateEnd.setHours(eh)
    dateEnd.setMinutes(em)

    const filtering = []

    if (value) {
      filtering.push({
        FieldName: 'Text',
        ValueString: value
      })
    }

    tableState.status.forEach(s =>
      filtering.push({
        FieldName: 'State',
        ValueNumBeg: s,
        ValueNumEnd: s
      })
    )

    tableState.dealer.forEach(s =>
      filtering.push({
        FieldName: 'DealerUid',
        ValueUid: s
      })
    )

    if (dateStart && dateEnd) {
      if (lastSale?.startDate != '') {
        if (lastSale?.startTime != null) {
          filtering.push({
            FieldName: 'LastSale',
            ValueDateBeg: dateStart,
            ValueDateEnd: dateEnd
          })
        }
      }
    }

    const res = await axios.post('/kiosk/list', {
      Pagination: {
        RowCount: tableState.pagination.rowCount,
        PageNum: tableState.pagination.pageNum
      },
      Filtering: filtering
    })

    const { pagination, kiosks } = res
    setTableState(s => ({
      ...s,
      pagination,
      kiosks: kiosks.map(k => {
        let colorIndicator
        switch (k.state.name) {
          case 'Требует обслуживания':
            colorIndicator = '#FFEBAF'
            break
          case 'Работает':
            colorIndicator = '#B2EEC2'
            break
          case 'Нет связи':
            colorIndicator = '#E17D7D'
            break
          case 'На обслуживании':
            colorIndicator = '#ABC9FD'
            break
          case 'Заблокирован':
            colorIndicator = '#545863'
            break
          case 'Договор расторгнут':
            colorIndicator = '#04080F'
            break
          default:
            colorIndicator = '#fff'
        }

        return { ...k, colorIndicator }
      }),
      loading: false
    }))
  }, [
    value,
    tableState.pagination.pageNum,
    tableState.pagination.rowCount,
    tableState.dealer,
    tableState.status,
    tableState.lastSale
  ])

  const getStatuses = useCallback(async () => {
    const res = await axios.get('/kiosk/states-filter')

    const statuses = deserializeList(res.states)

    setStatuses(statuses)
  }, [])

  const getDealers = useCallback(async () => {
    const res = await axios.get('/kiosk/dealers')
    const deserialized = deserializeList(res.dealers)
    if (state?.dealer) {
      const idx = deserialized.findIndex(i => i.id === state.dealer[0])
      if (idx !== -1) {
        deserialized[idx].selected = true
      }
    }
    setDealers(deserialized)
  }, [state?.dealer])

  useEffect(() => {
    getDealers()
  }, [getDealers])

  useEffect(() => {
    getKiosks()
  }, [getKiosks])

  useEffect(() => {
    getStatuses()
  }, [getStatuses])

  const history = useHistory()

  const columns = useMemo(
    () => [
      {
        Header: 'Номер',
        accessor: 'number',
        disableSortBy: true
      },
      {
        Header: 'Киоск',
        accessor: 'alias',
        disableSortBy: true
      },
      {
        Header: 'Адрес киоска',
        accessor: 'school',
        disableSortBy: true,
        Cell: ({ value }) => value.address
      },
      {
        Header: 'Дилер',
        accessor: 'dealer.visibleName',
        disableSortBy: true
      },
      {
        Header: 'Последняя продажа',
        accessor: 'lastSale',
        disableSortBy: true,
        Cell: ({ value }) => (value ? formatDate(dateFromUTCToLocale(value)) : '-')
      },
      {
        Header: 'Ожидает выдачи',
        accessor: 'awaitingIssue',
        disableSortBy: true,
        Cell: ({ value }) => `${value.count} ед. / ${formatRuble(value.sum)}`
      },
      {
        Header: 'Остатки в продаже',
        accessor: 'remainsKiosk',
        disableSortBy: true,
        Cell: ({ value }) => `${value.count} ед. / ${formatRuble(value.sum)}`
      },
      {
        Header: 'Статус киоска',
        accessor: 'state',
        disableSortBy: true,
        Cell: ({ value }) => value.name
      },
      {
        Header: '',
        accessor: 'colorIndicator',
        disableSortBy: true
      }
    ],
    []
  )

  const handleRowClick = row => () => {
    history.push(`/kiosks/kiosk/${row.original.uid}`)
  }

  const handleDownloadTableData = () => {
    const ws = XLSX.utils.table_to_sheet(document.getElementById('table-kiosks'))

    const entries = Object.entries(ws)

    const columnLetter = entries
      .find(([key, value]) => {
        if (typeof value.v === 'string') {
          if (value.v.startsWith('#') && value.v.length === 7) {
            return [key, value]
          }
        }
        return null
      })[0]
      .charAt(0)

    for (const [key] of entries) {
      if (key.startsWith(columnLetter)) {
        delete ws[key]
      }
    }

    const cols = [
      { wch: 8 },
      { wch: 25 },
      { wch: 25 },
      { wch: 25 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 }
    ]
    ws['!cols'] = cols

    const wb = XLSX.utils.book_new()

    XLSX.utils.book_append_sheet(wb, ws, 'Киоски')
    XLSX.writeFile(wb, `Киоски ${new Date().toLocaleDateString('ru-RU')}.xlsx`)
  }

  const handleFilterByKioskDealer = values => {
    const dealer = values.flatMap(v => (v.selected ? [v.id] : []))
    setTableState(s => ({ ...s, dealer, pagination: { ...s.pagination, pageNum: 1 } }))
    setDealers(values)
  }

  const handleFilterByKioskStatus = values => {
    const status = values.flatMap(v => (v.selected ? [v.id] : []))
    setTableState(s => ({ ...s, status, pagination: { ...s.pagination, pageNum: 1 } }))
    setStatuses(values)
  }

  const handleFilterByLastSale = values => {
    setTableState(s => ({
      ...s,
      lastSale: {
        ...values
      }
    }))
  }

  const handlePaginationChange = pagination => {
    setTableState(s => ({
      ...s,
      pagination: {
        ...tableState.pagination,
        ...pagination
      }
    }))
  }

  const handleSubmitAddKiosk = async (values, schema) => {
    try {
      setSaveLoading(true)
      const payload = {
        number: +values.number,
        alias: values.alias,
        state: values.state.id,
        dealerUid: values.dealer.id,
        schoolUid: values.school.id,
        shelves: schema.shelves
      }

      await axios.post('/kiosk', payload, { toast: true })

      handleCloseAddModal()
      getKiosks()
    } catch {
      //empty
    } finally {
      setSaveLoading(false)
    }
  }

  return (
    <div>
      <PageTopContainer>
        <div className={styles.firstRow}>
          <h1>Киоски</h1>
          <ConditionalRender roles={['admin']}>
            <AddButton
              label="Добавить киоск"
              isOpen={isAddOpen}
              onClick={handleOpenAddModal}
              onClose={handleCloseAddModal}
            />
          </ConditionalRender>
        </div>
        <div className={styles.secondRow}>
          <ConditionalRender roles={'admin'}>
            <Select
              text="Дилеры"
              options={dealers}
              value={tableState.dealer}
              onSave={handleFilterByKioskDealer}
              className={styles.input}
            />
          </ConditionalRender>
          <Select
            text="Статус киоска"
            options={statuses}
            onSave={handleFilterByKioskStatus}
            className={styles.input}
          />
          <Calendar
            text="Последняя продажа"
            onSave={handleFilterByLastSale}
            className={styles.input}
            // value={tableState.lastSale}
          />
        </div>
      </PageTopContainer>
      <Table
        id="table-kiosks"
        loading={tableState.loading}
        data={tableState.kiosks}
        columns={columns}
        onClick={handleRowClick}
        onExport={handleDownloadTableData}
        pagination={tableState.pagination}
        onPaginationChange={handlePaginationChange}
      />

      <Modal
        centered
        isOpen={isAddOpen}
        onClose={handleCloseAddModal}
        className={styles.modal}
      >
        <KioskForm
          title="Новый киоск"
          saveLoading={saveLoading}
          statuses={statuses.map(s => ({ ...s, selected: false }))}
          onSubmit={handleSubmitAddKiosk}
        />
      </Modal>
    </div>
  )
}
