import { useTable } from 'react-table'
import styles from './styles.module.scss'
import Indicator from '../Indicator'
import clsx from 'clsx'
import moment from "moment";
import {dateFromUTCToLocale} from "../../utils/helper";

export default function CardTable(props) {
  const { data, footer, columns, event, icon, ...rest } = props

  const { rows, headerGroups, prepareRow, getTableProps, getTableBodyProps } = useTable({
    data,
    columns
  })

  return (
    <div>
      <div className={styles.cardTable}>
        <table {...getTableProps} {...rest}>
          <thead>
            {headerGroups.map((headerGroup, index) => (
              <tr key={index} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(header => (
                  <th key={header.render('Header')} {...header.getHeaderProps()}>
                    {header.render('Header')}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps}>
            {rows.map((row, index) => {
              prepareRow(row)
              return (
                <tr key={index} {...row.getRowProps()}>
                  {row.cells.map((cell, index) => (
                    <td
                      key={index}
                      data-label={cell.column.Header}
                      {...cell.getCellProps()}
                    >
                      {cell.render('Cell')}
                    </td>
                  ))}
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
      {event && (
        <div>
          <div>
            <div className={styles.aboutDelivery}>
              <div className={styles.purchaseInfo}>
                <Indicator
                  className={clsx(event.date != null ? styles.green : styles.yellow)}
                />
                <p>
                  {event.date}
                </p>
                <p>
                  Общая сумма покупки со скидкой:
                  <span className={styles.price}> {event.sum || '-'} р.</span>
                </p>
              </div>
              {icon}
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
