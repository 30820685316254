import clsx from 'clsx'
import styles from '../TextInput/styles.module.scss'
import InputMask from 'react-input-mask'

export default function TimeInput(props) {
  const { value, onChange, placeholder, className, label, errorMessage, ...rest } = props

  const handleChange = event => {
    if (onChange) {
      onChange(event.target.value)
    }
  }

  const handleBeforeChange = (newValue, oldValue) => {
    let value = newValue.value
    let selection = newValue.selection

    const refExp = /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/

    if (value !== '' && !refExp.test(value)) {
      value = oldValue.value
      selection = oldValue.selection
    }

    return { value, selection }
  }

  return (
    <div className={styles.container}>
      {label && (
        <label className={clsx(styles.label, errorMessage && styles.error)}>
          {label}
        </label>
      )}
      <InputMask
        type="text"
        mask="99:99"
        value={value}
        onChange={handleChange}
        placeholder={placeholder}
        maskChar="0"
        beforeMaskedValueChange={handleBeforeChange}
        className={clsx(styles.input, className)}
        formatChars={{
          9: '[0-9]',
          a: '[A-Za-zА-Яа-я]',
          '*': '[A-Za-zА-Яа-я0-9]'
        }}
        {...rest}
      />
    </div>
  )
}
