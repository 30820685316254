import { useState, useEffect, useCallback } from 'react'
import InputMaskField from '../../../components/InputMaskField'
import ScheduleDays from '../../../components/ScheduleDays'
import SwitchButton from '../../../components/SwitchButton'
import IconButton from '../../../components/IconButton'
import TextInput from '../../../components/TextInput'
import Select from '../../../components/Select'
import GridLoader from '../../../components/Loader'
import Button from '../../../components/Button'
import axios from '../../../utils/axios'
import { useFormik } from 'formik'
import * as yup from 'yup'
import cancel from '../../../icons/cancel.svg'
import deserializeList, { deserializeListObject } from '../../../utils/deserializeList'
import clsx from 'clsx'
import parseTime from '../../../utils/12hourTo24'
import parseDate from '../../../utils/formatDate'
import styles from './styles.module.scss'

const BannerModal = props => {
  const { banner, onCloseEvent } = props

  const [loading, setLoading] = useState(false)
  const [cities, setCities] = useState([])
  const [schools, setSchools] = useState([])

  const getCities = useCallback(async () => {
    const { cities } = await axios.get('kiosk/cities')
    setCities(deserializeList(cities))
  }, [])

  const getSchools = useCallback(async (array, index) => {
    const res = await axios.get('catalogue/schools', {
      params: { selectedCities: [array] }
    })
    const options = res.schools.map(i => ({
      ...deserializeListObject(i),
      sublabel: i.address
    }))
    setSchools(options)
  }, [])

  useEffect(() => {
    getCities()
  }, [getCities])

  const handleSave = async values => {
    try {
      setLoading(true)
      const newData = {
        title: values.title,
        image: values.image === undefined ? null : values.image,
        dateEnd: values.dateEnd,
        dateStart: values.dateStart,
        showToAll: values.showToAll,
        description: values.description,
        scheduleDays: values.scheduleDays,
        schedulePeriod: values.schedulePeriod,
        scheduleTimeEnd: values.scheduleTimeEnd,
        scheduleTimeStart: values.scheduleTimeStart
      }

      const formData = new FormData()
      for (const key in newData) {
        formData.append(key, newData[key])
      }

      if (!values.showToAll) {
        const newSchools = []
        values.initialSchools.forEach(el => {
          newSchools.push(el.uid)
        })

        if (values.schools?.[0] !== '') {
          values.schools.forEach(el => {
            newSchools.push(el)
          })
        }

        for (let i = 0; i < newSchools.length; i++) {
          formData.append(`schools[${i}]`, newSchools[i])
        }
      }

      banner
        ? await axios.put(`promotion/banner/${banner.uid}`, formData)
        : await axios.post('promotion/banner', formData)
    } catch (e) {
      console.log('debug got a fookin error', e)
    } finally {
      setLoading(false)
      onCloseEvent()
    }
  }

  const { values, errors, touched, setFieldValue, handleSubmit, getFieldProps } =
    useFormik({
      onSubmit: values => {
        handleSave(values)
      },
      initialValues: {
        title: banner ? banner.title : '',
        description: banner ? banner.description : '',
        dateStart: banner ? parseDate(banner.dateStart, false) : '',
        dateEnd: banner ? parseDate(banner.dateEnd, false) : '',
        showToAll: banner ? banner.showToAll : true,
        scheduleDays: banner ? banner.scheduleDays : '0000000',
        schedulePeriod: banner ? banner.schedulePeriod : null,
        scheduleTimeStart: banner ? parseTime(banner.ScheduleTimeStart) : '',
        scheduleTimeEnd: banner ? parseTime(banner.ScheduleTimeEnd) : '',
        selectedCities: [''],
        schools: [''],
        initialSchools: banner?.schools?.length ? banner.schools : [],
        image: undefined
      },
      validationSchema: yup.object().shape({
        title: yup.string().required('Это поле обязательно'),
        description: yup.string().required('Это поле обязательно'),
        dateStart: yup
          .string()
          .required('Это поле обязательно')
          .matches(
            /^([0-2][0-9]|(3)[0-1])(\.)(((0)[0-9])|((1)[0-2]))(\.)\d{4}$/,
            'Формат DD.MM.YYYY'
          ),
        dateEnd: yup
          .string()
          .required('Это поле обязательно')
          .test(
            'match',
            'Дата окончания не может быть меньше даты начала',
            function (dateEnd) {
              if (dateEnd && this.parent.dateStart) {
                const start = this.parent.dateStart.split('.')
                const end = dateEnd.split('.')
                const dates = {
                  start: new Date(+start[2], +start[1] - 1, +start[0]),
                  end: new Date(+end[2], +end[1] - 1, +end[0])
                }
                return dates.end.getTime() >= dates.start.getTime()
              }
            }
          )
          .matches(
            /^([0-2][0-9]|(3)[0-1])(\.)(((0)[0-9])|((1)[0-2]))(\.)\d{4}$/,
            'Формат DD.MM.YYYY'
          ),
        showToAll: yup.bool(),
        scheduleDays: yup
          .mixed()
          .required('Это поле обязательно')
          .notOneOf(['0000000'], 'Выберите день'),
        schedulePeriod: yup
          .number()
          .min(1, 'Неверное значение')
          .max(4, 'Неверное значение')
          .typeError('Введите число'),
        scheduleTimeStart: yup
          .string()
          .required('Это поле обязательно')
          .matches(/^(2[0-3]|[0-1]?[\d]):[0-5][\d]$/, 'Неверный формат'),
        scheduleTimeEnd: yup
          .string()
          .required('Это поле обязательно')
          .matches(/^(2[0-3]|[0-1]?[\d]):[0-5][\d]$/, 'Неверный формат'),
        schools: yup.array().when('showToAll', {
          is: false,
          then: yup.array().of(yup.string().required('Это поле обязательно'))
        }),
        image: banner
          ? yup.mixed().notRequired()
          : yup.mixed().required('Изображение обязательно')
      }),
      enableReinitialize: true
    })

  const handleChangeCity = options => {
    const selectedCities = options.flatMap(c => (c.selected ? [c.id] : []))
    getSchools(selectedCities)
  }

  const handleChangeSchool = options => {
    const schools = options.flatMap(s => (s.selected ? [s.id] : []))
    setFieldValue('schools', schools)
  }

  const changeSchedular = value => {
    setFieldValue('scheduleDays', value)
  }

  const handleChangeSwitch = () => {
    const value = values.initialSchools?.length > 0 ? false : !values.showToAll
    setFieldValue('showToAll', value)
  }

  const handleChangePhoto = event => {
    setFieldValue('image', event.target.files[0])
  }

  const handleDeleteInitialSchool = id => () => {
    if (values.initialSchools.length <= 1) {
      setFieldValue('initialSchools', [])
    } else {
      const newSchools = values.initialSchools.filter(f => f.uid !== id)
      setFieldValue('initialSchools', newSchools)
    }
  }

  const handleOnClose = () => onCloseEvent()

  const handleChangeSchedulePeriod = event => {
    const { name, value } = event.target
    if (value.length === 1 && Number.isInteger(+value)) {
      setFieldValue(name, value)
    }
  }

  return (
    <div className={clsx(styles.modalContainer, banner && styles.p20)}>
      <GridLoader loading={loading} className={styles.gridLoader} />
      <div className={clsx(loading && styles.blurred)}>
        <h2>{banner ? 'Редактировать баннер' : 'Добавить баннер'}</h2>
        <form onSubmit={handleSubmit}>
          <div className={styles.textInputs}>
            <TextInput
              label="Название правила"
              placeholder="Баннер №1"
              value={values.title}
              errorMessage={touched.title && errors.title}
              {...getFieldProps('title')}
              tabIndex={1}
            />

            <div className={styles.spacer1}></div>

            <TextInput
              label="Описание"
              placeholder="О чем баннер"
              value={values.description}
              errorMessage={touched.description && errors.description}
              {...getFieldProps('description')}
              textarea
              tabIndex={2}
            />
          </div>

          <div className={styles.spacer1} />

          <div className={styles.bannerTimeCol}>
            <div className={styles.bannerTimeRow}>
              <h3>Работа баннера</h3>
              <div className={styles.bannerInputsRow}>
                <InputMaskField
                  label="с"
                  value={values.dateStart}
                  {...getFieldProps('dateStart')}
                  errors={touched.dateStart && errors.dateStart}
                  mask="99.99.9999"
                  tabIndex={3}
                  className={styles.maskInputFrom}
                />

                <div className={styles.spacer1} />

                <InputMaskField
                  label="по"
                  value={values.dateEnd}
                  {...getFieldProps('dateEnd')}
                  errors={touched.dateEnd && errors.dateEnd}
                  mask="99.99.9999"
                  tabIndex={4}
                />
              </div>
            </div>
            <div className={styles.bannerTimeRow}>
              <h3>Время показа</h3>
              <div className={styles.bannerInputsRow}>
                <InputMaskField
                  label="c"
                  value={values.scheduleTimeStart}
                  {...getFieldProps('scheduleTimeStart')}
                  errors={touched.scheduleTimeStart && errors.scheduleTimeStart}
                  mask="99:99"
                  className={styles.maskInputFrom}
                  tabIndex={5}
                />

                <div className={styles.spacer1} />

                <InputMaskField
                  label="по"
                  value={values.scheduleTimeEnd}
                  {...getFieldProps('scheduleTimeEnd')}
                  errors={touched.scheduleTimeEnd && errors.scheduleTimeEnd}
                  mask="99:99"
                  tabIndex={6}
                />
              </div>
            </div>
          </div>

          <div>
            <h3>Дни недели</h3>
            <ScheduleDays
              onChange={changeSchedular}
              errors={touched.scheduleDays && errors.scheduleDays}
              activeDays={values.scheduleDays}
            />
          </div>

          <div>
            <h3>Периодичность</h3>
            <div className={styles.repeats}>
              <span>Повторять каждые </span>
              <TextInput
                value={values.schedulePeriod}
                placeholder="2"
                errorMessage={touched.schedulePeriod && errors.schedulePeriod}
                {...getFieldProps('schedulePeriod')}
                className={styles.repeatsInput}
                tabIndex={7}
                onChange={handleChangeSchedulePeriod}
              />
              <span> недели</span>
            </div>
          </div>

          <div>
            <h3>Показывать</h3>
            <div className={styles.switchContainer}>
              <span>Показывать всем</span>
              <SwitchButton
                id="showToAll"
                onLabel="да"
                offLabel="нет"
                checked={values.initialSchools?.length > 0 ? false : values.showToAll}
                onChange={handleChangeSwitch}
              />
            </div>

            <div className={styles.spacer1} />

            <div className={styles.initialSchoolsContainer}>
              {values.initialSchools?.length > 0 && (
                <>
                  {values.initialSchools.map(school => (
                    <div key={school.uid} className={styles.schoolItem}>
                      <p className={styles.schoolItemDescription}>
                        {school.name}, {school.address}
                      </p>
                      <IconButton
                        onClick={handleDeleteInitialSchool(school.uid)}
                        icon={cancel}
                      />
                    </div>
                  ))}
                </>
              )}
            </div>

            {(!values.showToAll || values.initialSchools?.length > 0) && (
              <div className={styles.addCol}>
                <Select
                  text="Выберите город"
                  options={cities}
                  value={values.selectedCities}
                  errorMessage={touched.selectedCities && errors.selectedCities}
                  onSave={handleChangeCity}
                  className={styles.selectorContainer}
                />
                <Select
                  text="Выберите учреждение"
                  options={schools}
                  value={values.schools}
                  errorMessage={touched.schools && errors.schools}
                  onSave={handleChangeSchool}
                  className={styles.selectorContainer}
                />
              </div>
            )}
          </div>

          <h3>Изображение</h3>
          <div className={styles.addImage}>
            <input type="file" accept=".jpg, .jpeg, .png" onChange={handleChangePhoto} />
            {touched.image && <p className={styles.addError}>{errors.image}</p>}
          </div>

          <div className={styles.modalButtons}>
            <Button primary="true" type="submit">
              {banner ? 'Изменить' : 'Добавить'}
            </Button>
            {banner && (
              <Button white onClick={handleOnClose}>
                Отменить
              </Button>
            )}
          </div>
        </form>
      </div>
    </div>
  )
}

export default BannerModal
