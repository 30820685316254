import clsx from 'clsx'
import styles from './styles.module.scss'

export default function IconButton(props) {
  const { icon, type, primary, onClick, className, disabled, ...rest } = props

  return (
    <button
      type={type || 'button'}
      onClick={onClick}
      disabled={disabled}
      className={clsx(
        styles.button,
        primary && styles.buttonPrimary,
        disabled && styles.disabled,
        className
      )}
      {...rest}
    >
      <img src={icon} alt="" />
    </button>
  )
}
