import clsx from 'clsx'
import styles from './styles.module.scss'

export default function TabCarousel(props) {
  const { tabs, onSelect, className, selected } = props

  const handleClick = id => () => {
    onSelect(id)
  }

  return (
    <ul className={clsx(styles.carousel, className)}>
      {tabs.map(t => (
        <li
          key={t.id}
          className={clsx(styles.tab, t.id === selected && styles.selected)}
          onClick={handleClick(t.id)}
        >
          {t.label}
        </li>
      ))}
    </ul>
  )
}
