import clsx from 'clsx'
import plus from '../../icons/plus.svg'
import minus from '../../icons/minus.svg'
import styles from './styles.module.scss'

export default function Counter(props) {
  const { min = 0, max, value = 0, onChange, unitName, className, bigAndFlat } = props

  const handleChange = event => {
    const value = +event.target.value

    if (value > max) return

    onChange(value)
  }

  const handleKeyPress = event => {
    const keyCode = event.keyCode || event.which
    const keyValue = String.fromCharCode(keyCode)

    const int = parseInt(keyValue)
    if (isNaN(int)) {
      event.preventDefault()
    }
  }

  const handleBlur = event => {
    const value = +event.target.value

    if (value < min) {
      onChange(min)
    }
  }

  const handlePlusClick = () => {
    const newValue = value + 1
    if (max && max < newValue) {
      return
    }
    if (onChange) {
      onChange(newValue)
    }
  }

  const handleMinusClick = () => {
    const newValue = value - 1
    if (min && min > newValue) {
      return
    }
    if (onChange) {
      onChange(newValue)
    }
  }

  return (
    <div className={clsx(styles.container, bigAndFlat && styles.bigAndFlat, className)}>
      <button type="button" onClick={handleMinusClick} className={styles.button}>
        <img alt="Убавить" src={minus} />
      </button>

      <div>
        <input
          value={value}
          onChange={handleChange}
          className={styles.input}
          onKeyPress={handleKeyPress}
          onBlur={handleBlur}
        />
        {unitName}
      </div>

      <button type="button" onClick={handlePlusClick} className={styles.button}>
        <img alt="Прибавить" src={plus} />
      </button>
    </div>
  )
}
