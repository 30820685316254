import React from 'react'
import showdown from 'showdown'
import LiscDocument from '../../components/LiscDocument'

const Changelog = () => {
  let converter = new showdown.Converter(),
    html = converter.makeHtml(text)
  return <LiscDocument dangerouslySetInnerHTML={{ __html: html }} />
}

const changes = `

## [1.1.0] – 2022-09-09

### Добавлено

- Сводный отчет по чекам. Вы просили – мы сделали. Теперь можете смотреть агрегированную информацию в разрезе каждого дня.

### Изменено

- Теперь имя отображается одной строкой без разбивки на ФИО.

### Исправлено

- Лента событий теперь отсортировано по любой активности с заказами. Сначала свежие.
- Исправлены сложности с закрытием модальных окон клавишей Esc.
- Ошибка при изменении пароля.

## [1.0.0] – 2022-07-15

### Добавлено

- В Личном Кабинете теперь есть Changelog с описанием всех обновлений. Тот, на который вы сейчас смотрите.
- В заказе теперь отображается системная информация, полезная при взаимодействии с командой технической поддержки.

### Изменено

- Статус "Ошибка киоска" мы заменили на "Товар не выдан". Так вы сможете быстрее ориентироваться по проблемным чекам.
- В отчет по чекам добавили те, по которым отмена произошла без фактической оплаты.

### Исправлено

- С этого момента авторизация живет долго и счастливо. Не сбрасывается и не слетает после обновлений.
- Мы починили фильтр по периоду выдачи в ленте событий!
- Исправлена ошибка, при которой чек ОФД не формировался, если у покупателя не указаны ФИО.
- Исправлена ошибка, не позволяющая изменять статус дилера в личном кабинете.
- Другие незначительные улучшения и исправления.
`

const text = `
# Changelog
Все заметные изменения приложения будут задокументированы в этом файле.

${changes}
`

export default Changelog
