import { useFormik } from 'formik'
import * as yup from 'yup'
import Button from '../../../components/Button'
import TextInput from '../../../components/TextInput'
import axios from '../../../utils/axios'
import styles from './styles.module.scss'

const CitiesModal = props => {
  const { city, onCloseEvent } = props

  const handleSave = async values => {
    try {
      if (city) {
        await axios.put(`nomenclature/city/${city.uid}`, values)
      } else {
        await axios.post('nomenclature/city', values)
      }
    } catch {
    } finally {
      onCloseEvent()
    }
  }

  const { values, errors, touched, setFieldValue, handleSubmit } = useFormik({
    onSubmit: values => handleSave(values),
    initialValues: {
      name: city ? city.name : ''
    },
    validationSchema: yup.object().shape({
      name: yup.string().required('Это поле обязательно')
    }),
    enableReinitialize: true
  })

  const handleChange = name => event => {
    setFieldValue(name, event.target.value)
  }

  return (
    <div>
      <h2>{city ? 'Редактировать город' : 'Добавить город'}</h2>
      <form onSubmit={handleSubmit}>
        <div className={styles.textInputs}>
          <TextInput
            label="Название"
            placeholder="Название"
            value={values.name}
            errorMessage={touched.name && errors.name}
            onChange={handleChange('name')}
            tabIndex={1}
          />
        </div>

        <div className={styles.spacer1}></div>

        <div className={styles.modalButtons}>
          <Button primary type="submit">
            {city ? 'Изменить' : 'Добавить'}
          </Button>
          {city && (
            <Button white onClick={() => onCloseEvent()}>
              Отменить
            </Button>
          )}
        </div>
      </form>
    </div>
  )
}

export default CitiesModal
