import { useFormik } from 'formik'
import * as yup from 'yup'
import styles from './styles.module.scss'
import TextInput from '../TextInput'
import Button from '../Button'
import { toast } from 'react-toastify'
import axiosInstance from '../../utils/axios'

export const EditUserModal = ({ user, onPassChange, onClose }) => {
  const onSumbitHandler = async () => {
    await axiosInstance
      .post('/user/update', {
        ...values,
        uid: user.uid,
        phone: user?.phone,
        email: user?.email
      })
      .then(() => {
        toast.success('Данные обновлены')
        onClose()
      })
  }

  const { values, errors, touched, setFieldValue, handleSubmit } = useFormik({
    onSubmit: values => onSumbitHandler(values),
    initialValues: {
      visibleName: user?.visibleName ?? '',
    },
    validationSchema: yup.object().shape({
        visibleName: yup.string().required('Поле обязательно'),
      family: yup.string().required('Поле обязательно'),
      middleName: yup.string().required('Поле обязательно')
    }),
    enableReinitialize: true
  })

  const onChangeHandler = name => event => {
    setFieldValue(name, event.target.value)
  }

  return (
    <form className={styles.form} onSubmit={handleSubmit}>
      <h3>Редактирование профиля</h3>
      <div>
        <TextInput
          placeholder="Роль"
          label="Роль"
          className={styles.input}
          value={user?.userRole?.name}
          disabled
          fullDisabled
        />
        <TextInput
          placeholder="Должность"
          className={styles.input}
          label="Должность"
          value={user?.position}
          disabled
          fullDisabled
        />
      </div>
      <div>
        <TextInput
          placeholder="Телефон"
          className={styles.input}
          label="Телефон"
          value={user.phone}
          disabled
          fullDisabled
        />
        <TextInput
          placeholder="E-mail"
          className={styles.input}
          label="E-mail"
          value={user.email}
          disabled
          fullDisabled
        />
      </div>
      <div>
        <TextInput
          placeholder="Имя"
          className={styles.input}
          label="Имя"
          value={values.visibleName}
          errorMessage={touched.name && errors.name}
          onChange={onChangeHandler('name')}
        />
        <Button onClick={onPassChange} className={styles.change_pass_button}>
          Сменить пароль
        </Button>
      </div>
      <Button type="submit" className={styles.save_changes_button}>
        Сохранить
      </Button>
    </form>
  )
}
