import { useState, useRef, useEffect } from 'react'
import clsx from 'clsx'
import ruLang from 'date-fns/locale/ru'
import DatePicker, { registerLocale } from 'react-datepicker'

import Fade from '../Fade'
import Button from '../Button'
import TimeInput from '../TimeInput'
import SelectSingle from '../SelectSingle'

import styles from './styles.module.scss'
import triangle from '../../icons/triangle.svg'
import stylesSelect from '../Select/styles.module.scss'
import useOnClickOutside from '../../hooks/useOnClickOutside'

import 'react-datepicker/dist/react-datepicker.css'
import './calendar.scss'

registerLocale('ru', ruLang)

const getYears = () => {
  const array = []
  const end = new Date().getFullYear()
  let current = 2021

  do {
    array.push(current)
    current++
  } while (current <= end)

  return array.map(item => ({ id: item, label: item }))
}

const YEARS = getYears()

const MONTHS = [
  { id: 0, label: 'Январь' },
  { id: 1, label: 'Февраль' },
  { id: 2, label: 'Март' },
  { id: 3, label: 'Апрель' },
  { id: 4, label: 'Май' },
  { id: 5, label: 'Июнь' },
  { id: 6, label: 'Июль' },
  { id: 7, label: 'Август' },
  { id: 8, label: 'Сентябрь' },
  { id: 9, label: 'Октябрь' },
  { id: 10, label: 'Ноябрь' },
  { id: 11, label: 'Декабрь' }
]

function renderCustomHeader(props) {
  const { date, changeYear, changeMonth } = props

  const handleMonthChange = ({ id }) => {
    changeMonth(id)
  }

  const handleYearChange = ({ id }) => {
    changeYear(id)
  }

  return (
    <div>
      <div className={styles.label}>Выберите дату:</div>
      <div className={styles.inputRow}>
        <div className={styles.input}>
          <SelectSingle
            value={date.getMonth()}
            options={MONTHS}
            onChange={handleMonthChange}
          />
        </div>
        <div className={styles.input}>
          <SelectSingle
            value={date.getFullYear()}
            options={YEARS}
            onChange={handleYearChange}
          />
        </div>
      </div>
    </div>
  )
}

export default function Calendar(props) {
  const { text, value, onSave, right = true, required = false, errorMessage, className, withoutTime = false, ...rest } = props

  const [error, setError] = useState({ period: '', date: '' })
  const [isOpen, setOpen] = useState(false)
  const [endDate, setEndDate] = useState('')
  const [endTime, setEndTime] = useState('')
  const [startDate, setStartDate] = useState('')
  const [startTime, setStartTime] = useState('')
  const [selectionComplete, setSelectionComplete] = useState(false)
  const [dateSaved, setDateSaved] = useState(false)

  const ref = useRef()

  const handleOpen = () => {
    isOpen ? handleClose() : setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
    if (!dateSaved) {
      setEndDate('')
      setEndTime('')
      setStartDate('')
      setStartTime('')
      setSelectionComplete(false)
    }
  }

  const handleReset = () => {
    if (onSave) {
      onSave({ startDate: '', endDate: '', startTime: '', endTime: '' })
    }
    setError({ period: '', date: '' })
    setDateSaved(false)
    handleClose()
  }

  useEffect(() => {
    if (!dateSaved) {
      handleClose()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateSaved])

  useOnClickOutside(ref, handleClose)

  const handleSave = () => {
    if (!(startDate && endDate)) {
      setError({ date: 'Некорректная дата', ...error })
      return
    } else {
      setError({ date: '', ...error })
    }

    if (startTime && endTime) {
      const [startHours, startMinutes] = startTime.split(':').map(i => +i)
      const [endHours, endMinutes] = endTime.split(':').map(i => +i)

      if (startHours * 60 + startMinutes >= endHours * 60 + endMinutes) {
        setError({ period: 'Некорректный промежуток', ...error })
        return
      } else {
        setError({ period: '', ...error })
      }
    }

    if (onSave) {
      onSave({ startDate, endDate, startTime, endTime })
      setDateSaved(true)
    }
    setOpen(false)
  }

  function date23(date) {
    let newDate = new Date(date).setHours(23, 59, 59)
    return newDate
  }

  const handleChangeDate = date => {
    let end = new Date(date23(date))
    if (!selectionComplete && !startDate) {
      setStartDate(date)
      setEndDate(end)
    } else if (!selectionComplete && startDate && !endDate) {
      setEndDate(date23(date))
      setSelectionComplete(true)
    } else if (!selectionComplete && startDate && endDate) {
      setEndDate(date23(date))
      setSelectionComplete(true)
    } else if (selectionComplete && startDate && endDate) {
      setStartDate(date)
      setEndDate(end)
      setSelectionComplete(false)
    }
  }

  const handleSelectDate = date => {
    if (!selectionComplete && startDate && !endDate) {
      handleChangeDate(date)
    }
  }

  return (
    <div ref={ref} className={clsx(stylesSelect.container, className)} {...rest}>
      <button type="button" onClick={handleOpen} className={stylesSelect.button}>
        {required && (<span style={{color: "red"}}> *</span>)} {text}
        <img
          className={clsx(stylesSelect.triangle, isOpen && stylesSelect.rotated)}
          src={triangle}
          alt=""
        />
        {value && <span className={styles.indicator} />}
      </button>
      <Fade isShown={isOpen}>
        <div className={clsx(stylesSelect.listContainer, styles.selectContainer)} style={{right: right && 0 }}>
          {/* <div className={stylesSelect.header}>
            <button
              className={stylesSelect.close}
              onClick={handleClose}
            >
              <img src={triangle} alt='' />
            </button>
          </div> */}
          <div className={styles.container}>
            {!withoutTime && (
              <div className={styles.block}>
                <div className={styles.label}>Выберите время:</div>
                <div className={styles.inputRow}>
                  <div className={styles.input}>
                    <TimeInput
                      value={value?.startTime || startTime}
                      onChange={setStartTime}
                      placeholder="с 6:00"
                    />
                  </div>
                  <div className={styles.input}>
                    <TimeInput
                      value={value?.endTime || endTime}
                      onChange={setEndTime}
                      placeholder="до 14:00"
                    />
                  </div>
                </div>
                {error.period && <div className={styles.error}>{error.period}</div>}
              </div>
            )}
            <div className={styles.datePicker}>
              <DatePicker
                endDate={endDate || value?.endDate}
                selected={startDate || value?.startDate}
                startDate={startDate || value?.startDate}
                onChange={handleChangeDate}
                onSelect={handleSelectDate}
                selectsEnd={!Boolean(endDate)}
                selectsStart={!Boolean(startDate)}
                inline
                locale="ru"
                renderCustomHeader={renderCustomHeader}
                disabledKeyboardNavigation
              />
            </div>
            {error.date && <div className={styles.error}>{error.date}</div>}
            <div className={styles.acceptContainer}>
              <Button onClick={handleReset} className={styles.accept}>
                Сбросить
              </Button>
              <Button onClick={handleSave} className={styles.accept}>
                Применить
              </Button>
            </div>
          </div>
        </div>
      </Fade>
      {errorMessage && <p className={styles.error}>{errorMessage}</p>}
    </div>
  )
}
