import { useMemo, useState, useEffect, useCallback } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import Table from '../../components/Table'
import PageTopContainer from '../../components/PageTopContainer'
import Button from '../../components/Button'
import IconButton from '../../components/IconButton'
import ConditionalRender from '../../components/ConditionalRender'
import Tooltip from '../../components/Tooltip'
import { useSearch } from '../../hooks/useContext'
import axios from '../../utils/axios'
import ListIcon from '../../icons/list2.svg'
import styles from './styles.module.scss'
import Modal from '../../components/Modal'
import SwitchButton from '../../components/SwitchButton'
import TextInput from '../../components/TextInput'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import edit from '../../icons/edit.svg'
import AddButton from '../../components/AddButton'
import personOrange from '../../icons/personOrange.svg'
import squareOrange from '../../icons/squareOrange.svg'
import SelectSingle from "../../components/SelectSingle";

function DealerProfile(props) {
  const { title, onSubmit, initialValues, readOnly } = props

  const formik = useFormik({
    initialValues: initialValues
      ? initialValues
      : {
          name: '',
          position: '',
          phoneNumber: '',
          email: '',
          profileActive: false,
          shortName: '',
          fullName: '',
          bik: '',
          accountNumber: '',
          inn: '',
          kpp: '',
          addressName: '',
          taxSystemType: { id: '1' },
          orangeDataCabinetTypeEnum: { id: '1' },
          merchantUid: ''
        },
    validationSchema: Yup.object({
      name: Yup.string().required('ФИО не может быть пустым'),
      position: Yup.string().required('Должность не может быть пустая'),
      phoneNumber: Yup.string()
        .notRequired()
        .matches(
          /^((8|\+7)[- ]?)?(\(?\d{3}\)?[- ]?)?[\d\- ]{7,10}$/,
          'Неверный номер телефона'
        ),
      email: Yup.string()
        .email('Указан невалидный Email')
        .required('Email не может быть пустым'),
      profileActive: Yup.boolean(),
      shortName: Yup.string().required('Наименование юр. лица не может быть пустым'),
      fullName: Yup.string().required('Наименование юр. лица не может быть пустым'),
      bik: Yup.string()
        .test('bik test', 'БИК может содержать только 9 чисел', value => {
          if (value?.length === 9) {
            return true
          }
          return false
        })
        .required('БИК не может быть пустым'),
      accountNumber: Yup.string()
        .test(
          'account number test',
          'Номер счета может содержать только 20 чисел',
          value => {
            if (value?.length === 20) {
              return true
            }
            return false
          }
        )
        .required('Номер счета не может быть пустым'),
      inn: Yup.string()
        .test('inn test', 'ИНН может содержать только 9-12 чисел', value => {
          if (value?.length >= 9 && value.length <= 12) {
            return true
          }
          return false
        })
        .required('ИНН не может быть пустым'),
      kpp: Yup.string()
        .test('kpp test', 'КПП может содержать только 9 чисел', value => {
          if (value?.length === 9) {
            return true
          }
          return false
        })
        .required('КПП не может быть пустым'),
      addressName: Yup.string().required('Юридический адрес не может быть пустым'),
      taxSystemType: Yup.object(),
      orangeDataCabinetTypeEnum: Yup.object()
    }),
    onSubmit
  })

  // Cut 11.07.2022, Task #71125
  // const handleFormActiveChange = e => {
  //   formik.setFieldValue('active', e.target.checked)
  // }

  const getDealerPC = async () => {
    try {
      const resDealers = await axios.get(`dealer/${initialValues.uid}`)
      formik.setFieldValue('position', resDealers.user.position)
      formik.setFieldValue('shortName', resDealers.user.merchantModel.shortName)
      formik.setFieldValue('fullName', resDealers.user.merchantModel.fullName)
      formik.setFieldValue('bik', resDealers.user.merchantModel.bik)
      formik.setFieldValue('accountNumber', resDealers.user.merchantModel.accountNumber)
      formik.setFieldValue('inn', resDealers.user.merchantModel.inn)
      formik.setFieldValue('kpp', resDealers.user.merchantModel.kpp)
      formik.setFieldValue('addressName', resDealers.user.merchantModel.addressName)
      formik.setFieldValue('taxSystemType', {
        id: `${resDealers.user.merchantModel.cabinetType}`
      })
      formik.setFieldValue('orangeDataCabinetTypeEnum', {
        id: `${resDealers.user.merchantModel.taxationSystem}`
      })
    } catch {
      /* empty */
    }
  }

  useEffect(() => {
    getDealerPC()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleChange = name => event => {
    const val = event.target.value
    if (name === 'name') {
      if (val.length <= 103) {
        formik.setFieldValue(name, val)
      }
    } else if (name === 'position') {
      if (val.length <= 103) {
        formik.setFieldValue(name, val)
      }
    } else {
      formik.setFieldValue(name, val)
    }
  }

  return (
    <>
      <form className={styles.form} onSubmit={formik.handleSubmit}>
        <div className={styles.formFirstRow}>
          <h3 className={styles.title}>{title}</h3>
          {/* Cut 11.07.2022, Task #71125 */}
          {/*<SwitchButton*/}
          {/*  id="activitySwitch"*/}
          {/*  offLabel="Неактивен"*/}
          {/*  onLabel="Активен"*/}
          {/*  checked={formik.values.active}*/}
          {/*  onChange={handleFormActiveChange}*/}
          {/*  disabled={readOnly}*/}
          {/*/>*/}
        </div>
        <div>
          <TextInput
            placeholder="ФИО"
            label="ФИО"
            className={styles.input}
            errorMessage={formik.touched.name && formik.errors.name}
            disabled={readOnly}
            onChange={handleChange('name')}
            value={formik.values.name}
            // {...formik.getFieldProps('name')}
          />
          <TextInput
            placeholder="Должность"
            label="Должность"
            className={styles.input}
            errorMessage={formik.touched.position && formik.errors.position}
            disabled={readOnly}
            value={formik.values.position}
            onChange={handleChange('position')}
            // {...formik.getFieldProps('position')}
          />
        </div>
        <div>
          <TextInput
            placeholder="Номер"
            label="Номер телефона"
            className={styles.input}
            errorMessage={formik.touched.phoneNumber && formik.errors.phoneNumber}
            disabled={readOnly}
            {...formik.getFieldProps('phoneNumber')}
          />
          <TextInput
            placeholder="Почтовый адрес"
            label="E-mail"
            className={styles.input}
            errorMessage={formik.touched.email && formik.errors.email}
            disabled={readOnly}
            {...formik.getFieldProps('email')}
          />
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', gap: 20 }}>
          <TextInput
            placeholder="ООО"
            label="Сокращенное наименование юр. лица"
            errorMessage={formik.touched.shortName && formik.errors.shortName}
            className={styles.input}
            disabled={readOnly}
            {...formik.getFieldProps('shortName')}
          />
          <TextInput
            placeholder="Общество с ограниченной ответсвенностью"
            label="Полное наименование юр. лица"
            errorMessage={formik.touched.fullName && formik.errors.fullName}
            className={styles.input}
            disabled={readOnly}
            {...formik.getFieldProps('fullName')}
          />
        </div>
        <div>
          <TextInput
            placeholder="БИК"
            label="БИК"
            numbersOnly
            errorMessage={formik.touched.bik && formik.errors.bik}
            className={styles.input}
            disabled={readOnly}
            onChange={e =>
              e.target.validity.valid && formik.setFieldValue(e.target.value)
            }
            {...formik.getFieldProps('bik')}
          />
          <TextInput
            placeholder="Номер банковского счета"
            label="Номер банковского счета"
            numbersOnly
            errorMessage={formik.touched.accountNumber && formik.errors.accountNumber}
            className={styles.input}
            disabled={readOnly}
            {...formik.getFieldProps('accountNumber')}
          />
        </div>
        <div>
          <TextInput
            placeholder="ИНН"
            label="ИНН"
            numbersOnly
            errorMessage={formik.touched.inn && formik.errors.inn}
            className={styles.input}
            disabled={readOnly}
            {...formik.getFieldProps('inn')}
          />
          <TextInput
            placeholder="КПП"
            label="КПП"
            numbersOnly
            errorMessage={formik.touched.kpp && formik.errors.kpp}
            className={styles.input}
            disabled={readOnly}
            {...formik.getFieldProps('kpp')}
          />
        </div>
        <div>
          <TextInput
            placeholder="Юридический адрес"
            label="Юридический адрес"
            errorMessage={formik.touched.addressName && formik.errors.addressName}
            className={styles.input}
            disabled={readOnly}
            {...formik.getFieldProps('addressName')}
          />
        </div>
        <div>
          <div style={{ paddingRight: 20 }}>
            <SelectSingle
              text="Тип ЛК OrangeData"
              className={styles.anotherSelect}
              value={formik.values?.taxSystemType?.id}
              onChange={taxSystemType =>
                formik.setFieldValue('taxSystemType', taxSystemType)
              }
              options={[
                { id: '1', label: 'Основной' },
                { id: '2', label: 'Вендинговый' },
                { id: '3', label: 'Транспортный' }
              ]}
              disabled={readOnly}
            />
          </div>
          <div>
            <SelectSingle
              text="Система налогооблажения"
              value={formik.values?.orangeDataCabinetTypeEnum?.id}
              className={styles.anotherSelect}
              onChange={orangeDataCabinetTypeEnum =>
                formik.setFieldValue(
                  'orangeDataCabinetTypeEnum',
                  orangeDataCabinetTypeEnum
                )
              }
              options={[
                { id: '1', label: 'Общая, ОСН' },
                { id: '2', label: 'Упрощенная доход, УСН доход' },
                { id: '3', label: 'Упрощенная доход минус расход, УСН доход - расход' },
                { id: '4', label: 'Единый налог на вмененный доход, ЕНВД' },
                { id: '5', label: 'Единый сельскохозяйственный налог, ЕСН' },
                { id: '6', label: 'Патентная система налогообложения, Патент' }
              ]}
              disabled={readOnly}
            />
          </div>
        </div>
        {!readOnly ? (
          <Button type="submit" className={styles.button} loading={formik.isSubmitting}>
            Сохранить
          </Button>
        ) : (
          <></>
        )}
      </form>
    </>
  )
}

export default function Dealers() {
  const params = useParams()
  const history = useHistory()
  const { value } = useSearch()

  const [loading, setLoading] = useState(false)
  const [pagination, setPagination] = useState({
    rowCount: 10,
    pageNum: params.pageNum,
    pageCount: 1
  })
  const [dealers, setDealers] = useState([])

  const getDealers = useCallback(async () => {
    try {
      setLoading(true)
      const res = await axios.get('nomenclature/dealer/list', {
        params: {
          text: value,
          'Pagination.RowCount': pagination.rowCount,
          'Pagination.PageNum': pagination.pageNum
        }
      })

      setDealers(res.dealers)

      setPagination(p => ({ ...p, pageCount: res.pagination.pageCount }))
    } catch {
      /* empty */
    } finally {
      setLoading(false)
    }
  }, [value, pagination.rowCount, pagination.pageNum])

  // const getDealer = useCallback(async () => {
  //   try {
  //     const res = await axios.get('nomenclature/dealer/list', {
  //       params: {
  //         text: value,
  //         'Pagination.RowCount': pagination.rowCount,
  //         'Pagination.PageNum': pagination.pageNum
  //       }
  //     })
  //     console.log(res)
  //   }
  //   catch { }
  // }, [value, pagination.rowCount, pagination.pageNum])

  useEffect(() => {
    getDealers()
  }, [getDealers])

  const handleArchive = async (uid, isActive) => {
    await axios.put(`/nomenclature/dealer/archive/${uid}`, null, {
      params: {
        isActive: !isActive
      }
    })
    handleCloseReadModal()
    await getDealers()
  }

  const handleGoToKioskDealer = uid => () => {
    history.push('/kiosks', {
      dealer: [uid]
    })
  }

  const handleGoToUsersDealer = uid => () => {
    history.push('/users/profiles', {
      dealer: uid
    })
  }

  const handleGoToDealersNomenclature = profile => () => {
    history.push(`/nom/goods`, {
      dealer: profile.uid,
      dealerName: profile.visibleName
    })
  }

  const [readModal, setReadModal] = useState({
    isOpen: false,
    profileName: '',
    profileId: ''
  })

  const [editModal, setEditModal] = useState({
    isOpen: false,
    profileName: '',
    profileId: ''
  })

  const handleCloseReadModal = () => {
    setReadModal({ ...readModal, isOpen: false })
  }

  const handleCloseEditModal = () => {
    setEditModal({ ...readModal, isOpen: false })
  }

  const handleClickRow = value => () => {
    const profile = value.original
    setReadModal({
      isOpen: true,
      profileId: profile.uid,
      profileName: profile.visibleName,
      initialValues: {
        uid: profile.uid,
        name: profile.visibleName ?? '',
        profileActive: profile.profileActive,
        role: '22ddccbe-b8ce-480b-a19a-7d96b38591a1s',
        phoneNumber: profile.phone ?? '',
        email: profile.email,
        merchantUid: profile.merchantUid
      }
    })
  }

  const handleEditProfile = profile => e => {
    e.stopPropagation()
    setEditModal({
      isOpen: true,
      profileId: profile.uid,
      profileName: profile.visibleName,
      initialValues: {
        uid: profile.uid,
        name: profile.visibleName ?? '',
        position: profile.position ?? '',
        profileActive: profile.profileActive,
        role: '22ddccbe-b8ce-480b-a19a-7d96b38591a1s',
        phoneNumber: profile.phone ?? '',
        email: profile.email ?? '',
        merchantUid: profile.merchantUid
      }
    })
  }

  const onArchiveToggleClickHandler = (uid, active) => ev => {
    ev.stopPropagation()
    handleArchive(uid, active)
  }

  const columns = useMemo(
    () => [
      {
        Header: 'ФИО',
        accessor: 'visibleName',
        disableSortBy: true
      },
      {
        Header: 'Контактный телефон',
        accessor: 'phone',
        disableSortBy: true
      },
      {
        Header: 'Электронная почта',
        accessor: 'email',
        disableSortBy: true
      },
      {
        Header: '',
        accessor: 'uid',
        disableSortBy: true,

        Cell: ({ value, row }) => (
          <ConditionalRender authKey="allow-nomenclature-dealer-update">
            <div className={styles.buttons__container}>
              <div className={styles.buttons_small}>
                <Tooltip content="Киоски дилера">
                  <IconButton
                    icon={squareOrange}
                    onClick={handleGoToKioskDealer(
                      row.original.uid,
                      row.original.visibleName
                    )}
                  />
                </Tooltip>
                <Tooltip content="Сотрудники дилера">
                  <IconButton
                    icon={personOrange}
                    onClick={handleGoToUsersDealer(row.original)}
                  />
                </Tooltip>
                <Tooltip content="Редактировать дилера">
                  <IconButton icon={edit} onClick={handleEditProfile(row.original)} />
                </Tooltip>
                <Tooltip content="Номенклатура дилера">
                  <IconButton
                    icon={ListIcon}
                    onClick={handleGoToDealersNomenclature(row.original)}
                  />
                </Tooltip>
              </div>
              <div className={styles.buttons}>
                <Button
                  onClick={onArchiveToggleClickHandler(
                    row.original.uid,
                    row.original.profileActive
                  )}
                >
                  {row.original.profileActive === true
                    ? 'Отправить в архив'
                    : 'Вернуть из архива'}
                </Button>
              </div>
            </div>
          </ConditionalRender>
        )
      }
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  const handleChangePagination = p => {
    setPagination({
      ...pagination,
      ...p
    })
    history.push(`dealers&pageNum=${p.pageNum}&rowCount=${pagination.rowCount}`)
  }

  const serializeProfilePayload = values => ({
    email: values.email,
    phone: values.phoneNumber,
    visibleName: values.name,
    position: values.position,
    profileActive: values.profileActive,
    shortName: values.shortName,
    fullName: values.fullName,
    role: '22ddccbe-b8ce-480b-a19a-7d96b38591a1s',
    bik: values.bik,
    accountNumber: values.accountNumber,
    inn: values.inn,
    kpp: values.kpp,
    addressName: values.addressName,
    taxSystemType: values.taxSystemType?.id,
    orangeDataCabinetTypeEnum: values.orangeDataCabinetTypeEnum?.id,
    merchantUid: values.merchantUid
  })

  const handleSubmitEdit = async values => {
    console.log(values)
    const payload = serializeProfilePayload(values)
    await axios.put(`/dealer/${editModal.profileId}`, payload, { toast: true })
    getDealers()
    setEditModal(s => ({ ...s, isOpen: false }))
  }

  const [isAddOpen, setAddOpen] = useState(false)

  const handleOpenAddModal = () => setAddOpen(true)

  const handleCloseAddModal = () => setAddOpen(false)

  const handleSubmitProfile = async values => {
    const payload = serializeProfilePayload(values)

    await axios.post('/dealer', payload, { toast: true })
    getDealers()
    handleCloseAddModal()
  }

  return (
    <div>
      <PageTopContainer>
        <div className={styles.firstRow}>
          <h1>Дилеры</h1>
          <ConditionalRender authKey="allow-user-user-update">
            <AddButton
              label="Добавить дилера"
              isOpen={isAddOpen}
              onClick={handleOpenAddModal}
              onClose={handleCloseAddModal}
            >
              <DealerProfile
                title="Новая учётная запись"
                onSubmit={handleSubmitProfile}
              />
            </AddButton>
          </ConditionalRender>
        </div>
      </PageTopContainer>
      <Table
        loading={loading}
        data={dealers}
        columns={columns}
        pagination={pagination}
        onPaginationChange={handleChangePagination}
        onClick={handleClickRow}
      />
      <Modal centered isOpen={readModal.isOpen} onClose={handleCloseReadModal}>
        <DealerProfile
          title={`Просмотр дилера ${readModal.profileName}`}
          initialValues={readModal.initialValues}
          readOnly
        />
      </Modal>
      <Modal centered isOpen={editModal.isOpen} onClose={handleCloseEditModal}>
        <DealerProfile
          title={`Редактирование дилера ${editModal.profileName}`}
          initialValues={editModal.initialValues}
          onSubmit={handleSubmitEdit}
        />
      </Modal>
    </div>
  )
}
